import {Acl, Authorized, FileForm, StdFormat, TemplateHelper, ToastHelper} from '@hapro/template-react';
import type {RecordDoubleClickEventArgs} from '@syncfusion/ej2-grids';
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Group,
    Inject,
    Search,
    Sort,
} from '@syncfusion/ej2-react-grids';
import type {ReactNode} from 'react';
import React from 'react';
import {Link, NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {TaskProgress} from '../../components/TaskProgress';
import type {BulletPoint} from '../../entities/BulletPoint';
import type {Protection} from '../../entities/Protection';
import {defaultProtection} from '../../entities/Protection';
import {TaskHelper} from '../../helpers/TaskHelper';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    params: URLSearchParams;
    navigate: NavigateFunction;
}

interface State {
    loading: boolean;
    protection: Protection;
}

class Internal extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {protection: defaultProtection(), loading: true};
        this.handleDoubleClick = this.handleDoubleClick.bind(this);
    }

    /** @inheritDoc */
    public async componentDidMount(): Promise<void> {
        const id = Number(this.props.params.get('id'));
        if (!this.props.params.has('id') || isNaN(id)) {
            this.props.navigate('/protection', {replace: true});
            return;
        }

        const protection = await ReportService.getProtection(id);
        this.setState({protection, loading: false});
    }

    public override render(): ReactNode {
        const {loading, protection} = this.state;
        const {params} = this.props;

        const id = params.get('id');
        const bulletPoints = [];
        for (const group of protection.bulletPointGroups) {
            for (const point of group.bulletPoints) {
                bulletPoints.push({groupLabel: group.label, ...point});
            }
        }

        return (
            <section className={'container mt-5'}>
                <h1>Vernerunde - Rapport #{id}</h1>
                <hr/>
                <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                    <TaskProgress step={3} id={id} type={'protection'}/>

                    {/* Status overview */}
                    <h5 className={'text-center mt-5'}>Vernerunde status: {protection.status}</h5>
                    <div className={'d-flex justify-content-between mt-4 p-3'}>
                        {TaskHelper.statuses.map((status, i) =>
                            <div key={i} style={{width: '18%'}} className={'text-center'}>
                                <i className={`${status.iconCss} fa-2x`}/>
                                <h5 className={'mt-2'}>{protection.bulletPointGroups
                                    .flatMap(group => group.bulletPoints)
                                    .filter(point => point.status === status.id)
                                    .length}</h5>
                                <h6 className={'mt-2'}>{status.text}</h6>
                            </div>,
                        )}
                    </div>

                    {/* Text summary */}
                    <div className={'card mt-4'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Rapport for vernerunden</h5>
                        </div>
                        <div className={'card-body'}>
                            <p>
                                Vernerunden <b>{protection.title}</b> ble registrert
                                av <b>{protection.createdDisplayName}</b> og
                                gjennomført <b>{TemplateHelper.toStdDate(protection.date, StdFormat.date)}</b> på
                                avdeling <b>{protection.department?.text ?? 'Uspesifisert'}</b> og
                                lokasjon <b>{protection.location?.text ?? 'Uspesifisert'}{protection.subLocation?.text
                                ? ' - ' + protection.subLocation.text
                                : ''}</b> følgende personer deltok:
                            </p>
                            <ul>
                                {protection.participants.map((participant, i) =>
                                    <li key={i}>{participant.displayName}</li>,
                                )}
                            </ul>
                        </div>
                    </div>

                    {/* Bullet Points */}
                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Oppfølging av vernerunden</h5>
                        </div>
                        <div className={'card-body'}>
                            <GridComponent dataSource={bulletPoints} allowGrouping={true} allowSorting={true}
                                           groupSettings={{columns: ['groupLabel']}} allowTextWrap={true}
                                           recordDoubleClick={this.handleDoubleClick}>
                                <ColumnsDirective>
                                    <ColumnDirective field={'groupLabel'} headerText={'Gruppe'} width={'6rem'}/>
                                    <ColumnDirective field={'label'} headerText={'Sjekkpunkt'} width={'6rem'}/>
                                    <ColumnDirective field={'comment'} headerText={'Kommentar'} width={'10rem'}/>
                                    <ColumnDirective field={'status'} headerText={'Status'} width={'6rem'}
                                                     template={this.statusTemplate}/>
                                </ColumnsDirective>
                                <Inject services={[Sort, Search, Group]}/>
                            </GridComponent>
                        </div>
                    </div>

                    {/* Files */}
                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Filer tilknyttet vernerunden</h5>
                        </div>
                        <div className={'card-body'}>
                            <div className={'d-flex flex-column gap-3'}>
                                {protection.bulletPointGroups.flatMap(group => group.bulletPoints)
                                    .filter(point => point.files.length)
                                    .map((point, i) =>
                                        <div key={i}>
                                            <h6>{point.label}</h6>
                                            <FileForm files={point.files} noUpload={true}
                                                      download={`/V1/Report/ProtectionFile/${protection?.id}/${point?.id}`}
                                                      upload={`/V1/Report/ProtectionFile/${protection?.id}/${point?.id}`}
                                                      remove={`/V1/Report/ProtectionFile/${protection?.id}/${point?.id}`}/>
                                        </div>,
                                    )}
                            </div>
                        </div>
                        <div className={'card-footer d-flex justify-content-between'}>
                            <Link to={`/protection/execute?id=${id}`} className={'btn btn-primary'}>
                                <i className={'fa-solid fa-fast-backward me-2'}/>Forrige
                            </Link>
                            <button className={'btn btn-info'} onClick={this.print}>
                                <i className={'fa-solid fa-print me-2'}/>Skriv ut
                            </button>
                        </div>
                    </div>

                </Authorized>
            </section>
        );
    }

    /** */
    private async print(): Promise<void> {
        ToastHelper.info('Ikke implementert', 'bootstrap');
    }

    /** */
    private handleDoubleClick(args: RecordDoubleClickEventArgs): void {
        const {navigate, params} = this.props;
        const id = params.get('id');
        const point = args.rowData as BulletPoint;
        if (id && point.reportId) {
            navigate(`/report/${point.reportId}`);
        }
    }

    /** */
    private statusTemplate(row: BulletPoint): JSX.Element {
        let test = TaskHelper.statusIcons.get(row.status);
        return (
            <div>
                <i className={`${test?.iconCss} me-2`}/>{test?.text}{row.report?.closedCommentId && <span className={"ms-2 fa-solid fa-check text-success"} title={"Hendelsesrapporten er lukket"}></span>}
            </div>
        );
    }
}

/** */
export function Report() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    return (<Internal params={params} navigate={navigate}/>);
}

