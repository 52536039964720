import type {ToolbarSettingsModel} from '@syncfusion/ej2-react-richtexteditor';

/** Helpful misc functions */
export abstract class MiscHelper {

    public static rteSettings: ToolbarSettingsModel = {
        items: [
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'ClearFormat',
            '|',
            'Formats', 'Alignments', 'Outdent', 'Indent',
            '|',
            'NumberFormatList', 'BulletFormatList', 'CreateTable', 'CreateLink',
            '|',
            'SourceCode',
        ],
    };

    /** @param str string to process
     * @return string without html entities */
    public static stripHtml(str: string): string {
        return str.replace(/<[^>]+>/g, '');
    }

    public static summary(str: string, length: number = 100): string {
        str = this.stripHtml(str);
        if (str.length > length) {
            return str.substring(0, length) + '...';
        }

        return str;
    }
}
