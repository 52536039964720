import {Company, Modal, MultiAuthUser, SpinnerHelper, userStore} from '@hapro/template-react';
import type {ActionEventArgs} from '@syncfusion/ej2-grids';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    GridComponent,
    RowSelectEventArgs,
    Sort,
} from '@syncfusion/ej2-react-grids';
import {Inject} from '@syncfusion/ej2-react-richtexteditor';
import React, {ChangeEvent, ReactNode} from 'react';
import type {Report} from '../entities/Report';
import type {Unit} from '../entities/Unit';
import {defaultUnit} from '../entities/Unit';
import {ReportService} from '../services/ReportService';

interface Props {
    report: Report;
}

interface State {
    user: MultiAuthUser | null;
    unit: Unit;
    units: Unit[] | null;
}

/** */
export class Units extends React.Component<Props, State> {

    /** */
    private modal: Modal | null = null;

    /** */
    private resultGrid: GridComponent | null = null;

    /** */
    private grid: GridComponent | null = null;

    /** */
    private busy: boolean = false;

    /** */
    private typing: boolean = false;

    constructor(props: Props) {
        super(props);
        this.state = {user: userStore.value, unit: defaultUnit(), units: null};
        this.reset = this.reset.bind(this);
        this.addUnit = this.addUnit.bind(this);
        this.rowSelected = this.rowSelected.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
        this.batchChange = this.batchChange.bind(this);
        this.serialChange = this.serialChange.bind(this);
        this.articleChange = this.articleChange.bind(this);
        this.updateSuggestions = this.updateSuggestions.bind(this);
    }

    /** @inheritDoc */
    public override componentDidMount(): void {
        userStore.subscribe(value => {
            this.setState({user: value});
        });
    }

    public override render(): ReactNode {
        const {report} = this.props;
        const {user, unit, units} = this.state;

        if (user?.userMinimal?.company === Company.electronics || user?.userMinimal?.company === Company.it) {
            return (
                <>
                    <div className={'input-group input-group-sm mt-3'}>
                        <span className={'input-group-text'}><i className={'fas fa-calculator'}/></span>
                        <button className={'btn btn-secondary form-control'} onClick={() => this.modal?.show()}>
                            {report.units.length} Artikkler involvert
                        </button>
                    </div>

                    <Modal ref={m => this.modal = m} animation={true} size={'xl'} onHidden={this.reset}>
                        <div className={'modal-header'}>
                            <h5 className={'modal-title'}>Artikkler</h5>
                        </div>
                        <div className={'modal-body'}>
                            {report.closedCommentId ? null :
                                <div className={'input-group input-group-sm mb-3'}>
                                    <button className={'btn btn-secondary'} onChange={this.reset}>
                                        <i className={'fa-solid fa-times'}/>
                                    </button>
                                    <input className={'form-control'} type={'text'} placeholder={'Artikkelnr.'}
                                           onChange={this.articleChange} value={unit.article}/>
                                    <input className={'form-control'} type={'number'} placeholder={'Sats'}
                                           onChange={this.batchChange} value={unit.batch}/>
                                    <input className={'form-control'} type={'text'} placeholder={'Serienr.'}
                                           onChange={this.serialChange} value={unit.serial}/>
                                    <button className={'btn btn-success'} onClick={this.addUnit}
                                            disabled={!unit.serial || !unit.article || !unit.batch}>
                                        <i className={'fa-solid fa-plus'}/>
                                    </button>
                                </div>
                            }
                            {units ?
                                <div className={'mb-3'}>
                                    <GridComponent dataSource={units} ref={g => this.resultGrid = g}
                                                   rowSelected={this.rowSelected}>
                                        <ColumnsDirective>
                                            <ColumnDirective field={'article'} headerTemplate={'Artikkelnr.'}/>
                                            <ColumnDirective field={'batch'} headerTemplate={'Sats'}/>
                                            <ColumnDirective field={'serial'} headerTemplate={'serienr.'}/>
                                        </ColumnsDirective>
                                    </GridComponent>
                                </div>
                                : null}
                            <GridComponent ref={g => this.grid = g} allowSorting={true}
                                           actionBegin={this.actionBegin} dataSource={report.units}
                                           editSettings={{allowDeleting: true, showDeleteConfirmDialog: true}}>
                                <ColumnsDirective>
                                    <ColumnDirective field={'article'} headerText={'Artikkelnr.'}/>
                                    <ColumnDirective field={'batch'} headerText={'Sats'}/>
                                    <ColumnDirective field={'serial'} headerText={'Serienr.'}/>
                                    <ColumnDirective headerText={'Fjern'} width={'4rem'} commands={[
                                        {
                                            type: 'Delete',
                                            buttonOption: {cssClass: 'e-flat', iconCss: 'fa-solid fa-trash'},
                                        }]}/>
                                </ColumnsDirective>
                                <Inject services={[Sort, CommandColumn]}/>
                            </GridComponent>
                        </div>
                    </Modal>
                </>
            );
        }

        return null;
    }

    /** */
    private rowSelected(args: RowSelectEventArgs): void {
        const {unit} = this.state;
        const row = args.data as Unit;
        unit.article = row.article;
        unit.batch = row.batch;
        unit.serial = row.serial;
        this.setState({unit});
    }

    /** */
    private reset(): void {
        this.setState({unit: defaultUnit(), units: []});
    }

    /** */
    private async addUnit(): Promise<void> {
        const {report} = this.props;
        const {unit} = this.state;
        SpinnerHelper.block();
        unit.id = 0;
        unit.reportId = report.id;
        const id = await ReportService.postUnit(unit);
        if (id) {
            unit.id = id;
            report.units.push(unit);
            this.setState({unit}, () => this.grid?.refresh());
        }
        SpinnerHelper.unblock();
    }

    /** */
    private async actionBegin(args: ActionEventArgs): Promise<void> {
        if (args.requestType === 'delete' && args.data?.[0]) {
            SpinnerHelper.block();
            const unit = args.data[0] as Unit;
            const success = await ReportService.deleteUnit(unit.id);
            if (!success) {
                args.cancel = true;
            }
            this.setState({unit});
            SpinnerHelper.unblock();
        }
    }

    /** Updates suggestions 600 ms after stopping to type */
    private async updateSuggestions(): Promise<void> {
        const {unit} = this.state;
        this.typing = true;

        // Set typing to false 300ms after input
        window.setTimeout(() => {
            this.typing = false;

            // Check if user has input more 600ms after input
            window.setTimeout(() => {
                if (this.busy || this.typing) {
                    return;
                }
                this.busy = true;
                this.resultGrid?.showSpinner();
                ReportService.putUnits(unit).then(units => {
                    this.setState({units}, () => {
                        this.resultGrid?.refresh();
                        this.busy = false;
                        this.resultGrid?.hideSpinner();
                    });
                });
            }, 300);
        }, 300);
    }

    /** */
    private articleChange(e: ChangeEvent<HTMLInputElement>): void {
        const {unit} = this.state;
        unit.article = e.target.value ?? '';
        this.typing = true;
        this.setState({unit}, this.updateSuggestions);
    }

    /** */
    private batchChange(e: ChangeEvent<HTMLInputElement>): void {
        const {unit} = this.state;
        const num = Number(e.target.value);
        this.typing = true;
        if (num) {
            unit.batch = num ? num : 0;
            this.setState({unit}, this.updateSuggestions);
        }
    }

    /** */
    private serialChange(e: ChangeEvent<HTMLInputElement>): void {
        const {unit} = this.state;
        unit.serial = e.target.value ?? '';
        this.typing = true;
        this.setState({unit}, this.updateSuggestions);
    }
}
