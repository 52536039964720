/** */
import {ReportCategory} from '../enums/ReportCategory';

export interface ReportForm {
    text: string;
    title: string;
    type: string;
    date: Date;
    responsible: number | null;
    category: ReportCategory;
    subLocationText: string;
    locationId: number | null;
    pictures: number;
    daysAbsent: number | null;
    reported: Date | null;
    initialMeasure: string;
}

/** Default object */
export function defaultReportForm(): ReportForm {
    return {
        type: '',
        title: '',
        text: '',
        date: new Date(),
        responsible: null,
        category: ReportCategory.deviation,
        subLocationText: '',
        locationId: null,
        pictures: 0,
        daysAbsent: null,
        reported: null,
        initialMeasure: '',
    };
}
