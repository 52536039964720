import {Acl, Authorized, SpinnerHelper} from '@hapro/template-react';
import React, {useState} from 'react';
import {ReportTable} from '../components/ReportTable';
import type {Report} from '../entities/Report';
import {aclReport} from '../services/aclStores';
import {ReportService} from '../services/ReportService';

enum Tab {
    none,
    my,
    responsible,
    involved
}

export function My(): JSX.Element {

    const [reports, setReports] = useState<Report[]>([]);
    const [tab, setTab] = useState<Tab>(Tab.none);

    async function switchTab(newTab: Tab): Promise<void> {
        if (newTab === tab) {
            return;
        }
        setTab(newTab);
        SpinnerHelper.show();
        let reports: Report[];
        switch (newTab) {
            case Tab.my:
                reports = await ReportService.getMyReports();
                break;
            case Tab.responsible:
                reports = await ReportService.getResponsibleReports();
                break;
            case Tab.involved:
                reports = await ReportService.getInvolvedReports();
                break;
            case Tab.none:
            default:
                reports = [];
                break;
        }
        setReports(reports);
        SpinnerHelper.hide();
    }

    return (
        <section className={'mt-5 mb-3'}>
            <div className={'container mb-3'}>
                <h1>Mine Rapporter</h1>
            </div>
            <div className={'container-fluid'}>
                <Authorized aclStore={aclReport} acl={Acl.create}>

                    <div className={'input-group container mb-3'}>
                        <button className={`form-control ${tab === Tab.my ? 'btn-primary' : ''}`}
                                onClick={() => switchTab(Tab.my)}>
                            <i className={'fa-solid fa-user me-2'}/>Mine rapporter
                        </button>
                        <button className={`form-control ${tab === Tab.responsible ? 'btn-primary' : ''}`}
                                onClick={() => switchTab(Tab.responsible)}>
                            <i className={'fa-solid fa-user-nurse me-2'}/>Rapporter jeg er ansvarlig for
                        </button>
                        <button className={`form-control ${tab === Tab.involved ? 'btn-primary' : ''}`}
                                onClick={() => switchTab(Tab.involved)}>
                            <i className={'fa-solid fa-users me-2'}/>Rapporter jeg er involvert i
                        </button>
                    </div>

                    <ReportTable reports={reports}/>

                </Authorized>
            </div>
        </section>
    );

}
