/** Type of Report. Order matters */
export enum ReportCategory {

    /** 'Avvik' */
    deviation,

    /** 'Tilløp til skade' */
    nearInjury,

    /** 'Skade' */
    injury,

    /** 'Forbedring' */
    improvement
}
