import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactNode} from 'react';
import type {Location} from '../entities/Location';
import {ReportService} from '../services/ReportService';

interface Props {
    id: number | null | undefined;
    onChange: (value: Location | null) => void;
    readOnly?: boolean;
}

interface State {
    locations: Location[];
}

/** */
export class LocationSelect extends React.Component<Props, State> {

    /** */
    private fields: FieldSettingsModel = {value: 'id', text: 'text'};

    public constructor(props: Props) {
        super(props);
        this.state = {locations: []};
        this.change = this.change.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const locations = await ReportService.getLocations();
        this.setState({locations});
    }

    public override render(): ReactNode {
        const {locations} = this.state;
        const {id, readOnly} = this.props;

        return <ComboBoxComponent value={id ?? undefined} readonly={readOnly}
                                  placeholder={'Uspesifisert'}
                                  dataSource={locations as any}
                                  noRecordsTemplate={'Fant ingen lokasjoner'}
                                  fields={this.fields}
                                  filterType={'Contains'}
                                  allowFiltering={true}
                                  ignoreCase={true}
                                  onChange={this.change}/>;
    }

    /** */
    private change(e: any) {
        const {onChange} = this.props;
        const {locations} = this.state;
        const location = locations.find(location => location.id === e.value);
        onChange(location ?? null);
    }
}
