import type {SortDescriptorModel} from '@syncfusion/ej2-react-grids';

/** */
export interface TableConfig {
    sorting: SortDescriptorModel[];
    grouping: string[];
    visible: string[];
}

/** */
export function defaultTableConfig(): TableConfig {
    return {
        sorting: [{field: 'created', direction: 'Descending', isFromGroup: false}],
        grouping: [],
        visible: [
            'id',
            'category',
            'title',
            'createdDisplayName',
            'responsibleDisplayName',
            'created',
            'closedCommentId',
        ],
    };
}
