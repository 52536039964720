import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactNode} from 'react';
import {ReportCategory} from '../enums/ReportCategory';

interface Props {
    value: string;
    category: ReportCategory;
    onChange: (value: string) => void;
}

interface State {
    types: string[];
}

/** */
export class TypeSelect extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {types: TypeSelect.getTypes(props.category)};
        this.change = this.change.bind(this);
    }

    public static getTypes(category: ReportCategory): string[] {
        switch (category) {
            case ReportCategory.deviation:
                return [
                    'Annet',
                    'Brudd på intern rutine/prosedyre',
                    'Brudd på lov/forskrift',
                    'Branntilløp/brann',
                    'Orden/renhold',
                ];
            case ReportCategory.nearInjury:
                return ['Tilløp til skade'];
            case ReportCategory.injury:
                return ['Annet', 'Personskade', 'Materiell skade'];
            case ReportCategory.improvement:
                return ['Forbedring'];
            default:
                return ['ERR'];
        }
    }

    public override render(): ReactNode {
        const {types} = this.state;
        const {value} = this.props;

        return <DropDownListComponent value={value} dataSource={types as any} onChange={this.change}/>;
    }

    private change(e: any) {
        this.props.onChange(e.value ?? '');
    }
}
