import type {ColumnModel} from '@syncfusion/ej2-react-grids';

/** Base for certain fields on an HMS Report */
export interface FieldBase {
    id: number;
    text: string;
}

/** Max length of strings */
export class FieldLength {
    static text = 200;
}

/** */
export function fieldAccessor(field: string, data: Object, _column: ColumnModel): string {
    const firstField = field.split('.')[0];
    const fieldBase = data[firstField] as FieldBase | undefined;
    return fieldBase?.text ?? 'Uspesifisert';
}
