import {Acl, Modal, SpinnerHelper, TemplateHelper, userStore} from '@hapro/template-react';
import type {Subscribe} from '@mathias_frost/simplestores';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    DeleteEventArgs,
    Edit,
    GridComponent,
    Inject,
    Sort,
} from '@syncfusion/ej2-react-grids';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ReactNode} from 'react';
import React from 'react';
import type {BulletPointTemplate} from '../entities/BulletPointTemplate';
import type {ProtectionNew} from '../models/ProtectionNew';
import {aclAdmin} from '../services/aclStores';
import {ReportService} from '../services/ReportService';

interface Props {
    protection: ProtectionNew;
    onChange: (bulletPointTemplate: BulletPointTemplate) => void;
}

interface State {
    templates: BulletPointTemplate[];
    name: string;
    acl: Acl;
}

/** */
export class TemplateModal extends React.Component<Props, State> {

    /** */
    private grid: GridComponent | null = null;

    /** */
    private unsubAcl: Subscribe<Acl> | null = null;

    /** */
    private modal: Modal | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {templates: [], name: '', acl: aclAdmin.value};
        this.save = this.save.bind(this);
        this.apply = this.apply.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
    }

    /** @inheritDoc */
    public async componentDidMount(): Promise<void> {
        SpinnerHelper.show();
        this.unsubAcl = aclAdmin.subscribe(value => this.setState({acl: value}));
        const templates = await ReportService.getTemplates();
        this.setState({templates}, () => this.grid?.refresh);
        SpinnerHelper.hide();
    }

    /** @inheritDoc */
    public componentWillUnmount(): void {
        aclAdmin.unsubscribe(this.unsubAcl);
    }

    public override render(): ReactNode {
        const {templates, name, acl} = this.state;
        const {protection} = this.props;

        const canManage = acl >= Acl.erase;

        return (<>
            <button className={'btn btn-sm'} onClick={() => this.modal?.show()}>
                <i className={'fa-solid fa-file-lines text-info me-2'}/>Maler
            </button>


            <Modal ref={m => this.modal = m} animation={true} centered={true} size={'xl'}
                   onHidden={() => this.setState({name: ''})}>
                <div className={'modal-header'}>
                    <h5 className={'modal-title'}>Sjekkpunktmaler</h5>
                    <button className={'btn-close'} onClick={() => this.modal?.hide()}/>
                </div>
                <div className={'modal-body'}>
                    <p>Lagre sjekkpunkt i <b>{protection.title}</b> som mal</p>
                    <div className={'row mb-3'}>
                        <div className={'col-11'}>
                            <TextBoxComponent value={name} placeholder={'Navn'}
                                              input={e => this.setState({name: e.value})}/>
                        </div>
                        <div className={'col-1 d-flex'}>
                            <button className={'btn btn-sm btn-success ms-auto'} disabled={!name} onClick={this.save}>
                                <i className={'fa-solid fa-plus'}/>
                            </button>
                        </div>
                    </div>
                    <GridComponent ref={g => this.grid = g} dataSource={templates} allowSorting={true}
                                   actionBegin={this.actionBegin}
                                   editSettings={{allowDeleting: canManage, showDeleteConfirmDialog: true}}>
                        <ColumnsDirective>
                            <ColumnDirective field={'name'} headerText={'Mal'} width={'10rem'}/>
                            <ColumnDirective field={'createdDisplayName'} headerText={'Opprettet av'} width={'8rem'}/>
                            <ColumnDirective field={'created'} headerText={'Dato'} type={'Date'}
                                             format={TemplateHelper.date} width={'7rem'}/>
                            <ColumnDirective headerText={'Fjern'} width={'4rem'} visible={canManage} commands={[
                                {type: 'Delete', buttonOption: {cssClass: 'e-flat', iconCss: 'fa-solid fa-trash'}}]}/>
                        </ColumnsDirective>
                        <Inject services={[CommandColumn, Sort, Edit]}/>
                    </GridComponent>
                </div>
                <div className={'modal-footer'}>
                    <button className={'btn btn-sm'} onClick={this.apply}>
                        <i className={'fa-solid fa-check text-warning me-2'}/>Bruk markert mal
                    </button>
                </div>
            </Modal>
        </>);
    }

    /** */
    private async actionBegin(args: DeleteEventArgs): Promise<void> {
        if (args.requestType === 'delete' && args.data?.length) {
            SpinnerHelper.block();
            const template = args.data[0] as BulletPointTemplate;
            const success = await ReportService.deleteTemplate(template.id);
            if (!success) {
                args.cancel = true;
            }
            SpinnerHelper.unblock();
        }
    }

    /** */
    private async save(): Promise<void> {
        const {protection} = this.props;
        const {templates, name} = this.state;
        const user = userStore.value?.userMinimal;
        if (!user) {
            return;
        }

        SpinnerHelper.block();

        // Report field is not relevant for templates
        protection.bulletPointGroups.forEach(group => group.bulletPoints.forEach(point => point.report = null));

        const id = await ReportService.postTemplate(name, protection.bulletPointGroups);
        if (id) {
            const newTemplate: BulletPointTemplate = {
                id,
                name,
                bulletPointGroups: protection.bulletPointGroups,
                created: new Date(),
                createdBy: user.id,
                createdDisplayName: user.displayName ?? 'Unknown',
            };
            templates.push(newTemplate);
        }
        this.setState({templates, name: ''}, () => this.grid?.refresh());
        SpinnerHelper.unblock();
    }

    /** */
    private apply(): void {
        const selected = this.grid?.getSelectedRecords() as BulletPointTemplate[] | undefined;
        if (selected?.length) {
            this.props.onChange(selected[0]);
        }
    }
}
