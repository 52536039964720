import type {ProxyFile} from '@hapro/template-react';
import {PointStatus} from '../enums/PointStatus';
import type {Report} from './Report';

/** For Protections */
export interface BulletPoint {
    id: number;
    order: number;
    label: string;
    comment: string | null;
    groupId: number;
    status: PointStatus;
    statusText: string;
    reportId: number | null;
    report: Report | null;
    files: ProxyFile[];
}

/** */
export function defaultBulletPoint(): BulletPoint {
    return {
        id: 0,
        label: '',
        order: 0,
        comment: '',
        groupId: 0,
        files: [],
        reportId: null,
        report: null,
        status: PointStatus.pending,
        statusText: '',
    };
}
