import type {UserMinimal} from '@hapro/template-react';
import {Company, HttpClient, ServiceBase} from '@hapro/template-react';
import type {DateRange} from '@syncfusion/ej2-calendars';
import type {Activity} from '../entities/Activity';
import type {Analysis} from '../entities/Analysis';
import {defaultAnalysis} from '../entities/Analysis';
import type {BulletPoint} from '../entities/BulletPoint';
import type {BulletPointGroup} from '../entities/BulletPointGroup';
import type {BulletPointTemplate} from '../entities/BulletPointTemplate';
import type {CompanyGroup} from '../entities/CompanyGroup';
import type {Correspondent} from '../entities/Correspondent';
import type {Department} from '../entities/Department';
import type {HmsStat} from '../entities/HmsStat';
import type {Location} from '../entities/Location';
import type {Measure} from '../entities/Measure';
import type {Protection} from '../entities/Protection';
import {defaultProtection} from '../entities/Protection';
import type {Report} from '../entities/Report';
import {defaultReport} from '../entities/Report';
import type {SubLocation} from '../entities/SubLocation';
import type {Unit} from '../entities/Unit';
import type {AnalysisNew} from '../models/AnalysisNew';
import type {MeasureNew} from '../models/MeasureNew';
import type {ProtectionNew} from '../models/ProtectionNew';
import type {ReportForm} from '../models/ReportForm';

/** Backend interaction for creating and modifying Reports */
export class ReportService extends ServiceBase {

    /** */
    public static async getUsers(): Promise<UserMinimal[]> {
        try {
            const res = await HttpClient.get('/V1/Report/Users');
            return this.expectObject<UserMinimal[]>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente ansatte');
            return [];
        }
    }

    /** */
    public static async getLocations(): Promise<Location[]> {
        try {
            const res = await HttpClient.get('/V1/Report/Locations');
            this.expectSuccessStatusCode(res);
            return this.expectObject<Location[]>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente lokasjoner');
            return [];
        }
    }

    /** */
    public static async getSubLocations(): Promise<SubLocation[]> {
        try {
            const res = await HttpClient.get('/V1/Report/SubLocations');
            this.expectSuccessStatusCode(res);
            return this.expectObject<Location[]>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente steder');
            return [];
        }
    }

    /** */
    public static async getDepartments(): Promise<Department[]> {
        try {
            const res = await HttpClient.get('/V1/Report/Departments');
            this.expectSuccessStatusCode(res);
            return this.expectObject<Department[]>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente avdelinger');
            return [];
        }
    }

    /** @param reportForm Required fields
     *  @returns Id of the created report. 0 if failed. */
    public static async post(reportForm: ReportForm): Promise<number> {
        try {
            const res: Response = await HttpClient.post('/V1/Report', reportForm);
            this.expectSuccessStatusCode(res);
            const reportId: number = await this.expectInt(res);
            this.handleSuccess('Hendelsesrapport opprettet');
            return reportId;
        } catch (e) {
            this.handleError(e, 'Kunne ikke opprette hendelsesrapport');
            return 0;
        }
    }

    /** */
    public static async patchType(reportId: number, type: string): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Type/${reportId}?` + new URLSearchParams({type}));
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Type endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre type');
            return false;
        }
    }

    /** */
    public static async patchTitle(reportId: number, title: string): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Title/${reportId}?` + new URLSearchParams({title}));
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Tittel endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre tittel');
            return false;
        }
    }

    /** */
    public static async patchText(reportId: number, text: string): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Text/${reportId}?` + new URLSearchParams({text}));
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Beskrivelse endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre beskrivelse');
            return false;
        }
    }

    /** */
    public static async patchInitialMeasure(reportId: number, initialMeasure: string): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(
                `V1/Report/InitialMeasure/${reportId}?` + new URLSearchParams({initialMeasure}));
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Tiltak endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre tiltak');
            return false;
        }
    }

    /** */
    public static async patchResponsible(reportId: number, userId?: number | null): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Responsible/${reportId}/${userId ?? ''}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Ansvarlig endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke endre ansvarlig');
            return false;
        }
    }

    /** */
    public static async patchLocation(reportId: number, locationId?: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Location/${reportId}/${locationId ?? ''}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Lokasjon endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre lokasjon');
            return false;
        }
    }

    /** */
    public static async patchSubLocation(reportId: number, text: string): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(
                `V1/Report/SubLocation/${reportId}?` + new URLSearchParams({text}));
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Sted endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre sted');
            return false;
        }
    }

    /** */
    public static async patchDepartment(reportId: number, departmentId?: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Department/${reportId}/${departmentId ?? ''}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Avdeling endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre avdeling');
            return false;
        }
    }

    /** */
    public static async patchCategory(reportId: number, categoryId: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Category/${reportId}/${categoryId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Kategori endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre kategori');
            return false;
        }
    }

    /** */
    public static async patchDate(reportId: number, date: Date): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`V1/Report/Date/${reportId}/${date.toJSON()}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Dato endret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre dato');
            return false;
        }
    }

    /** */
    public static async postCorrespondent(reportId: number, userId: number): Promise<Correspondent> {
        try {
            const res: Response = await HttpClient.post(`V1/Report/Correspondent/${reportId}/${userId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Bruker lagt til');
            return this.expectObject<Correspondent>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke legge til bruker');
            return {displayName: '', id: 0, reportId: 0, userId: 0};
        }
    }

    /** */
    public static async deleteCorrespondent(reportId: number, userId: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`V1/Report/Correspondent/${reportId}/${userId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Bruker fjernet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke fjerne bruker');
            return false;
        }
    }

    /** */
    public static async putUnits(unit: Unit): Promise<Unit[]> {
        try {
            const res: Response = await HttpClient.put('/V1/Report/Units', unit);
            this.expectSuccessStatusCode(res);
            return this.expectObject<Unit[]>(res);
        } catch (e) {
            this.handleError(e);
            return [];
        }
    }

    /** */
    public static async postUnit(unit: Unit): Promise<number> {
        try {
            const res: Response = await HttpClient.post('/V1/Report/Unit', unit);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Artikkel lagt til');
            return this.expectInt(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke legge til artikkel');
            return 0;
        }
    }

    /** */
    public static async deleteUnit(unitId: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`V1/Report/Unit/${unitId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Artikkel fjernet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke fjerne artikkel');
            return false;
        }
    }

    /** */
    public static async postMeasure(measureNew: MeasureNew): Promise<number> {
        try {
            const res = await HttpClient.post('/V1/Report/Measure', measureNew);
            this.expectSuccessStatusCode(res);
            const id = await this.expectInt(res);
            this.handleSuccess('Tiltak opprettet');
            return id;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre tiltak');
            return 0;
        }
    }

    /** */
    public static async patchMeasure(measure: Measure): Promise<boolean> {
        try {
            const res = await HttpClient.patch('/V1/Report/Measure', measure);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Tiltak lagret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre tiltak');
            return false;
        }
    }

    /** */
    public static async deleteMeasure(unitId: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`V1/Report/Measure/${unitId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Tiltak fjernet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke fjerne tiltak');
            return false;
        }
    }

    /** */
    public static async postComment(reportId: number, text: string, toggle: boolean): Promise<boolean> {
        try {
            const params = new URLSearchParams({text});
            const res: Response = await HttpClient.post(`/V1/Report/Comment/${reportId}/${toggle}?${params}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Kommentar lagret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre kommentar');
            return false;
        }
    }

    /** */
    public static async postSolution(reportId: number, commentId: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.post(`/V1/Report/Solution/${reportId}/${commentId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Kommentar satt som løsning');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne sette som løsning');
            return false;
        }
    }

    /** */
    public static async patchUpVote(commentId: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`/V1/Report/UpVote/${commentId}`);
            this.expectSuccessStatusCode(res);
            return true;
        } catch (e) {
            this.handleError(e, 'Noe gikk galt');
            return false;
        }
    }

    /** */
    public static async postFile(reportId: number, file: File | undefined, mute: boolean = false): Promise<boolean> {
        try {
            const formData = new FormData();
            formData.append('file', file!);
            const res: Response = await HttpClient.post(`/V1/Report/ReportFile/${reportId}`, formData);
            this.expectSuccessStatusCode(res);
            if (!mute) this.handleSuccess('Opplasting fullført');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke laste opp');
            return false;
        }
    }

    /** @returns Full Report */
    public static async getReport(reportId: number): Promise<Report> {
        try {
            const res: Response = await HttpClient.get(`/V1/Report/Report/${reportId}`);
            const report = await this.expectObject<Report>(res);
            HttpClient.initializeDates(report);
            return report;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente rapport');
            return defaultReport();
        }
    }

    /** @returns Reports in category Improvement */
    public static async getImprovements(): Promise<Report[]> {
        try {
            const res: Response = await HttpClient.get('/V1/Report/Improvements');
            const reports = await this.expectObject<Report[]>(res);
            HttpClient.initializeDates(reports);
            return reports;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente forbedringer');
            return [];
        }
    }

    /** @returns Reports user has created */
    public static async getMyReports(): Promise<Report[]> {
        try {
            const res: Response = await HttpClient.get('/V1/Report/MyReports');
            const reports = await this.expectObject<Report[]>(res);
            HttpClient.initializeDates(reports);
            return reports;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente dine hendelsesrapporter');
            return [];
        }
    }

    /** @returns Reports assigned to user */
    public static async getResponsibleReports(): Promise<Report[]> {
        try {
            const res: Response = await HttpClient.get('/V1/Report/ResponsibleReports');
            const reports = await this.expectObject<Report[]>(res);
            HttpClient.initializeDates(reports);
            return reports;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente hendelsesrapporter delegert til deg');
            return [];
        }
    }

    /** @returns Reports user is involved in */
    public static async getInvolvedReports(): Promise<Report[]> {
        try {
            const res: Response = await HttpClient.get('/V1/Report/InvolvedReports');
            const reports = await this.expectObject<Report[]>(res);
            HttpClient.initializeDates(reports);
            return reports;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente hendelsesrapporter du er involvert i');
            return [];
        }
    }

    /** @returns HMS stats object */
    public static async putStats(dateRange: DateRange): Promise<HmsStat[]> {
        try {
            const res: Response = await HttpClient.put('/V1/Report/Stats', dateRange);
            return await this.expectObject<HmsStat[]>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente data');
            return [];
        }
    }

    /** @returns Company group for user */
    public static async getCompanyGroup(company: Company | undefined): Promise<CompanyGroup> {
        try {
            const res: Response = await HttpClient.get(`/V1/Report/CompanyGroup/${company}`);
            this.expectSuccessStatusCode(res);
            return await this.expectObject<CompanyGroup>(res);
        } catch (e) {
            this.handleError(e, 'Noe gikk galt');
            return {responsible: []};
        }
    }

    /** */
    public static async getAnalyses(): Promise<Analysis[]> {
        try {
            const res = await HttpClient.get('/V1/Report/Analyses');
            this.expectSuccessStatusCode(res);
            const analyses = await this.expectObject<Analysis[]>(res);
            HttpClient.initializeDates(analyses);
            return analyses;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente SJA');
            return [];
        }
    }

    /** */
    public static async getAnalysis(id: number): Promise<Analysis> {
        try {
            const res = await HttpClient.get(`/V1/Report/Analysis/${id}`);
            this.expectSuccessStatusCode(res);
            const analysis = await this.expectObject<Analysis>(res);
            HttpClient.initializeDates(analysis);
            return analysis;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente SJA');
            return defaultAnalysis();
        }
    }

    /** */
    public static async postAnalysis(analysis: AnalysisNew): Promise<number> {
        try {
            const res = await HttpClient.post('/V1/Report/Analysis', analysis);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('SJA opprettet');
            return await this.expectInt(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke opprette SJA');
            return 0;
        }
    }

    /** */
    public static async patchAnalysis(id: number, analysis: AnalysisNew): Promise<boolean> {
        try {
            const res = await HttpClient.patch(`/V1/Report/Analysis/${id}`, analysis);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('SJA lagret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre SJA');
            return false;
        }
    }

    /** */
    public static async patchActivities(analysisId: number, activities: Activity[]): Promise<boolean> {
        try {
            const res = await HttpClient.patch(`/V1/Report/Activities/${analysisId}`, activities);
            this.expectSuccessStatusCode(res);
            return true;
        } catch (e) {
            this.handleError(e, 'Noe gikk galt');
            return false;
        }
    }

    /** */
    public static async patchActivity(analysisId: number, activity: Activity): Promise<boolean> {
        try {
            const res = await HttpClient.patch(`/V1/Report/Activity/${analysisId}`, activity);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Aktivitet lagret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre aktivitet');
            return false;
        }
    }

    /** */
    public static async patchEvaluation(analysisId: number, text: string): Promise<boolean> {
        try {
            const query = new URLSearchParams({text});
            const res = await HttpClient.patch(`/V1/Report/Evaluation/${analysisId}?${query}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Evaluering satt');
            return true;
        } catch (e) {
            this.handleError(e, 'Evaluering feilet');
            return false;
        }
    }

    /** */
    public static async patchApproveAnalysis(analysisId: number): Promise<boolean> {
        try {
            const res = await HttpClient.patch(`/V1/Report/ApproveAnalysis/${analysisId}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('SJA godkjent');
            return true;
        } catch (e) {
            this.handleError(e, 'Godkjenning feilet');
            return false;
        }
    }

    /** */
    public static async getAnalysisPdf(analysisId: number): Promise<boolean> {
        try {
            const res = await HttpClient.get(`/V1/Report/AnalysisPdf/${analysisId}`);
            this.expectSuccessStatusCode(res);
            const str = await this.expectString(res);
            window.open(process.env.REACT_APP_BACKEND_URL + str, '_blank');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke generere PDF');
            return false;
        }
    }

    /** */
    public static async postProtection(protection: ProtectionNew): Promise<number> {
        try {
            const res = await HttpClient.post('/V1/Report/Protection', protection);
            this.expectSuccessStatusCode(res);
            const id = await this.expectInt(res);
            this.handleSuccess('Vernerunde opprettet');
            return id;
        } catch (e) {
            this.handleError(e, 'Kunne ikke opprette vernerunde');
            return 0;
        }
    }

    /** */
    public static async getProtections(): Promise<Protection[]> {
        try {
            const res = await HttpClient.get('/V1/Report/Protections');
            this.expectSuccessStatusCode(res);
            const protections = await this.expectObject<Protection[]>(res);
            HttpClient.initializeDates(protections);
            return protections;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente vernerunder');
            return [];
        }
    }

    /** */
    public static async getProtection(id: number): Promise<Protection> {
        try {
            const res = await HttpClient.get(`/V1/Report/Protection/${id}`);
            this.expectSuccessStatusCode(res);
            const protection = await this.expectObject<Protection>(res);
            HttpClient.initializeDates(protection);
            return protection;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente vernerunde');
            return defaultProtection();
        }
    }

    /** */
    public static async patchBulletPoint(protectionId: number, bulletPoint: BulletPoint): Promise<boolean> {
        try {
            const res = await HttpClient.patch(`/V1/Report/BulletPoint/${protectionId}`, bulletPoint);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Sjekkpunkt lagret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre sjekkpunkt');
            return false;
        }
    }

    /** */
    public static async patchProtection(id: number, protectionNew: ProtectionNew): Promise<boolean> {
        try {
            const res = await HttpClient.patch(`/V1/Report/Protection/${id}`, protectionNew);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Endringer lagret');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre vernerunde');
            return false;
        }
    }

    /** */
    public static async postTemplate(name: string, groups: BulletPointGroup[]): Promise<number> {
        try {
            const params = new URLSearchParams({name});
            const res = await HttpClient.post(`/V1/Report/Template?${params}`, groups);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Mal lagret');
            return await this.expectInt(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre mal');
            return 0;
        }
    }

    /** */
    public static async deleteTemplate(id: number): Promise<boolean> {
        try {
            const res = await HttpClient.delete(`/V1/Report/Template/${id}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Mal slettet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke slette mal');
            return false;
        }
    }

    /** */
    public static async getTemplates(): Promise<BulletPointTemplate[]> {
        try {
            const res = await HttpClient.get('/V1/Report/Templates');
            this.expectSuccessStatusCode(res);
            return await this.expectObject<BulletPointTemplate[]>(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente maler');
            return [];
        }
    }
}
