import type {Column, ICellFormatter} from '@syncfusion/ej2-react-grids';
import {ReportCategory} from '../enums/ReportCategory';
import type {MenuItem} from '../types/MenuItem';

interface CategoryText {
    id: ReportCategory;
    text: string;
}

/** Useful functions pertaining to Reports */
export class ReportHelper {

    /** */
    public static reportCategories: CategoryText[] = [
        {id: ReportCategory.deviation, text: 'Avvik'},
        {id: ReportCategory.injury, text: 'Skade'},
        {id: ReportCategory.nearInjury, text: 'Tilløp til skade'},
        {id: ReportCategory.improvement, text: 'Forbedring'},
    ];

    /** */
    public static initialMeasure(category: ReportCategory): string {
        switch (category) {
            case ReportCategory.deviation:
            case ReportCategory.nearInjury:
            case ReportCategory.injury:
                return 'Hva gjorde du med saken og forslag til tiltak';
            case ReportCategory.improvement:
                return 'Forslag til tiltak';
        }
    }

    /** */
    public static descriptionTitle(category: ReportCategory): string {
        switch (category) {
            case ReportCategory.deviation:
            case ReportCategory.nearInjury:
            case ReportCategory.injury:
                return 'Beskrivelse/årsak til hendelse';
            case ReportCategory.improvement:
                return 'Beskrivelse';
        }
    }

    /** */
    public static categoryAccessor(field: string, data: Object): string {
        const category = data[field] as ReportCategory;
        return ReportHelper.reportCategories.find(c => c.id === category)?.text ?? '';
    }

    /** */
    public static menuColors(category: MenuItem): string {
        switch (category) {
            case 'Avvik':
                return '#de7c59';
            case 'Skade':
                return '#ce3333';
            case 'SJA':
                return '#64dccd';
            case 'Tilløp til skade':
                return '#e5b854';
            case 'Vernerunde':
                return '#d979ce';
            case 'Forbedring':
                return '#80d755';
            default:
                return 'transparent';
        }
    }

    public static categoryText(category: ReportCategory): string {
        return this.reportCategories.find(c => c.id === category)?.text ?? '';
    }
}

/** Color */
export class CategoryFormatter implements ICellFormatter {

    public getValue(column: Column, data: Object): Object {
        const category = data[column.field] as ReportCategory;
        let menuItem: MenuItem;
        switch (category) {
            default:
            case ReportCategory.deviation:
                menuItem = 'Avvik';
                break;
            case ReportCategory.nearInjury:
                menuItem = 'Tilløp til skade';
                break;
            case ReportCategory.injury:
                menuItem = 'Skade';
                break;
            case ReportCategory.improvement:
                menuItem = 'Forbedring';
                break;
        }
        return `<span style="color: ${ReportHelper.menuColors(menuItem)};">${menuItem}</span>`;
    }
}

/** */
export function closedAccessor(field: string, data: Object): boolean {
    const id = data[field] as number | null;
    return !!id;
}

/** Closed */
export class ClosedFormatter implements ICellFormatter {

    public getValue(column: Column, data: Object): Object {
        const closedCommentId = data[column.field] as number | null;
        return `<i class="fa-regular ${closedCommentId
            ? 'fa-square-check text-success'
            : 'fa-square-minus text-muted'}"/>`;
    }
}
