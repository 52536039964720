import {Acl, Authorized, StdFormat, TemplateHelper} from '@hapro/template-react';
import type {Subscribe} from '@mathias_frost/simplestores';
import React, {ReactNode} from 'react';
import {Params, useParams} from 'react-router-dom';
import {Comments} from '../../components/Comments';
import {Correspondents} from '../../components/Correspondents';
import {Files} from '../../components/Files';
import {Measures} from '../../components/Measures';
import {ReportForm} from '../../components/ReportForm';
import type {Report} from '../../entities/Report';
import {aclReport} from '../../services/aclStores';
import {closedStore} from '../../services/ClosedStore';
import {ReportManager, reportStore} from '../../services/ReportStore';

interface Props {
    params: Readonly<Params>;
}

interface State {
    loading: boolean;
    report: Report | null;
    closed: boolean;
}

class Internal extends React.Component<Props, State> {

    /** */
    private subscribeReport: Subscribe<Report | null> | null = null;

    /** */
    private subscribeClosed: Subscribe<boolean> | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {loading: true, report: null, closed: true};
        this.reportChange = this.reportChange.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        // Return to '/' if report is malformed
        this.subscribeReport = reportStore.subscribe(value => {
            const report = value;
            if (report && !report.id) window.location.replace('/');
            this.setState({report, loading: !report});
        });
        this.subscribeClosed = closedStore.subscribe(value => this.setState({closed: value}));

        const id = Number(this.props.params.reportId);
        if (this.state.report?.id !== id) {
            await ReportManager.updateReport(id);
        }
    }

    /** @inheritDoc */
    public componentWillUnmount(): void {
        reportStore.unsubscribe(this.subscribeReport);
        closedStore.unsubscribe(this.subscribeClosed);
    }

    public override render(): ReactNode {
        const {loading, report, closed} = this.state;
        const {params} = this.props;

        return (
            <section className={'mt-5'}>
                <div className={'container'}>
                    <h1>Hendelsesrapport <span className={'text-muted'}>&mdash; #{params.reportId}</span></h1>
                </div>
                <div className={'container-fluid'}>
                    <hr/>
                    <Authorized loading={loading} aclStore={aclReport} acl={Acl.create}>

                        {/* Closed notice */}
                        {report?.closedCommentId ?
                            <div className={'alert alert-dark border border-primary d-flex' +
                                ' justify-content-between align-items-baseline'}>
                                <i className={'fa-light fa-exclamation'}/>
                                <span>Denne hendelsesrapporten ble lukket {TemplateHelper.toStdDate(
                                    report.closedDate, StdFormat.dateTime)}</span>
                                <i className={'fa-light fa-exclamation'}/>
                            </div>
                            : null}

                        <div className={'row'}>

                            {/* 1st Column */}
                            <div className={'col-lg-4 col-md-6 col-sm-12'}>

                                {/* Details */}
                                <div className={'card mt-3'}>
                                    <div className={'card-header'}>
                                        <h5 className={'card-title'}>Detaljer om Hendelsen</h5>
                                    </div>
                                    <div className={'card-body'}>
                                        <ReportForm report={report!} closed={closed}/>
                                    </div>
                                </div>

                            </div>

                            {/*  2nd Column */}
                            <div className={'col-lg-4 col-md-6 col-sm-12'}>

                                {/*  Correspondents */}
                                <div className={'card mt-3'}>
                                    <div className={'card-header'}>
                                        <h5 className={'card-title'}>Involverte</h5>
                                    </div>
                                    <div className={'card-body'}>
                                        <Correspondents report={report!} closed={closed}/>
                                    </div>
                                </div>

                                {/*  Files */}
                                <div className={'card mt-3'}>
                                    <div className={'card-header'}>
                                        <h5 className={'card-title'}>Filer</h5>
                                    </div>
                                    <div className={'card-body'}>
                                        <Files report={report!} closed={closed}/>
                                    </div>
                                </div>

                            </div>
                            {/*  2nd Column end */}

                            {/*  3nd Column */}
                            <div className={'col-lg-4 col-md-6 col-sm-12'}>

                                {/*  Comments */}
                                <div className={'card mt-3'}>
                                    <div className={'card-header'}>
                                        <h5 className={'card-title'}>Kommentarer</h5>
                                    </div>
                                    <div className={'card-body'}>
                                        <Comments report={report!} closed={closed}/>
                                    </div>
                                </div>

                            </div>
                            {/*  3rd Column end */}

                        </div>

                        <div className={'mt-3'}>
                            <Measures report={report} onChange={this.reportChange} closed={closed}/>
                        </div>

                        {/*  row end */}
                    </Authorized>
                </div>
                {/*  container-fluid end */}

            </section>
        );
    }

    /** */
    private reportChange(report: Report): void {
        this.setState({report});
    }
}

export function ReportPage() {
    const params = useParams();
    return <Internal params={params}/>;
}
