import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactNode} from 'react';
import {FieldLength} from '../bases/FieldBase';
import type {SubLocation} from '../entities/SubLocation';
import {defaultSubLocation} from '../entities/SubLocation';
import {MiscHelper} from '../helpers/MiscHelper';
import {ReportService} from '../services/ReportService';

interface Props {
    text: string;
    onChange: (value: SubLocation) => void;
    readOnly?: boolean;
}

interface State {
    subLocations: SubLocation[];
}

export class SubLocationSelect extends React.Component<Props, State> {

    private fields: FieldSettingsModel = {value: 'id', text: 'text'};

    public constructor(props: Props) {
        super(props);
        this.state = {subLocations: []};
        this.change = this.change.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const subLocations = await ReportService.getSubLocations();
        this.setState({subLocations});
    }

    public override render(): ReactNode {
        const {subLocations} = this.state;
        const {text, readOnly} = this.props;

        return <ComboBoxComponent text={text} readonly={readOnly}
                                  placeholder={'Uspesifisert'}
                                  dataSource={subLocations as any}
                                  noRecordsTemplate={'Fant ingen lokasjoner'}
                                  fields={this.fields}
                                  filterType={'Contains'}
                                  allowFiltering={true}
                                  ignoreCase={true}
                                  onChange={this.change}/>;
    }

    /** */
    private change(e: any) {
        const {onChange} = this.props;
        const {subLocations} = this.state;
        if (typeof e.value === 'string') {
            e.vaule = e.value ? MiscHelper.summary(e.value, FieldLength.text) : '';
            onChange(defaultSubLocation(e.value ?? ''));
        } else if (typeof e.value === 'number') {
            const subLocation = subLocations.find(subLocation => subLocation.id === e.value);
            onChange(subLocation ?? defaultSubLocation());
        }
    }
}
