import type {TaskNewBase} from '../bases/TaskNewBase';
import type {Activity} from '../entities/Activity';

/** For creating and editing Analyses */
export interface AnalysisNew extends TaskNewBase {
    activities: Activity[];
    project: string;
    responsible: number | null;
}

/** */
export function defaultAnalysisNew(): AnalysisNew {
    return {
        title: '',
        createdBy: 0,
        locationId: null,
        departmentId: null,
        subLocationText: '',
        project: '',
        description: '',
        participants: [],
        externalUsers: [],
        activities: [],
        responsible: null,
        date: new Date(),
    };
}
