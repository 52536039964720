import type {FieldBase} from '../bases/FieldBase';

/** Library of sub-locations used in HMS */
export interface SubLocation extends FieldBase {
}

/** */
export function defaultSubLocation(text?: string): SubLocation {
    return {
        id: 0,
        text: text ?? '',
    };
}
