import type {Column, ICellFormatter} from '@syncfusion/ej2-react-grids';
import type {Analysis} from '../entities/Analysis';
import type {Protection} from '../entities/Protection';
import {PointStatus} from '../enums/PointStatus';
import {RiskType} from '../enums/RiskType';
import type {AnalysisNew} from '../models/AnalysisNew';
import type {ProtectionNew} from '../models/ProtectionNew';

interface StatusTextIcon {
    id: PointStatus;
    text: string;
    iconCss: string;
}

/** */
export class TaskHelper {

    /** */
    public static riskIcons: Map<RiskType, string> = new Map([
        [RiskType.low, 'fa-solid fa-square fa-xl text-success'],
        [RiskType.mid, 'fa-solid fa-square fa-xl text-warning'],
        [RiskType.high, 'fa-solid fa-square fa-xl text-danger'],
    ]);

    /** */
    public static statusIcons: Map<PointStatus, { text: string, iconCss: string }> = new Map([
        [PointStatus.pending, {text: 'Ikke sjekket', iconCss: 'fa-solid fa-play-circle text-muted'}],
        [PointStatus.notApplicable, {text: 'Ikke akutelt', iconCss: 'fa-solid fa-minus-circle text-info'}],
        [PointStatus.improvement, {text: 'Forbedring', iconCss: 'fa-light fa-lightbulb-on text-warning'}],
        [PointStatus.ok, {text: 'Ok', iconCss: 'fa-light fa-check-circle text-success'}],
        [PointStatus.deviation, {text: 'Avvik', iconCss: 'fa-light fa-times-circle text-danger'}],
    ]);

    /** */
    public static statuses: StatusTextIcon[] = [
        {id: PointStatus.pending, ...TaskHelper.statusIcons.get(PointStatus.pending)!},
        {id: PointStatus.notApplicable, ...TaskHelper.statusIcons.get(PointStatus.notApplicable)!},
        {id: PointStatus.improvement, ...TaskHelper.statusIcons.get(PointStatus.improvement)!},
        {id: PointStatus.ok, ...TaskHelper.statusIcons.get(PointStatus.ok)!},
        {id: PointStatus.deviation, ...TaskHelper.statusIcons.get(PointStatus.deviation)!},
    ];

    /** */
    public static statusTemplate(args: StatusTextIcon): JSX.Element {
        return (
            <div className={'d-flex p-1 gap-2 align-baseline'}>
                <i className={args.iconCss + ' ms-2 mt-auto mb-auto'}/>{args.text}
            </div>
        );
    }

    /** */
    public static canManageProtection(protection: Protection | null, userId?: number): boolean {
        return protection?.participants.some(u => u.userId === userId)
            || protection?.createdBy === userId;
    }

    /** */
    public static canManageAnalysis(analysis: Analysis | null, userId?: number): boolean {
        return analysis?.participants.some(u => u.userId === userId)
            || analysis?.createdBy === userId
            || analysis?.responsible === userId;
    }

    /** */
    public static canManageProtectionNew(protection: ProtectionNew, userId?: number): boolean {
        return protection.participants.some(u => u === userId)
            || protection.createdBy === userId;
    }

    /** */
    public static canManageAnalysisNew(analysis: AnalysisNew, userId?: number): boolean {
        return analysis.participants.some(u => u === userId)
            || analysis.createdBy === userId
            || analysis.responsible === userId;
    }
}

export class DeviationFormatter implements ICellFormatter {
    public getValue(column: Column, data: Object): Object {
        const num = data[column.field];
        if (typeof num !== 'number') {
            return 'ERR';
        }

        if (num > 0) {
            return `<b class="text-danger">${num}</b>`;
        }

        return num.toString();
    }
}
