import {Acl, Authorized, FileForm, Modal, SpinnerHelper, userStore} from '@hapro/template-react';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ChangeEvent, ReactNode} from 'react';
import React from 'react';
import {Link, NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {ResponsibleSelect} from '../../components/ResponsibleSelect';
import {RiskSelect} from '../../components/RiskSelect';
import {TaskProgress} from '../../components/TaskProgress';
import type {Activity} from '../../entities/Activity';
import {defaultActivity} from '../../entities/Activity';
import type {Analysis} from '../../entities/Analysis';
import {RiskType} from '../../enums/RiskType';
import {MiscHelper} from '../../helpers/MiscHelper';
import {TaskHelper} from '../../helpers/TaskHelper';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    params: URLSearchParams;
    navigate: NavigateFunction;
}

interface State {
    analysis: Analysis | null;

    /** Activity we are currently viewing/editing */
    selected: Activity | null;
    loading: boolean;
}

class Internal extends React.Component<Props, State> {

    /** */
    private modal: Modal | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {analysis: null, selected: null, loading: true};
        this.addActivity = this.addActivity.bind(this);
        this.saveActivity = this.saveActivity.bind(this);
        this.selectActivity = this.selectActivity.bind(this);
        this.removeActivity = this.removeActivity.bind(this);
        this.completedChange = this.completedChange.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const id = Number(this.props.params.get('id'));
        const activityId = Number(this.props.params.get('activityId'));
        if (!this.props.params.has('id') || isNaN(id)) {
            this.props.navigate('/analysis', {replace: true});
            return;
        }

        const analysis = await ReportService.getAnalysis(id);
        this.setState({analysis, loading: false}, () => {

            // Auto open activity if id is specified
            if (activityId) {
                const selected = analysis.activities.find(a => a.id === activityId);
                if (selected) {
                    this.setState({analysis, selected}, () => this.modal?.show());
                }
            }
        });
    }

    public override render(): ReactNode {
        const {analysis, selected, loading} = this.state;
        const {params} = this.props;

        const id = params.get('id');
        const canManage = TaskHelper.canManageAnalysis(analysis, userStore.value?.userMinimal?.id);

        return (
            <section className={'container mt-5'}>
                <h1>Sikker jobbanalyse - Kartlegging #{id}</h1>
                <hr/>
                <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                    <TaskProgress step={2} id={id} type={'analysis'}/>

                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Aktiviteter</h5>
                        </div>
                        <div className={'card-body'}>
                            <p className={'form-label'}>Sikker jobbanalyse:</p>
                            <h5>{analysis?.title}</h5>
                            <hr/>
                            <p className={'form-label'}>Aktiviteter:</p>
                            <div className={'d-flex flex-column gap-3'}>
                                {analysis?.activities.map((activity, i) =>
                                    <div key={i}
                                         className={'bg-dark ps-3 pe-3 pt-1 pb-1 rounded selectable d-flex justify-content-between'}>
                                        <div className={'w-100 mt-auto mb-auto'}
                                             onClick={() => this.selectActivity(activity)}>
                                            {activity.label ?
                                                <span>{activity.label}</span>
                                                :
                                                <span className={'text-muted'}>Ingen tittel</span>
                                            }
                                        </div>
                                        {canManage ?
                                            <button className={'btn btn-sm'} onClick={() => this.removeActivity(i)}>
                                                <i className={'fa-solid fa-trash text-danger'}/>
                                            </button>
                                            : null}
                                    </div>,
                                )}
                            </div>
                            {canManage ?
                                <button className={'btn btn-sm mt-3'} onClick={this.addActivity}>
                                    <i className={'fa-solid fa-plus text-success me-2'}/>Legg til aktivitet
                                </button>
                                : null}
                        </div>
                        <div className={'card-footer d-flex justify-content-between'}>
                            <Link to={`/analysis/edit?id=${id}`} className={'btn btn-primary'}>
                                <i className={'fa-solid fa-fast-backward me-2'}/>Forrige
                            </Link>
                            <Link to={`/analysis/report?id=${id}`} className={'btn btn-primary'}>
                                <i className={'fa-solid fa-fast-forward me-2'}/>Neste
                            </Link>
                        </div>
                    </div>

                    <Modal ref={m => this.modal = m} animation={true} size={'lg'} centered={true}
                           onHidden={() => this.setState({selected: null})}>
                        <div className={'modal-header'}>
                            <h5 className={'modal-title'}>Aktivitet: <b>{selected?.label ? selected.label : 'Ny'}</b>
                            </h5>
                            <button className={'btn-close'} onClick={() => this.modal?.hide()}/>
                        </div>
                        {selected ?
                            <div className={'modal-body'}>
                                <p className={'form-label'}>Aktivitet/deloppgave</p>
                                <TextBoxComponent value={selected.label} change={e => selected.label = e.value}
                                                  readOnly={!canManage}/>

                                <p className={'form-label mt-3'}>Mulig risiko</p>
                                <TextBoxComponent value={selected.description} multiline={true} readOnly={!canManage}
                                                  change={e => selected.description = e.value}/>

                                <p className={'form-label mt-3'}>Risiko før tiltak</p>
                                <RiskSelect value={selected.riskBefore} readOnly={!canManage}
                                            onChange={e => this.setState({selected: {...selected, riskBefore: e}})}/>

                                <p className={'form-label mt-3'}>Tiltak</p>
                                <TextBoxComponent value={selected.measure ?? ''} multiline={true}
                                                  change={e => selected.measure = e.value} readOnly={!canManage}
                                                  placeholder={'Ikke nødvendig hvis lav risiko'}/>

                                <p className={'form-label mt-3'}>Ansvarlig</p>
                                <ResponsibleSelect value={selected.responsible ?? 0} placeholder={'Deg selv'}
                                                   readOnly={!canManage}
                                                   onChange={e => (selected.responsible = e?.id ?? 0)
                                                       || (selected.responsibleDisplayName = e?.displayName ?? '')}/>

                                <p className={'form-label mt-3'}>Tiltak utført?</p>
                                <div className={'form-check form-switch'}>
                                    <input className={'form-check-input pointer'} type={'checkbox'}
                                           checked={selected.completed} onChange={this.completedChange}
                                           disabled={!canManage}/>
                                </div>

                                {selected.completed ? <>
                                    <p className={'form-label mt-3'}>Risiko etter tiltak</p>
                                    <RiskSelect value={selected.riskAfter} readOnly={!canManage}
                                                onChange={e => this.setState({selected: {...selected, riskAfter: e}})}/>
                                </> : null}

                                <p className={'form-label mt-3'}>Filer</p>
                                <FileForm
                                    files={selected.files} // TODO: selected becomes null when uploading. No idea why
                                    noUpload={!canManage} noRemove={!canManage}
                                    download={`/V1/Report/ActivityFile/${analysis?.id}/${selected.id}`}
                                    upload={`/V1/Report/ActivityFile/${analysis?.id}/${selected.id}`}
                                    remove={`/V1/Report/ActivityFile/${analysis?.id}/${selected.id}`}/>
                            </div>
                            : null}
                        {canManage ?
                            <div className={'modal-footer'}>
                                <button className={'btn btn-success'} onClick={this.saveActivity}>
                                    <i className={'fa-solid fa-save me-2'}/>Lagre og lukk
                                </button>
                            </div>
                            : null}
                    </Modal>

                </Authorized>
            </section>
        );
    }

    /** */
    private completedChange(e: ChangeEvent<HTMLInputElement>): void {
        const {selected} = this.state;
        if (selected) {
            selected.completed = e.target.checked;
            selected.riskAfter = selected.completed ? RiskType.low : null;
            this.setState({selected});
        }
    }

    /** */
    private async addActivity(): Promise<void> {
        let {analysis} = this.state;
        if (analysis) {
            SpinnerHelper.show();
            const selected = defaultActivity();
            analysis.activities.push(selected);
            const success = await ReportService.patchActivities(analysis.id, analysis.activities);
            if (success) {
                analysis = await ReportService.getAnalysis(analysis.id);
                const activity = analysis.activities[analysis.activities.length - 1];
                selected.id = activity.id;
                this.setState({analysis, selected}, () => this.modal?.show());
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private async removeActivity(index: number): Promise<void> {
        const {analysis} = this.state;
        if (analysis) {
            SpinnerHelper.show();
            analysis.activities.splice(index, 1);
            const success = await ReportService.patchActivities(analysis.id, analysis.activities);
            if (success) {
                this.setState({analysis});
            }
            SpinnerHelper.hide();
        }
    }

    /** Create a dummy object to make changes on before saving to make it easier to reset when closing modal without saving */
    private selectActivity(activity: Activity): void {
        const selected = {...activity};
        this.setState({selected}, () => this.modal?.show());
    }

    /** */
    private async saveActivity(): Promise<void> {
        SpinnerHelper.block();
        const {analysis, selected} = this.state;
        if (analysis?.id && selected) {
            selected.label = selected.label ? selected.label : MiscHelper.summary(selected.description);
            const success = await ReportService.patchActivity(analysis.id, selected);
            if (success) {
                const index = analysis.activities.findIndex(a => a.id === selected.id);
                analysis.activities[index] = {...selected};
                this.setState({selected}, () => this.modal?.hide());
            }
        }
        SpinnerHelper.unblock();
    }
}

/** */
export function Execute() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    return (<Internal params={params} navigate={navigate}/>);
}
