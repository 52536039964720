import type {ProxyFile} from '@hapro/template-react';
import {ReportCategory} from '../enums/ReportCategory';
import type {Comment} from './Comment';
import type {Correspondent} from './Correspondent';
import type {Department} from './Department';
import type {Injury} from './Injury';
import type {Location} from './Location';
import type {Measure} from './Measure';
import type {SubLocation} from './SubLocation';
import type {Unit} from './Unit';

/** One of three main systems of HMS */
export interface Report {
    id: number;
    created: Date;
    createdBy: number;
    createdDisplayName: string;
    type: string;
    title: string;
    text: string;
    date: Date;
    responsible: number;
    responsibleDisplayName: string;
    initialMeasure: string;
    category: ReportCategory;
    closedCommentId: number | null;
    closedDate: Date | null;
    injury: Injury | null;
    correspondents: Correspondent[];
    units: Unit[];
    files: ProxyFile[];
    comments: Comment[];
    measures: Measure[];
    department: Department | null;
    location: Location | null;
    subLocation: SubLocation | null;
}

/** Max lengths for Report string fields */
export abstract class ReportLength {
    static text: number = 1_000;
    static title: number = 200;
}

/** Default Report object */
export function defaultReport(): Report {
    return {
        id: 0,
        created: new Date(),
        createdBy: 0,
        createdDisplayName: '',
        type: '',
        title: '',
        text: '',
        initialMeasure: '',
        date: new Date(),
        category: ReportCategory.deviation,
        responsible: 0,
        responsibleDisplayName: '',
        closedCommentId: null,
        closedDate: null,
        injury: null,
        comments: [],
        correspondents: [],
        files: [],
        units: [],
        measures: [],
        location: null,
        subLocation: null,
        department: null,
    };
}
