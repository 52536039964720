import {HttpClient, ServiceBase} from '@hapro/template-react';
import type {Injury} from '../entities/Injury';

/** Backend interaction for high access level operations */
export class AdminService extends ServiceBase {

    /** */
    public static async putDepartment(text: string, old?: string): Promise<number> {
        try {
            const params = new URLSearchParams({text, old: old ?? ''});
            const res: Response = await HttpClient.put(`/V1/Admin/Department?${params}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Avdeling lagret');
            return await this.expectInt(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre avdeling');
            return 0;
        }
    }

    /** */
    public static async putLocation(text: string, old?: string): Promise<number> {
        try {
            const params = new URLSearchParams({text, old: old ?? ''});
            const res: Response = await HttpClient.put(`/V1/Admin/Location?${params}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('lokasjon lagret');
            return await this.expectInt(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre lokasjon');
            return 0;
        }
    }

    /** */
    public static async putSubLocation(text: string, old?: string): Promise<number> {
        try {
            const params = new URLSearchParams({text, old: old ?? ''});
            const res: Response = await HttpClient.put(`/V1/Admin/SubLocation?${params}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Sted lagret');
            return await this.expectInt(res);
        } catch (e) {
            this.handleError(e, 'Kunne ikke lagre sted');
            return 0;
        }
    }

    /** */
    public static async deleteDepartment(id: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`/V1/Admin/Department/${id}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Avdeling slettet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke slette avdeling');
            return false;
        }
    }

    /** */
    public static async deleteLocation(id: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`/V1/Admin/Location/${id}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Lokasjon slettet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke slette lokasjon');
            return false;
        }
    }

    /** */
    public static async deleteSubLocation(id: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`/V1/Admin/SubLocation/${id}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Sted slettet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke slette sted');
            return false;
        }
    }

    /** */
    public static async patchInjury(
        reportId: number, injury: Injury, field: 'daysAbsent' | 'reported'): Promise<boolean> {
        try {
            const res: Response = await HttpClient.patch(`/V1/Admin/Injury/${reportId}`, injury);
            this.expectSuccessStatusCode(res);
            this.handleSuccess(
                `${field === 'daysAbsent' ? 'Antall fraværsdager' : 'Dato meldt inn som yrkesskade'} endret`);
            return true;
        } catch (e) {
            this.handleError(e, 'Noe gikk galt');
            return false;
        }
    }

    /** */
    public static async deleteReport(id: number): Promise<boolean> {
        try {
            const res: Response = await HttpClient.delete(`/V1/Admin/Report/${id}`);
            this.expectSuccessStatusCode(res);
            this.handleSuccess('Repport slettet');
            return true;
        } catch (e) {
            this.handleError(e, 'Kunne ikke slette rapport');
            return false;
        }
    }
}
