import {Acl, Authorized, TemplateHelper} from '@hapro/template-react';
import type {RecordDoubleClickEventArgs} from '@syncfusion/ej2-grids';
import {
    ColumnDirective,
    ColumnsDirective,
    ContextMenu,
    ContextMenuClickEventArgs,
    ContextMenuItemModel,
    Filter,
    GridComponent,
    Group,
    Inject,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import type {ClickEventArgs} from '@syncfusion/ej2-react-navigations';
import type {ReactNode} from 'react';
import React from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {fieldAccessor} from '../../bases/FieldBase';
import type {Analysis as AnalysisModel} from '../../entities/Analysis';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    navigate: NavigateFunction;
}

interface State {
    analyses: AnalysisModel[];
    loading: boolean;
}

class Internal extends React.Component<Props, State> {

    private static menuItems: ContextMenuItemModel[] = [
        {text: 'Rediger', iconCss: 'fa-solid fa-edit'},
        {text: 'Kartlegging', iconCss: 'fa-solid fa-map'},
        {text: 'Rapport', iconCss: 'fa-solid fa-file-lines'},
    ];

    // noinspection DuplicatedCode
    public constructor(props: Props) {
        super(props);
        this.state = {analyses: [], loading: true};
        this.toolbarClick = this.toolbarClick.bind(this);
        this.doubleClick = this.doubleClick.bind(this);
        this.contextMenuClick = this.contextMenuClick.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const analyses = await ReportService.getAnalyses();
        this.setState({analyses, loading: false});
    }

    public override render(): ReactNode {
        const {analyses, loading} = this.state;

        return (
            <section className={'mt-5'}>
                <div className={'container'}>
                    <h1>Sikker Jobbanalyse</h1>
                    <hr/>
                </div>
                <div className={'container-fluid'}>
                    <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                        <GridComponent dataSource={analyses as any} allowGrouping={true} allowFiltering={true}
                                       filterSettings={{type: 'Menu'}} allowSorting={true}
                                       searchSettings={{
                                           fields: [
                                               'registeredBy',
                                               'departmentDisplayName',
                                               'title',
                                               'status',
                                           ],
                                       }}
                                       toolbar={['Search', 'Add']}
                                       editSettings={{allowAdding: true, allowDeleting: true}}
                                       toolbarClick={this.toolbarClick} recordDoubleClick={this.doubleClick}
                                       contextMenuItems={Internal.menuItems} contextMenuClick={this.contextMenuClick}
                                       sortSettings={{columns: [{field: 'date', direction: 'Descending'}]}}>
                            <ColumnsDirective>
                                <ColumnDirective field={'created'} headerText={'Opprettet'} type={'Date'}
                                                 format={TemplateHelper.date} width={'7rem'} allowGrouping={false}/>
                                <ColumnDirective field={'title'} headerText={'Tittel'} width={'9rem'}
                                                 allowGrouping={false}/>
                                <ColumnDirective field={'department.text'} headerText={'Avdeling'} width={'7rem'}
                                                 valueAccessor={fieldAccessor}/>
                                <ColumnDirective field={'location.text'} headerText={'Lokasjon'} width={'7rem'}
                                                 valueAccessor={fieldAccessor}/>
                            </ColumnsDirective>
                            <Inject services={[Sort, Group, Filter, Toolbar, ContextMenu]}/>
                        </GridComponent>
                    </Authorized>
                </div>
            </section>
        );
    }

    /** */
    private contextMenuClick(args: ContextMenuClickEventArgs): void {
        if (!args.rowInfo?.rowData) {
            return;
        }
        const id = (args.rowInfo.rowData as AnalysisModel).id;
        switch (args.item.text) {
            case 'Rediger':
                this.props.navigate(`/analysis/edit?id=${id}`);
                break;
            case 'Kartlegging':
                this.props.navigate(`/analysis/execute?id=${id}`);
                break;
            case 'Rapport':
                this.props.navigate(`/analysis/report?id=${id}`);
                break;
        }
    }

    /** */
    private doubleClick(args: RecordDoubleClickEventArgs): void {
        const analysis = args.rowData as AnalysisModel;
        const param = new URLSearchParams({id: analysis.id.toString()});
        this.props.navigate(`/analysis/execute?${param}`);
    }

    /** */
    private toolbarClick(args: ClickEventArgs): void {
        if (args.item.text === 'Add') {
            this.props.navigate('/analysis/edit');
        }
    }
}

/** */
export function Analysis() {
    const navigate = useNavigate();
    return (<Internal navigate={navigate}/>);
}
