import type {TaskNewBase} from '../bases/TaskNewBase';
import type {BulletPointGroup} from '../entities/BulletPointGroup';

/** For creating and editing Protections */
export interface ProtectionNew extends TaskNewBase {
    bulletPointGroups: BulletPointGroup[];
}

export function defaultProtectionNew(): ProtectionNew {
    return {
        title: '',
        createdBy: 0,
        date: new Date(),
        locationId: null,
        departmentId: null,
        subLocationText: '',
        description: '',
        participants: [],
        externalUsers: [],
        bulletPointGroups: [],
    };
}
