import type {BulletPointGroup} from './BulletPointGroup';
import type {Department} from './Department';
import type {Location} from './Location';
import type {ProtectionExternalUser} from './ProtectionExternalUser';
import type {ProtectionParticipant} from './ProtectionParticipant';
import type {SubLocation} from './SubLocation';

/** */
export interface Protection {
    id: number;
    date: Date;
    title: string;
    description: string;
    created: Date;
    createdBy: number;
    createdDisplayName: string;
    department: Department | null;
    location: Location | null;
    subLocation: SubLocation | null;
    status: string;
    open: number;
    total: number;
    bulletPointGroups: BulletPointGroup[];
    participants: ProtectionParticipant[];
    externalUsers: ProtectionExternalUser[];
}

/** One of three main systems of HMS */
export const defaultProtection: () => Protection = () => ({
    id: 0,
    date: new Date(),
    title: '',
    description: '',
    created: new Date(),
    createdBy: 0,
    createdDisplayName: '',
    department: null,
    location: null,
    subLocation: null,
    status: '',
    open: 0,
    total: 0,
    bulletPointGroups: [],
    participants: [],
    externalUsers: [],
});
