import {userStore} from '@hapro/template-react';
import {Store} from '@mathias_frost/simplestores';
import type {Comment} from '../entities/Comment';
import type {Report} from '../entities/Report';
import {closedStore} from './ClosedStore';
import {ReportService} from './ReportService';

export const reportStore = new Store<Report | null>(null);

export class ReportManager {

    static async updateReport(reportId: number): Promise<void> {
        const report = await ReportService.getReport(reportId);
        report.comments.sort(this.compareDate);
        const user = userStore.value?.userMinimal;
        const group = await ReportService.getCompanyGroup(user?.company);
        const isHmsResponsible = group.responsible.includes(user?.id ?? 0) || user?.id === 585 // Elsa Randy Lynne TODO: THIS MUST BE REMOVED WHEN RESPONSIBLE SYSTEM IS REVAMPED;
        closedStore.value = !!report.closedCommentId && !isHmsResponsible;
        reportStore.value = report;
    }

    private static compareDate(a: Comment, b: Comment): number {
        if (a.created < b.created) {
            return -1;
        }
        if (a.created > b.created) {
            return 1;
        }
        return 0;
    }
}
