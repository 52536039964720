import type {UserMinimal} from '@hapro/template-react';
import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactNode} from 'react';
import {ReportService} from '../services/ReportService';

interface Props {
    value: number | null;
    onChange: (value: UserMinimal | null) => void;
    placeholder?: string;
    readOnly?: boolean;
}

interface State {
    users: UserMinimal[];
}

/** */
export class ResponsibleSelect extends React.Component<Props, State> {

    /** */
    private fields: FieldSettingsModel = {value: 'id', text: 'displayName', groupBy: 'departmentName'};

    public constructor(props: Props) {
        super(props);
        this.state = {users: []};
        this.change = this.change.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const users = await ReportService.getUsers();
        this.setState({users});
    }

    public override render(): ReactNode {
        const {users} = this.state;
        const {value, placeholder, readOnly} = this.props;

        if (users.length) {
            return (<ComboBoxComponent value={value ?? undefined} readonly={readOnly}
                                       dataSource={users as any}
                                       noRecordsTemplate={'Fant ingen ansatte'}
                                       fields={this.fields}
                                       filterType={'Contains'}
                                       allowFiltering={true}
                                       ignoreCase={true}
                                       allowCustom={false}
                                       placeholder={placeholder ?? 'Nærmeste leder'}
                                       onChange={this.change}/>);

        }

        return (<p className={'bg-dark rounded ps-3 pe-3 pt-1 pb-1'}>Loading...</p>);
    }

    /** */
    private change(e: any) {
        const user = this.state.users.find(u => u.id === e.value);
        this.props.onChange(user ?? null);
    }
}
