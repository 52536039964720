import {Acl, Authorized} from '@hapro/template-react';
import React, {ReactNode} from 'react';
import {ReportCell} from '../components/ReportCell';
import {aclReport} from '../services/aclStores';

interface Props {

}

interface State {

}

export class Home extends React.Component<Props, State> {

    public override render(): ReactNode {
        return (
            <section className={'container-fluid'}>
                <Authorized aclStore={aclReport} acl={Acl.create}>
                    <div className={'row h-100 mt-md-3 mt-5'}>
                        <ReportCell category={'Avvik'} icon={'fa-duotone fa-triangle-exclamation'}/>
                        <ReportCell category={'Skade'} icon={'fa-duotone fa-person-falling-burst'}/>
                        <ReportCell category={'SJA'} icon={'fa-duotone fa-microscope'}/>
                        <ReportCell category={'Tilløp til skade'} icon={'fa-duotone fa-bullhorn'}/>
                        <ReportCell category={'Forbedring'} icon={'fa-duotone fa-lightbulb-on'}/>
                        <ReportCell category={'Vernerunde'} icon={'fa-duotone fa-memo-circle-check'}/>
                    </div>
                </Authorized>
            </section>
        );
    }
}
