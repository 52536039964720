import {Acl, Authorized, TemplateHelper} from '@hapro/template-react';
import type {DateRange} from '@syncfusion/ej2-calendars';
import {DateRangePickerComponent, RangeEventArgs} from '@syncfusion/ej2-react-calendars';
import {
    Category,
    ChartComponent,
    Inject as ChartInject,
    Legend,
    SeriesCollectionDirective,
    SeriesDirective,
    SplineSeries,
    Tooltip,
} from '@syncfusion/ej2-react-charts';
import {
    Aggregate,
    ColumnDirective,
    ColumnsDirective,
    ExcelExport,
    Filter,
    GridComponent,
    Inject,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import type {ToolbarClickEventArgs} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import type {HmsStat} from '../entities/HmsStat';
import {aclReport} from '../services/aclStores';
import {ReportService} from '../services/ReportService';

interface Props {

}

interface State {
    loading: boolean;
    stats: HmsStat[];
    range: DateRange;
}

/** */
export class Stats extends React.Component<Props, State> {

    /** */
    private grid: GridComponent | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {loading: true, stats: [], range: {}};
        this.dateRangeChange = this.dateRangeChange.bind(this);
        this.toolbarClick = this.toolbarClick.bind(this);
    }

    /** */
    private static filterStats(stats: HmsStat[], range: DateRange): HmsStat[] {
        return stats.filter(stat =>
            (!range.start
                || stat.year > range.start.getFullYear()
                || (stat.year === range.start.getFullYear() && stat.month >= range.start.getMonth() + 1))
            && (!range.end
                || stat.year < range.end.getFullYear()
                || (stat.year === range.start?.getFullYear() && stat.month <= range.end.getMonth() + 1)),
        );
    }

    /** @inheritDoc */
    public async componentDidMount(): Promise<void> {
        const stats = await ReportService.putStats({});

        // H- and F-Value
        for (let stat of stats) {
            stat.hValue = (stat.injuriesWithAbsence * 1_000_000) / stat.hours;
            stat.hValue = isNaN(stat.hValue) ? 0 : stat.hValue;
            stat.fValue = (stat.injuryDays * 1_000_000) / stat.hours;
            stat.fValue = isNaN(stat.fValue) ? 0 : stat.fValue;

            stat.dateLabel = (stat.year * 100) + stat.month;
        }

        // Average per year H- and F-Value
        for (const stat of stats) {
            const hYearStats = stats.filter(s => s.year === stat.year);
            const hSum = hYearStats.reduce((prev, curr) => prev + curr.hValue, 0);
            stat.hYear = hSum / hYearStats.length;

            const fYearStats = stats.filter(s => s.year === stat.year);
            const fSum = fYearStats.reduce((prev, curr) => prev + curr.fValue, 0);
            stat.fYear = fSum / fYearStats.length;
        }

        // Accumulated H- and F-Value
        for (let i = 0; i < stats.length; i++) {
            const stat = stats[i];
            stat.hAccumulated = stats.slice(i - 12, i).reduce((prev, curr) => prev + curr.hValue, 0);
            stat.fAccumulated = stats.slice(i - 12, i).reduce((prev, curr) => prev + curr.fValue, 0);
        }

        this.setState({stats, loading: false});
    }

    public override render(): ReactNode {
        const {loading, stats, range} = this.state;
        //const {} = this.props;

        const filtered = Stats.filterStats(stats, range);

        return (
            <section className={'mt-5'}>
                <div className={'container'}>
                    <h1>Statistikk</h1>
                    <small className={'text-muted'}>NB: Viser statistikk for hele Hapro AS konsern</small>
                    <hr/>
                </div>
                <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                    <div className={'container'}>
                        <div className={'row mb-3'}>
                            <div className={'col-4'}>
                                <p className={'form-label'}>Tidsperspektiv</p>
                                <DateRangePickerComponent change={this.dateRangeChange} format={TemplateHelper.date}/>
                            </div>
                        </div>
                        <ChartComponent title={'H- og F-Verdier'} tooltip={{enable: true}}>
                            <SeriesCollectionDirective>
                                <SeriesDirective dataSource={filtered} xName={'dateLabel'} yName={'hValue'}
                                                 name={'H-Verdi'} type={'Spline'}/>
                                <SeriesDirective dataSource={filtered} xName={'dateLabel'} yName={'hYear'}
                                                 name={'H-Verdi snitt år'} type={'Spline'}/>
                                <SeriesDirective dataSource={filtered} xName={'dateLabel'} yName={'hAccumulated'}
                                                 name={'H-Verdi akkumulert'} type={'Spline'}/>
                                <SeriesDirective dataSource={filtered} xName={'dateLabel'} yName={'fValue'}
                                                 name={'F-Verdi'} type={'Spline'}/>
                                <SeriesDirective dataSource={filtered} xName={'dateLabel'} yName={'fYear'}
                                                 name={'F-Verdi snitt år'} type={'Spline'}/>
                                <SeriesDirective dataSource={filtered} xName={'dateLabel'} yName={'fAccumulated'}
                                                 name={'F-Verdi akkumulert'} type={'Spline'}/>
                            </SeriesCollectionDirective>
                            <ChartInject services={[SplineSeries, Category, Legend, Tooltip]}/>
                        </ChartComponent>
                    </div>

                    <div className={'container-fluid mt-3'}>
                        <GridComponent dataSource={filtered} ref={g => this.grid = g} allowExcelExport={true}
                                       allowSorting={true} allowFiltering={true} toolbarClick={this.toolbarClick}
                                       filterSettings={{type: 'Menu'}} toolbar={['ExcelExport']}>
                            <ColumnsDirective>
                                <ColumnDirective field={'year'} headerText={'År'} width={'4rem'}/>
                                <ColumnDirective field={'month'} headerText={'Måned'} width={'4rem'}/>
                                <ColumnDirective field={'hours'} headerText={'Timer'} width={'4rem'}/>
                                <ColumnDirective field={'injuriesWithAbsence'} headerText={'Skader ført til fravær'}
                                                 width={'4rem'}/>
                                <ColumnDirective field={'injuries'} headerText={'Antall skader uten fravær'}
                                                 width={'4rem'}/>
                                <ColumnDirective field={'injuryDays'} headerText={'Antall skadedager'} width={'4rem'}/>
                                <ColumnDirective field={'hValue'} headerText={'H-verdi'} width={'4rem'}/>
                                <ColumnDirective field={'hYear'} headerText={'H-verdi snitt år'} width={'4rem'}/>
                                <ColumnDirective field={'hAccumulated'} headerText={'H-verdi akkumulert'}
                                                 width={'4rem'}/>
                                <ColumnDirective field={'fValue'} headerText={'F-verdi'} width={'4rem'}/>
                                <ColumnDirective field={'fYear'} headerText={'F-verdi snitt år'} width={'4rem'}/>
                                <ColumnDirective field={'fAccumulated'} headerText={'F-verdi akkumulert'}
                                                 width={'4rem'}/>
                                <ColumnDirective field={'nearInjuries'} headerText={'Tilløp til skade'} width={'4rem'}/>
                            </ColumnsDirective>
                            <Inject services={[Sort, Aggregate, Filter, ExcelExport, Toolbar]}/>
                        </GridComponent>
                    </div>
                </Authorized>
            </section>
        );
    }

    /** */
    private toolbarClick(args: ToolbarClickEventArgs): void {
        if (args.item.text === 'Excel Export') {
            this.grid?.excelExport({fileName: 'H- og F-Verdier.xlsx'});
        }
    }

    /** */
    private dateRangeChange(args: RangeEventArgs): void {
        const {range} = this.state;
        range.end = args.endDate;
        range.start = args.startDate;
        this.setState({range});
    }
}
