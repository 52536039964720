import type {ReactNode} from 'react';
import React from 'react';
import type {NavigateFunction} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

interface Props {
    step: 1 | 2 | 3;
    id: string | null;
    type: 'analysis' | 'protection';
}

interface State {

}

class Internal extends React.Component<Props & { navigate: NavigateFunction; }, State> {

    public constructor(props: Props & { navigate: NavigateFunction; }) {
        super(props);
        this.goTo = this.goTo.bind(this);
    }

    public override render(): ReactNode {
        const {step, id, type} = this.props;

        const pointer = id ? 'pointer' : '';

        return (
            <svg width={'100%'} height={'50px'}>
                {/* Lines */}
                <line x1={'25px'} x2={'25%'} y1={'25px'} y2={'25px'} strokeWidth={'0.4rem'}
                      style={{stroke: 'var(--bs-primary)'}}/>
                <line x1={'25%'} x2={'50%'} y1={'25px'} y2={'25px'} strokeWidth={'0.4rem'}
                      style={{stroke: step > 1 ? 'var(--bs-primary)' : 'var(--bs-gray-800)'}}/>
                <line x1={'50%'} x2={'75%'} y1={'25px'} y2={'25px'} strokeWidth={'0.4rem'}
                      style={{stroke: step > 1 ? 'var(--bs-primary)' : 'var(--bs-gray-800)'}}/>
                <line x1={'75%'} x2={'100%'} y1={'25px'} y2={'25px'} strokeWidth={'0.4rem'}
                      style={{
                          stroke: step > 2 ? 'var(--bs-primary)' : 'var(--bs-gray-800)',
                          transform: 'translateX(-25px)',
                      }}/>

                {/* Circles */}
                <circle cx={'25px'} cy={'25px'} r={'20px'} style={{fill: 'var(--bs-primary)'}}
                        className={'pointer'} onClick={() => this.goTo(`/${type}/edit`)}/>
                <circle cx={'50%'} cy={'25px'} r={'20px'}
                        style={{
                            stroke: 'var(--bs-primary)',
                            fill: step > 1 ? 'var(--bs-primary)' : 'var(--bs-gray-800)',
                        }} className={pointer} onClick={() => this.goTo(`/${type}/execute`)}/>
                <circle cx={'100%'} cy={'25px'} r={'20px'}
                        style={{
                            stroke: 'var(--bs-primary)',
                            fill: step > 2 ? 'var(--bs-primary)' : 'var(--bs-gray-800)',
                            transform: 'translateX(-25px)',
                        }} className={pointer} onClick={() => this.goTo(`/${type}/report`)}/>

                {/* Numbers */}
                <text x={'25px'} y={'25px'} textAnchor={'middle'} style={{stroke: 'var(--bs-light)'}}
                      strokeWidth={'2px'} dy={'0.3em'}
                      className={'pointer'} onClick={() => this.goTo(`/${type}/edit`)}>1
                </text>
                <text x={'50%'} y={'25px'} textAnchor={'middle'}
                      style={{stroke: step > 1 ? 'var(--bs-light)' : 'var(--bs-gray-600)'}}
                      strokeWidth={'2px'} dy={'0.3em'}
                      className={pointer} onClick={() => this.goTo(`/${type}/execute`)}>2
                </text>
                <text x={'100%'} y={'25px'} textAnchor={'middle'}
                      style={{
                          stroke: step > 2 ? 'var(--bs-light)' : 'var(--bs-gray-600)',
                          transform: 'translateX(-25px)',
                      }} strokeWidth={'2px'} dy={'0.3em'}
                      className={pointer} onClick={() => this.goTo(`/${type}/report`)}>3
                </text>
            </svg>
        );
    }

    private goTo(url: string): void {
        if (this.props.id) {
            const props = new URLSearchParams({id: this.props.id.toString()});
            this.props.navigate(`${url}?${props}`);
        }
    }
}

export function TaskProgress(props: Props): JSX.Element {
    const navigate = useNavigate();
    return (<Internal step={props.step} id={props.id} navigate={navigate} type={props.type}/>);
}
