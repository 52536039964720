import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactNode} from 'react';
import type {Department} from '../entities/Department';
import {ReportService} from '../services/ReportService';

interface Props {
    id: number | null | undefined;
    onChange: (value: Department | null) => void;
    readOnly?: boolean;
}

interface State {
    departments: Department[];
}

export class DepartmentSelect extends React.Component<Props, State> {

    private fields: FieldSettingsModel = {value: 'id', text: 'text'};

    public constructor(props: Props) {
        super(props);
        this.state = {departments: []};
        this.change = this.change.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const departments = await ReportService.getDepartments();
        this.setState({departments});
    }

    public override render(): ReactNode {
        const {departments} = this.state;
        const {id, readOnly} = this.props;

        if (departments.length) {
            return <ComboBoxComponent value={id ?? undefined} readonly={readOnly}
                                      placeholder={'Uspesifisert'}
                                      dataSource={departments as any}
                                      fields={this.fields}
                                      filterType={'Contains'}
                                      allowFiltering={true}
                                      ignoreCase={true}
                                      allowCustom={false}
                                      onChange={this.change}/>;
        }

        return <p className={'bg-dark rounded ps-3 pe-3 pt-1 pb-1'}>Loading...</p>;
    }

    /** */
    private change(e: any) {
        const {onChange} = this.props;
        const {departments} = this.state;
        const department = departments.find(department => department.id === e.value);
        onChange(department ?? null);
    }
}
