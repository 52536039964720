import {Acl, Authorized, TemplateHelper} from '@hapro/template-react';
import type {RecordDoubleClickEventArgs} from '@syncfusion/ej2-grids';
import {
    ColumnDirective,
    ColumnsDirective,
    ContextMenu,
    ContextMenuClickEventArgs,
    ContextMenuItemModel,
    Filter,
    GridComponent,
    Group,
    Inject,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import type {ClickEventArgs} from '@syncfusion/ej2-react-navigations';
import type {ReactNode} from 'react';
import React from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {fieldAccessor} from '../../bases/FieldBase';
import type {Protection as ProtectionModel} from '../../entities/Protection';
import {DeviationFormatter} from '../../helpers/TaskHelper';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    navigate: NavigateFunction;
}

interface State {
    protections: ProtectionModel[];
    loading: boolean;
}

class Internal extends React.Component<Props, State> {

    private static menuItems: ContextMenuItemModel[] = [
        {text: 'Rediger', iconCss: 'fa-solid fa-edit'},
        {text: 'Gå vernerunde', iconCss: 'fa-solid fa-person-military-pointing'},
        {text: 'Rapport', iconCss: 'fa-solid fa-file-lines'},
    ];

    // noinspection DuplicatedCode
    public constructor(props: Props) {
        super(props);
        this.state = {protections: [], loading: true};
        this.toolbarClick = this.toolbarClick.bind(this);
        this.doubleClick = this.doubleClick.bind(this);
        this.contextMenuClick = this.contextMenuClick.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const protections = await ReportService.getProtections();
        this.setState({protections, loading: false});
    }

    public override render(): ReactNode {
        const {protections, loading} = this.state;

        return (
            <section className={'mt-5'}>
                <div className={'container'}>
                    <h1>Vernerunder</h1>
                    <hr/>
                </div>
                <div className={'container-fluid'}>
                    <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                        <GridComponent dataSource={protections as any} allowGrouping={true} allowFiltering={true}
                                       filterSettings={{type: 'Menu'}} allowSorting={true}
                                       searchSettings={{
                                           fields: [
                                               'createdDisplayName',
                                               'departmentDisplayName',
                                               'title',
                                               'status',
                                           ],
                                       }}
                                       toolbar={['Search', 'Add']}
                                       editSettings={{allowAdding: true, allowDeleting: true}}
                                       toolbarClick={this.toolbarClick} recordDoubleClick={this.doubleClick}
                                       contextMenuItems={Internal.menuItems} contextMenuClick={this.contextMenuClick}
                                       sortSettings={{columns: [{field: 'date', direction: 'Descending'}]}}>
                            <ColumnsDirective>
                                <ColumnDirective field={'date'} headerText={'Dato'} type={'Date'}
                                                 format={TemplateHelper.date} width={'7rem'} allowGrouping={false}/>
                                <ColumnDirective field={'title'} headerText={'Tittel'} width={'9rem'}
                                                 allowGrouping={false}/>
                                <ColumnDirective field={'createdDisplayName'} headerText={'Registrert av'}
                                                 width={'9rem'}/>
                                <ColumnDirective field={'department.text'} headerText={'Avdeling'} width={'7rem'}
                                                 valueAccessor={fieldAccessor}/>
                                <ColumnDirective field={'location.text'} headerText={'Lokasjon'} width={'7rem'}
                                                 valueAccessor={fieldAccessor}/>
                                <ColumnDirective field={'status'} headerText={'Status'} width={'7rem'}/>
                                <ColumnDirective field={'open'} headerText={'Åpne avvik'} width={'8rem'}
                                                 formatter={DeviationFormatter} disableHtmlEncode={false}/>
                                <ColumnDirective field={'total'} headerText={'Antall avvik'} width={'8rem'}
                                                 formatter={DeviationFormatter} disableHtmlEncode={false}/>
                            </ColumnsDirective>
                            <Inject services={[Sort, Group, Filter, Toolbar, ContextMenu]}/>
                        </GridComponent>
                    </Authorized>
                </div>
            </section>
        );
    }

    /** */
    private contextMenuClick(args: ContextMenuClickEventArgs): void {
        if (!args.rowInfo?.rowData) {
            return;
        }
        const id = (args.rowInfo.rowData as ProtectionModel).id;
        switch (args.item.text) {
            case 'Rediger':
                this.props.navigate(`/protection/edit?id=${id}`);
                break;
            case 'Gå vernerunde':
                this.props.navigate(`/protection/execute?id=${id}`);
                break;
            case 'Rapport':
                this.props.navigate(`/protection/report?id=${id}`);
                break;
        }
    }

    /** */
    private doubleClick(args: RecordDoubleClickEventArgs): void {
        const protection = args.rowData as ProtectionModel;
        const param = new URLSearchParams({id: protection.id.toString()});
        this.props.navigate(`/protection/execute?${param}`);
    }

    /** */
    private toolbarClick(args: ClickEventArgs): void {
        if (args.item.text === 'Add') {
            this.props.navigate('/protection/edit');
        }
    }
}

/** */
export function Protection() {
    const navigate = useNavigate();
    return (<Internal navigate={navigate}/>);
}
