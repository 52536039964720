import type {Activity} from './Activity';
import type {AnalysisExternalUser} from './AnalysisExternalUser';
import type {AnalysisParticipant} from './AnalysisParticipant';
import type {Department} from './Department';
import type {Location} from './Location';
import type {SubLocation} from './SubLocation';

/** One of three main systems of HMS */
export interface Analysis {
    id: number;
    title: string;
    project: string;
    description: string;
    evaluation: string;
    date: Date;
    responsible: number;
    responsibleDisplayName: string;
    responsibleAnr: number | null;
    responsibleCell: number | null;
    responsibleApproved: boolean;
    created: Date;
    createdBy: number;
    createdDisplayName: string;
    createdAnr: number | null;
    createdCell: number | null;
    createdApproved: boolean;
    department: Department | null;
    location: Location | null;
    subLocation: SubLocation | null;
    participants: AnalysisParticipant[];
    externalUsers: AnalysisExternalUser[];
    activities: Activity[];
}

/** */
export function defaultAnalysis(): Analysis {
    return {
        id: 0,
        created: new Date(),
        title: '',
        project: '',
        evaluation: '',
        date: new Date(),
        responsible: 0,
        responsibleDisplayName: '',
        responsibleApproved: false,
        responsibleAnr: null,
        responsibleCell: null,
        createdCell: null,
        createdAnr: null,
        createdApproved: false,
        description: '',
        createdBy: 0,
        createdDisplayName: '',
        department: null,
        location: null,
        subLocation: null,
        participants: [],
        externalUsers: [],
        activities: [],
    };
}
