import {Acl, AclGate, Modal, ProxyFile, SpinnerHelper, TemplateHelper} from '@hapro/template-react';
import React, {ChangeEvent, ReactNode} from 'react';
import {aclReport} from '../services/aclStores';
import styles from '../style/Pictures.module.scss';

interface Props {
    proxyFiles: ProxyFile[];
    updateFiles?: (proxyFiles: ProxyFile[]) => void;
}

interface State {
    viewed: ProxyFile | null;
}

export class Pictures extends React.Component<Props, State> {

    /** */
    private modal: Modal | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {viewed: null};
        this.handlePictureAdd = this.handlePictureAdd.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.removePicture = this.removePicture.bind(this);
    }

    public override render(): ReactNode {
        const {viewed} = this.state;
        let {proxyFiles} = this.props;

        proxyFiles = proxyFiles.filter(p => p.url);

        return (
            <>
                {proxyFiles.length ?
                    /* current files */
                    <div className={'p-3 rounded bg-dark bg-opacity-25'}>
                        <div className={'d-flex gap-3 flex-wrap justify-content-center'}>
                            {proxyFiles.map((file, i) =>
                                <div className={`col-md-3 col-5 shadow rounded ${styles.animateIn}`} key={i}
                                     onClick={() => this.handleClick(file)}>
                                    <img className={'w-100 rounded'} src={file.url} alt={file.filename}/>
                                </div>,
                            )}
                        </div>
                    </div>
                    :
                    <p className={'text-muted'}>Ingen bilder...</p>
                }

                {/* form - currently only allows jpeg, png and gif */}
                <div className={'input-group mt-3'}>
                    <input accept={TemplateHelper.pictures.join(', ')} className={'form-control'} multiple
                           onChange={this.handlePictureAdd} type={'file'}/>
                    <span className={'input-group-text'}><i className={'fas fa-image'}/></span>
                </div>

                {/* Viewer */}
                <Modal ref={m => this.modal = m} animation={true} size={'xl'} centered={true}>
                    <div className={'modal-header'}>
                        <h5 className={'modal-title'}>{viewed?.filename}</h5>
                        <button className={'btn-close'} onClick={() => this.modal?.hide()}/>
                    </div>
                    <div className={'modal-body'}>
                        {viewed?.filename ?
                            <img alt={viewed.filename} className={'w-100 rounded'} src={viewed.url}/>
                            : null}
                    </div>
                    <AclGate aclStore={aclReport} level={Acl.create}>
                        <div className={'modal-footer'}>
                            <button className={'btn btn-danger btn-sm'} onClick={this.removePicture}>
                                <i className={'fas fa-trash'}/>
                            </button>
                        </div>
                    </AclGate>
                </Modal>
            </>
        );
    }

    /** */
    private removePicture() {
        const file = this.state.viewed;
        if (file?.url) {
            const proxyFiles = this.props.proxyFiles.filter(p => p.url && p.url !== file.url);
            URL.revokeObjectURL(file.url);
            if (this.props.updateFiles) {
                this.props.updateFiles(proxyFiles);
            }
        }
        this.modal?.hide();
    }

    /** */
    private handleClick(file: ProxyFile) {
        this.setState({viewed: file});
        this.modal?.show();
    }

    /** */
    private async handlePictureAdd(e: ChangeEvent) {
        SpinnerHelper.show();
        const input: HTMLInputElement = e.target as HTMLInputElement;
        await this.oneByOneUpload(input);

    }

    /** add user selected files to ReceptionNew.Files */
    private async oneByOneUpload(input?: HTMLInputElement, index: number = 0): Promise<void> {
        const file = input?.files?.item(index);

        // Complete when this is true
        if (!file || typeof file !== 'object') {
            SpinnerHelper.hide();
            return;
        }

        const proxyFile: ProxyFile = {
            bytes: 0,
            created: new Date(),
            filename: file.name,
            lastEdit: new Date(),
            mimeType: file.type,
            file,
            url: '',
        };

        if (TemplateHelper.isValidPicture(proxyFile)) {
            this.addToNew(proxyFile);
        }

        input!.value = '';
        setTimeout(() => this.oneByOneUpload(input, ++index), 100);
    }

    /** If we are creating a new report, add files to the report object */
    private addToNew(file: ProxyFile): void {
        file.url = URL.createObjectURL(file.file!);
        const proxyFiles = this.props.proxyFiles.concat(file);
        if (this.props.updateFiles) {
            this.props.updateFiles(proxyFiles);
        }
    }
}
