import {Acl, FileForm, SpinnerHelper, StdFormat, TemplateHelper, userStore} from '@hapro/template-react';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import React, {useState} from 'react';
import type {Measure} from '../entities/Measure';
import {defaultMeasure} from '../entities/Measure';
import type {Report} from '../entities/Report';
import {DateHelper} from '../helpers/DateHelper';
import {ReportService} from '../services/ReportService';
import {ReportManager} from '../services/ReportStore';
import {ResponsibleSelect} from './ResponsibleSelect';

/** */
interface Props {
    report: Report | null;
    measureId: number | null;
    acl: Acl;
    onChange: (report: Report) => void;
}

/** */
const STATUSES: { text: string; iconCss: string }[] = [
    {text: 'Ikke startet', iconCss: 'fa-solid fa-minus text-warning'},
    {text: 'Startet', iconCss: 'fa-solid fa-play text-info'},
    {text: 'Utsatt', iconCss: 'fa-solid fa-pause text-muted'},
    {text: 'Utført', iconCss: 'fa-solid fa-check text-success'},
];

/** */
export function statusTemplate(object: { status: string }): JSX.Element {
    const status = STATUSES.find(s => s.text === object.status);
    return (<span><i className={status?.iconCss}></i> {status?.text}</span>);
}

/** */
function alert(measure: Measure): JSX.Element {
    const verbosity = DateHelper.deadlineVerbosity(measure.effectiveDeadline, !!measure.performed);
    let alert: string;
    let past: boolean;
    switch (verbosity) {
        case 'info':
            alert = 'alert-secondary';
            past = false;
            break;
        case 'success':
            alert = 'alert-success';
            past = true;
            break;
        case 'warning':
            alert = 'alert-warning';
            past = false;
            break;
        case 'error':
            alert = 'alert-danger';
            past = false;
            break;
    }

    let delayNotice = '';
    if (measure.delay) {
        delayNotice = `, men har blitt utsatt til ${TemplateHelper.toStdDate(measure.delay, StdFormat.date)}`;
    }

    const date = TemplateHelper.toStdDate(measure.effectiveDeadline, StdFormat.date);
    return (
        <div className={`alert ${alert} mt-5`}>
            Fristen for tiltaket {past || measure.delay ? 'var' : 'er'} {date + delayNotice}
        </div>
    );
}

/** */
export function MeasureForm(props: Props): JSX.Element | null {
    const {report, measureId, acl, onChange} = props;

    const [newMeasure, setMeasure] = useState<Measure>(defaultMeasure());

    const measure: Measure | undefined = report?.measures.find(m => m.id === measureId);
    if (!measure) {
        return null;
    }

    if (measure.id !== newMeasure?.id) {
        setMeasure(measure);
    }

    async function save() {
        SpinnerHelper.show();
        const success = await ReportService.patchMeasure(newMeasure);
        if (success && report) {
            const measureIndex = report.measures.findIndex(m => m.id === newMeasure.id);
            if (measureIndex !== -1) {
                report.measures[measureIndex] = newMeasure;
                onChange(report);
                if (newMeasure.status === 'Utført') {
                    await ReportManager.updateReport(report.id);
                }
            }
        }
        SpinnerHelper.hide();
    }

    const canManage = (acl >= Acl.create || userStore.value?.userMinimal?.id === report?.createdBy)
        && !measure?.performed;

    return (<>
        {alert(newMeasure)}

        <div className={'row'}>

            <div className={'col-lg-6 col-sm-12'}>

                <div className={'card mt-3'}>
                    <div className={'card-header'}>
                        <h5 className={'card-title'}>Grunnopplysninger om tiltak #{measureId}</h5>
                    </div>
                    <div className={'card-body'}>
                        <p className={'form-label'}>Tittel</p>
                        <TextBoxComponent value={newMeasure.title} readonly={!canManage}
                                          change={e => setMeasure(prevState => {
                                              return {...prevState, title: e.value ?? ''};
                                          })}/>

                        <p className={'form-label mt-3'}>Registrert av</p>
                        <div className={'form-control'}>{newMeasure.createdDisplayName}</div>

                        <p className={'form-label mt-3'}>Frist</p>
                        <DatePickerComponent value={newMeasure.deadline} firstDayOfWeek={1} format={TemplateHelper.date}
                                             strictMode={true} readonly={!canManage}
                                             change={e => setMeasure(prevState => {
                                                 return {...prevState, deadline: e.value ?? prevState.deadline};
                                             })}/>

                        <p className={'form-label mt-3'}>Ansvarlig for utførelse</p>
                        {canManage ?
                            <ResponsibleSelect value={newMeasure.responsible}
                                               onChange={value => setMeasure(prevState => {
                                                   return {...prevState, responsible: value?.id ?? 0};
                                               })}/>
                            :
                            <div className={'form-control'}>{newMeasure.responsibleDisplayName}</div>
                        }

                        <p className={'form-label mt-3'}>Beskrivelse</p>
                        <TextBoxComponent multiline={true} value={newMeasure.description} readonly={!canManage}
                                          change={e => setMeasure(prevState => {
                                              return {...prevState, description: e.value ?? ''};
                                          })}/>
                    </div>
                    {canManage ?
                        <div className={'card-footer d-flex justify-content-end'}>
                            <button className={'btn btn-success'} onClick={save}>
                                <i className={'fa-solid fa-save me-2'}/>Lagre
                            </button>
                        </div>
                        : null}
                </div>

                <div className={'card mt-3'}>
                    <div className={'card-header'}>
                        <h5 className={'card-title'}>Filer</h5>
                    </div>
                    <div className={'card-body'}>
                        <FileForm files={newMeasure.files} noRemove={!canManage} noUpload={!canManage}
                                  download={`/V1/Report/MeasureFile/${report?.id}/${measureId}`}
                                  upload={`/V1/Report/MeasureFile/${report?.id}/${measureId}`}
                                  remove={`/V1/Report/MeasureFile/${report?.id}/${measureId}`}/>
                    </div>
                </div>

            </div>

            <div className={'col-lg-6 col-sm-12'}>

                <div className={'card mt-3'}>
                    <div className={'card-header'}>
                        <h5 className={'card-title'}>Bekreftelse på utførelse</h5>
                    </div>
                    <div className={'card-body'}>
                        <p className={'form-label'}>Status</p>
                        <DropDownListComponent dataSource={STATUSES} fields={{iconCss: 'iconCss', text: 'text'}}
                                               value={newMeasure.status} readonly={!canManage}
                                               change={e => setMeasure(prev => ({...prev, status: e.value}))}/>

                        <p className={'form-label mt-3'}>Kommentar</p>
                        <TextBoxComponent value={newMeasure.comment ?? ''} multiline={true} readonly={!canManage}
                                          change={e => setMeasure(prev => ({...prev, comment: e.value ?? ''}))}/>

                        {newMeasure.status === 'Utført' ? <>
                            <p className={'form-label mt-3'}>Utført dato</p>
                            <DatePickerComponent firstDayOfWeek={1} format={TemplateHelper.date}
                                                 strictMode={true} readonly={!canManage}
                                                 placeholder={'I dag'} value={newMeasure.performed ?? undefined}
                                                 change={e => setMeasure(prev => {
                                                     return {...prev, performed: e.value ?? new Date()};
                                                 })}/>
                        </> : newMeasure.status === 'Utsatt' ? <>
                            <p className={'form-label mt-3'}>Utsatt til</p>
                            <DatePickerComponent firstDayOfWeek={1} format={TemplateHelper.date}
                                                 strictMode={true} readonly={!canManage}
                                                 placeholder={'I dag'} value={newMeasure.delay ?? undefined}
                                                 change={e => setMeasure(prev => {
                                                     return {...prev, delay: e.value ?? new Date()};
                                                 })}/>
                        </> : null}

                        {newMeasure.performedBy ? <>
                            <p className={'form-label mt-3'}>Utført av</p>
                            <div className={'form-control'}>{newMeasure.performedDisplayName}</div>
                        </> : null}
                    </div>
                    {canManage ?
                        <div className={'card-footer d-flex justify-content-end'}>
                            <button className={'btn btn-success'} onClick={save}>
                                <i className={'fa-solid fa-save me-2'}/>Lagre
                            </button>
                        </div>
                        : null}
                </div>

            </div>

        </div>
    </>);
}
