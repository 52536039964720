import {AclStore} from '@hapro/template-react';

/** Access level of user */
export const aclReport = new AclStore('report');

/** Access level of user */
export const aclLog = new AclStore('log');

/** Access level of user */
export const aclAdmin = new AclStore('admin');
