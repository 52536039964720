import {Acl, AclGate, SpinnerHelper, userStore} from '@hapro/template-react';
import {
    HtmlEditor,
    Inject,
    Link,
    QuickToolbar,
    RichTextEditorComponent,
    Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import type {Report} from '../entities/Report';
import {MiscHelper} from '../helpers/MiscHelper';
import {aclAdmin, aclReport} from '../services/aclStores';
import {ReportService} from '../services/ReportService';
import {ReportManager} from '../services/ReportStore';
import {CommentCard} from './CommentCard';

interface Props {
    report: Report;
    closed: boolean;
}

interface State {
    text: string;
    userId: number;
}

export class Comments extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {text: '', userId: userStore.value?.userMinimal?.id ?? 0};
        this.addComment = this.addComment.bind(this);
        this.updateText = this.updateText.bind(this);
    }

    /** @inheritDoc */
    public override componentDidMount(): void {
        userStore.subscribe(value => {
            this.setState({userId: value?.userMinimal?.id ?? 0});
        });
    }

    public override render(): ReactNode {
        const {text, userId} = this.state;
        const {report, closed} = this.props;

        const isResponsibleOrCreator = report.responsible === userId || report.createdBy === userId;
        const closeButton = (
            <button className={'btn btn-sm btn-primary mb-1'} onClick={() => this.addComment(true)}>
                <i className={'fa-light fa-ban me-2'}/>Tiltak utført <em>(saken lukkes)</em>
            </button>
        );

        return (
            <>
                <div className={'d-flex flex-column gap-3'}>
                    {report.comments.map((comment, i) =>
                        <CommentCard comment={comment} userId={userId} report={report} key={i}/>,
                    )}
                </div>

                {report.comments.length ? null : <p className={'text-muted'}>Ingen Kommentarer/tiltak...</p>}

                {/* New comment */}
                <AclGate aclStore={aclReport} level={Acl.create}>
                    <hr/>
                    <RichTextEditorComponent value={text} height={300} change={this.updateText}
                                             toolbarSettings={MiscHelper.rteSettings}>
                        <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]}/>
                    </RichTextEditorComponent>
                    <div className={'d-flex justify-content-end gap-3 mt-3'}>
                        <>
                            {closed ? null :
                                <button className={'btn btn-sm btn-success mb-1'}
                                        onClick={() => this.addComment(false)}>
                                    Kommentér
                                </button>
                            }
                            {report.closedCommentId ?
                                // If closed, everyone can reopen
                                <button className={'btn btn-sm btn-warning mb-1'}
                                        onClick={() => this.addComment(true)}>
                                    <i className={'fa-solid fa-recycle me-2'}/>Gjenåpne
                                </button>
                                : // All measures must be completed to close
                                report.measures.every(m => m.performed)
                                    ? (isResponsibleOrCreator ? // Responsible and creator can close
                                        closeButton
                                        : // Else check for high access
                                        <AclGate aclStore={aclAdmin} level={Acl.erase}>
                                            {closeButton}
                                        </AclGate>)
                                    : null
                            }
                        </>
                    </div>
                </AclGate>
            </>
        );
    }

    /** */
    private updateText(e: any) {
        this.setState({text: e.value});
    }

    /** */
    private async addComment(toggle: boolean): Promise<boolean> {
        const text = this.state.text && MiscHelper.stripHtml(this.state.text) ? this.state.text : "<p>&lt;Ingen kommentar&gt;</p>";

        SpinnerHelper.show();
        const success = await ReportService.postComment(this.props.report.id, text, toggle ?? false);
        if (success) {
            await ReportManager.updateReport(this.props.report.id);
            this.setState({text: ''});
        }

        SpinnerHelper.hide();
        return success;
    }
}
