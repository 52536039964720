import type {DateRange} from '@syncfusion/ej2-calendars';
import type {ReportCategory} from '../enums/ReportCategory';
import {DateHelper} from '../helpers/DateHelper';

/** */
export interface LogFilters {
    dateRange: DateRange;
    locationIds: number[];
    subLocationIds: number[];
    categories: ReportCategory[];
    departmentIds: number[];
    status: number;
}

/** Default object */
export const defaultLogFilters: () => LogFilters = () => ({
    dateRange: DateHelper.oneMonth(),
    locationIds: [],
    subLocationIds: [],
    categories: [],
    departmentIds: [],
    status: 0,
});
