import {Layout, LogoutRedirect, NotFound, SigninCallback} from '@hapro/template-react';
import '@hapro/template-react/dist/index.css';
import React, {ReactNode} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {Admin} from './pages/Admin';
import {Analysis} from './pages/analysis/Analysis';
import {Edit as AnalysisEdit} from './pages/analysis/Edit';
import {Execute as AnalysisExecute} from './pages/analysis/Execute';
import {Report as AnalysisReport} from './pages/analysis/Report';
import {Home} from './pages/Home';
import {Improvements} from './pages/Improvements';
import {My} from './pages/My';
import {Edit as ProtectionEdit} from './pages/protection/Edit';
import {Execute as ProtectionExecute} from './pages/protection/Execute';
import {Protection} from './pages/protection/Protection';
import {Report as ProtectionReport} from './pages/protection/Report';
import {New} from './pages/report/New';
import {ReportPage} from './pages/report/ReportPage';
import {ReportLog} from './pages/ReportLog';
import {Stats} from './pages/Stats';

export class App extends React.Component<any, any> {

    public constructor(props: any) {
        super(props);
        this.state = {error: null, errorInfo: null};
    }

    /** @inheritDoc */
    public override componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        this.setState({error, errorInfo});
    }

    public override render(): ReactNode {
        const {error, errorInfo} = this.state;
        if (error) {
            return (
                <div className={'container'}>
                    <div className={'text-center'}>
                        <h1>Unrecoverable Error</h1>
                        <button className={'btn btn-secondary'} onClick={() => window.location.reload()}>
                            <i className={'fa-solid fa-recycle me-2'}/>Refresh
                        </button>
                    </div>
                    <h1>{error.name}</h1>
                    <h5 className={'text-danger'}>{error.message}</h5>
                    <div className={'p-3 bg-black rounded'}>
                        <code>{errorInfo?.componentStack}</code>
                    </div>
                    <br/>
                    <div className={'p-3 bg-black rounded'}>
                        <code className={'text-muted'}>{error.stack}</code>
                    </div>
                </div>
            );
        }
        return (
            <BrowserRouter>
                <Routes>
                    <Route path={'/'} element={<Layout/>}>
                        <Route index element={<Home/>}/>
                        <Route path={'/report'}>
                            <Route index element={<New/>}/>
                            <Route path={'/report/:reportId'} element={<ReportPage/>}/>
                        </Route>
                        <Route path={'/admin'} element={<Admin/>}/>
                        <Route path={'/my'} element={<My/>}/>
                        <Route path={'/report-log'} element={<ReportLog/>}/>
                        <Route path={'/stats'} element={<Stats/>}/>
                        <Route path={'/protection'}>
                            <Route index element={<Protection/>}/>
                            <Route path={'/protection/edit'} element={<ProtectionEdit/>}/>
                            <Route path={'/protection/execute'} element={<ProtectionExecute/>}/>
                            <Route path={'/protection/report'} element={<ProtectionReport/>}/>
                        </Route>
                        <Route path={'/analysis'}>
                            <Route index element={<Analysis/>}/>
                            <Route path={'/analysis/edit'} element={<AnalysisEdit/>}/>
                            <Route path={'/analysis/execute'} element={<AnalysisExecute/>}/>
                            <Route path={'/analysis/report'} element={<AnalysisReport/>}/>
                        </Route>
                        <Route path={'/improvements'} element={<Improvements/>}/>

                        {/* Template routes */}
                        <Route path={'/signin-callback'} element={<SigninCallback/>}/>
                        <Route path={'/logout-redirect'} element={<LogoutRedirect/>}/>
                        <Route path={'*'} element={<NotFound/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}
