import type {ExternalUserBase} from '../bases/ExternalUserBase';

/** */
export interface AnalysisExternalUser extends ExternalUserBase {
    analysisId: number;
}

/** */
export function newAnalysisExternalUser(): AnalysisExternalUser {
    return {email: null, name: '', organization: null, phone: null, id: 0, analysisId: 0};
}
