import {Acl, Authorized, SpinnerHelper, TemplateHelper, userStore} from '@hapro/template-react';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ReactNode} from 'react';
import React from 'react';
import {Link, NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {DepartmentSelect} from '../../components/DepartmentSelect';
import {LocationSelect} from '../../components/LocationSelect';
import {SubLocationSelect} from '../../components/SubLocationSelect';
import {TaskParticipants} from '../../components/TaskParticipants';
import {TaskProgress} from '../../components/TaskProgress';
import {TemplateModal} from '../../components/TemplateModal';
import {defaultBulletPoint} from '../../entities/BulletPoint';
import {defaultBulletPointGroup} from '../../entities/BulletPointGroup';
import type {BulletPointTemplate} from '../../entities/BulletPointTemplate';
import type {Department} from '../../entities/Department';
import type {Location} from '../../entities/Location';
import {newProtectionExternalUser} from '../../entities/ProtectionExternalUser';
import type {SubLocation} from '../../entities/SubLocation';
import {TaskHelper} from '../../helpers/TaskHelper';
import {defaultProtectionNew, ProtectionNew} from '../../models/ProtectionNew';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    params: URLSearchParams;
    navigate: NavigateFunction;
}

interface State {
    protectionNew: ProtectionNew;
}

class Internal extends React.Component<Props, State> {

    /** */
    private text: TextBoxComponent | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {protectionNew: defaultProtectionNew()};
        this.save = this.save.bind(this);
        this.addGroup = this.addGroup.bind(this);
        this.addPoint = this.addPoint.bind(this);
        this.moveGroup = this.moveGroup.bind(this);
        this.movePoint = this.movePoint.bind(this);
        this.deletePoint = this.deletePoint.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
        this.applyTemplate = this.applyTemplate.bind(this);
        this.locationChange = this.locationChange.bind(this);
        this.departmentChange = this.departmentChange.bind(this);
        this.subLocationChange = this.subLocationChange.bind(this);
    }

    /** @inheritDoc */
    public async componentDidMount(): Promise<void> {
        const id = Number(this.props.params.get('id'));
        if (!this.props.params.has('id') || isNaN(id)) {
            return;
        }
        SpinnerHelper.show();
        const protection = await ReportService.getProtection(id);
        const protectionNew: ProtectionNew = {
            createdBy: protection.createdBy,
            date: protection.date,
            bulletPointGroups: protection.bulletPointGroups,
            description: protection.description,
            departmentId: protection.department?.id ?? null,
            locationId: protection.location?.id ?? null,
            subLocationText: protection.subLocation?.text ?? '',
            participants: protection.participants.map(p => p.userId),
            externalUsers: protection.externalUsers,
            title: protection.title,
        };
        this.setState({protectionNew});
        SpinnerHelper.hide();
    }

    public override render(): ReactNode {
        const {protectionNew} = this.state;
        const {params} = this.props;

        const id = params.get('id');
        const canManage = !id || TaskHelper.canManageProtectionNew(protectionNew, userStore.value?.userMinimal?.id);

        return (
            <section className={'container mt-5'}>
                <h1>Vernerunde - Planlegg {id ? `#${id}` : 'ny'}</h1>
                <hr/>
                <Authorized aclStore={aclReport} acl={Acl.create}>
                    <TaskProgress step={1} id={id} type={'protection'}/>

                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Planlegg</h5>
                        </div>
                        <div className={'card-body'}>
                            <div className={'row'}>
                                <div className={'col-6 d-flex flex-column gap-3'}>
                                    <div>
                                        <p className={'form-label'}>Tittel</p>
                                        <TextBoxComponent value={protectionNew.title} readonly={!canManage}
                                                          change={e => protectionNew.title = e.value}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Dato</p>
                                        <DatePickerComponent firstDayOfWeek={1} strictMode={true} placeholder={'I dag'}
                                                             format={TemplateHelper.date} value={protectionNew.date}
                                                             readonly={!canManage}
                                                             change={e => protectionNew.date = e.value}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Avdeling</p>
                                        <DepartmentSelect id={protectionNew.departmentId} readOnly={!canManage}
                                                          onChange={this.departmentChange}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Lokasjon</p>
                                        <LocationSelect id={protectionNew.locationId} onChange={this.locationChange}
                                                        readOnly={!canManage}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Sted</p>
                                        <SubLocationSelect text={protectionNew.subLocationText} readOnly={!canManage}
                                                           onChange={this.subLocationChange}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Beskrivelse</p>
                                        <TextBoxComponent multiline={true} value={protectionNew.description}
                                                          readonly={!canManage}
                                                          change={e => protectionNew.description = e.value}/>
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    <TaskParticipants taskForm={protectionNew} readOnly={!canManage}
                                                      externalUserNew={newProtectionExternalUser}
                                                      changeExternal={e => protectionNew.externalUsers = e}
                                                      change={e => protectionNew.participants = e}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Planlegg sjekkpunkter</h5>
                        </div>
                        <div className={'card-body'}>
                            <div className={'bg-dark mt-3 p-3 rounded'}>
                                <div className={'d-flex flex-column gap-3'}>
                                    {protectionNew.bulletPointGroups.map((group, i) =>
                                        <div key={i}>
                                            <div className={'row'}>
                                                <div className={'col-8'}>
                                                    <TextBoxComponent value={group.label} readonly={!canManage}
                                                                      change={e => group.label = e.value}/>
                                                </div>
                                                <div className={'col-4 d-flex gap-3'}>
                                                    <button className={'btn btn-sm'} disabled={!canManage}
                                                            onClick={() => this.moveGroup(i, true)}>
                                                        <i className={'fa-solid fa-chevron-up'}/>
                                                    </button>
                                                    <button className={'btn btn-sm'} disabled={!canManage}
                                                            onClick={() => this.moveGroup(i, false)}>
                                                        <i className={'fa-solid fa-chevron-down'}/>
                                                    </button>
                                                    <button className={'ms-auto btn btn-sm'} disabled={!canManage}
                                                            onClick={() => this.deleteGroup(i)}>
                                                        <i className={'fa-solid fa-trash text-danger'}/>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className={'mt-3'}>
                                                <div className={'d-flex flex-column gap-3'}>
                                                    {group.bulletPoints.map((point, j) =>
                                                        <div className={'row'} key={j}>
                                                            <div className={'col-1'}/>
                                                            <div className={'col-7'}>
                                                                <TextBoxComponent value={point.label}
                                                                                  readonly={!canManage}
                                                                                  change={e => point.label = e.value}/>
                                                            </div>
                                                            <div className={'col-4 d-flex gap-3'}>
                                                                <button className={'btn btn-sm'} disabled={!canManage}
                                                                        onClick={() => this.movePoint(i, j, true)}>
                                                                    <i className={'fa-solid fa-chevron-circle-up'}/>
                                                                </button>
                                                                <button className={'btn btn-sm'} disabled={!canManage}
                                                                        onClick={() => this.movePoint(i, j, false)}>
                                                                    <i className={'fa-solid fa-chevron-circle-down'}/>
                                                                </button>
                                                                <button className={'ms-auto btn btn-sm'}
                                                                        disabled={!canManage}
                                                                        onClick={() => this.deletePoint(i, j)}>
                                                                    <i className={'fa-solid fa-trash-circle text-danger'}/>
                                                                </button>
                                                            </div>
                                                        </div>,
                                                    )}
                                                    <div className={'row'}>
                                                        <div className={'col-1'}/>
                                                        <div className={'col-7'}>
                                                            <button className={'btn btn-sm'} disabled={!canManage}
                                                                    onClick={() => this.addPoint(i)}>
                                                                <i className={'fa-solid fa-plus text-success me-2'}/>Legg
                                                                til sjekkpunkt
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>,
                                    )}
                                </div>
                                <div className={'d-flex justify-content-between mt-3'}>
                                    <button className={'btn btn-sm mt-3'} onClick={this.addGroup} disabled={!canManage}>
                                        <i className={'fa-solid fa-plus text-success me-2'}/>Legg til gruppe
                                    </button>

                                    <TemplateModal protection={protectionNew} onChange={this.applyTemplate}/>
                                </div>
                            </div>
                        </div>
                        <div className={'card-footer d-flex gap-3 justify-content-end'}>
                            {canManage ? <>
                                <button className={'btn btn-success'} onClick={() => this.save(false)}>
                                    <i className={'fa-solid fa-save me-2'}/>Lagre
                                </button>
                                <button className={'btn btn-info'} onClick={() => this.save(true)}>
                                    <i className={'fa-solid fa-fast-forward me-2'}/>Lagre og neste
                                </button>
                            </> : null}
                            <Link to={`/protection/execute?id=${id}`} className={'btn btn-primary'}>
                                <i className={'fa-solid fa-forward me-2'}/>Neste
                            </Link>
                        </div>
                    </div>
                </Authorized>
            </section>
        );
    }

    /** */
    private applyTemplate(template: BulletPointTemplate): void {
        const {protectionNew} = this.state;
        protectionNew.bulletPointGroups = template.bulletPointGroups;
        this.setState({protectionNew});
    }

    /** */
    private moveGroup(index: number, up: boolean): void {
        const {protectionNew} = this.state;
        let swapIndex = index;
        if (up && index > 0) {
            swapIndex--;
        } else if (!up && index < protectionNew.bulletPointGroups.length - 1) {
            swapIndex++;
        }
        const temp = protectionNew.bulletPointGroups[index];
        protectionNew.bulletPointGroups[index] = protectionNew.bulletPointGroups[swapIndex];
        protectionNew.bulletPointGroups[swapIndex] = temp;
        this.setState({protectionNew});
    }

    /** */
    private movePoint(groupIndex: number, index: number, up: boolean): void {
        const {protectionNew} = this.state;
        const group = protectionNew.bulletPointGroups[groupIndex];
        const point = group.bulletPoints[index];
        if (up && index > 0) {
            const temp = group.bulletPoints[index];
            group.bulletPoints[index] = group.bulletPoints[index - 1];
            group.bulletPoints[index - 1] = temp;
        } else if (!up && index < group.bulletPoints.length - 1) {
            const temp = group.bulletPoints[index];
            group.bulletPoints[index] = group.bulletPoints[index + 1];
            group.bulletPoints[index + 1] = temp;
        } else if (up && groupIndex > 0) {
            group.bulletPoints.splice(index, 1);
            protectionNew.bulletPointGroups[groupIndex - 1].bulletPoints.push(point);
        } else if (!up && groupIndex < protectionNew.bulletPointGroups.length - 1) {
            group.bulletPoints.splice(index, 1);
            protectionNew.bulletPointGroups[groupIndex + 1].bulletPoints.unshift(point);
        }
        this.setState({protectionNew});
    }

    /** */
    private addPoint(groupIndex: number): void {
        const {protectionNew} = this.state;
        const group = protectionNew.bulletPointGroups[groupIndex];
        if (group) {
            group.bulletPoints.push(defaultBulletPoint());
        }
        this.setState({protectionNew});
    }

    /** */
    private deletePoint(groupIndex: number, index: number): void {
        const {protectionNew} = this.state;
        const group = protectionNew.bulletPointGroups[groupIndex];
        if (group) {
            group.bulletPoints.splice(index, 1);
        }
        this.setState({protectionNew});
    }

    /** */
    private deleteGroup(index: number): void {
        const {protectionNew} = this.state;
        protectionNew.bulletPointGroups.splice(index, 1);
        this.setState({protectionNew});
    }

    /** */
    private addGroup(): void {
        const {protectionNew} = this.state;
        protectionNew.bulletPointGroups.push(defaultBulletPointGroup());
        this.setState({protectionNew}, () => {
            this.text?.focusIn();
        });
    }

    /** */
    private async save(goNext: boolean): Promise<void> {
        SpinnerHelper.block();
        const {protectionNew} = this.state;
        const {navigate, params} = this.props;

        protectionNew.bulletPointGroups.forEach((group, i) => group.order = i);
        for (let i = 0; i < protectionNew.bulletPointGroups.length; i++) {
            const group = protectionNew.bulletPointGroups[i];
            group.order = i;
            for (let j = 0; j < group.bulletPoints.length; j++) {
                const point = group.bulletPoints[j];
                point.order = j;
                point.report = null; // Will get 400 if we send this to db
            }
        }

        let id = Number(params.get('id'));
        if (id) {
            const success = await ReportService.patchProtection(id, protectionNew);
            if (success && goNext) {
                const query = new URLSearchParams({id: id.toString()});
                navigate(`/protection/execute?${query}`);
            }
        } else {
            id = await ReportService.postProtection(protectionNew);
            if (id) {
                const query = new URLSearchParams({id: id.toString()});
                navigate(`/protection/${goNext ? 'execute' : 'edit'}?${query}`);
            }
        }

        SpinnerHelper.unblock();
    }

    /** */
    private departmentChange(value: Department | null): void {
        const {protectionNew} = this.state;
        protectionNew.departmentId = value?.id ?? null;
        this.setState({protectionNew});

    }

    /** */
    private locationChange(value: Location | null): void {
        const {protectionNew} = this.state;
        protectionNew.locationId = value?.id ?? null;
        this.setState({protectionNew});
    }

    /** */
    private subLocationChange(value: SubLocation): void {
        const {protectionNew} = this.state;
        protectionNew.subLocationText = value.text;
        this.setState({protectionNew});
    }
}

/** */
export function Edit() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    return (<Internal params={params} navigate={navigate}/>);
}
