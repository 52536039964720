import {Acl, Authorized, SpinnerHelper, TemplateHelper, UserMinimal, userStore} from '@hapro/template-react';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ReactNode} from 'react';
import React from 'react';
import {Link, NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {DepartmentSelect} from '../../components/DepartmentSelect';
import {LocationSelect} from '../../components/LocationSelect';
import {ResponsibleSelect} from '../../components/ResponsibleSelect';
import {SubLocationSelect} from '../../components/SubLocationSelect';
import {TaskParticipants} from '../../components/TaskParticipants';
import {TaskProgress} from '../../components/TaskProgress';
import {newAnalysisExternalUser} from '../../entities/AnalysisExternalUser';
import type {Department} from '../../entities/Department';
import type {Location} from '../../entities/Location';
import type {SubLocation} from '../../entities/SubLocation';
import {TaskHelper} from '../../helpers/TaskHelper';
import {AnalysisNew, defaultAnalysisNew} from '../../models/AnalysisNew';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    params: URLSearchParams;
    navigate: NavigateFunction;
}

interface State {
    analysisNew: AnalysisNew;
}

class Internal extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {analysisNew: defaultAnalysisNew()};
        this.save = this.save.bind(this);
        this.locationChange = this.locationChange.bind(this);
        this.departmentChange = this.departmentChange.bind(this);
        this.responsibleChange = this.responsibleChange.bind(this);
    }

    /** @inheritDoc */
    public async componentDidMount(): Promise<void> {
        const id = Number(this.props.params.get('id'));
        if (!this.props.params.has('id') || isNaN(id)) {
            return;
        }
        SpinnerHelper.show();
        const analysis = await ReportService.getAnalysis(id);
        const analysisNew: AnalysisNew = {
            createdBy: analysis.createdBy,
            date: analysis.date,
            project: analysis.project,
            responsible: analysis.responsible,
            activities: analysis.activities,
            description: analysis.description,
            departmentId: analysis.department?.id ?? null,
            locationId: analysis.location?.id ?? null,
            subLocationText: analysis.subLocation?.text ?? '',
            participants: analysis.participants.map(a => a.userId),
            externalUsers: analysis.externalUsers,
            title: analysis.title,
        };
        this.setState({analysisNew});
        SpinnerHelper.hide();
    }

    public override render(): ReactNode {
        const {analysisNew} = this.state;
        const {params} = this.props;

        const id = params.get('id');
        const canManage = !id || TaskHelper.canManageAnalysisNew(analysisNew, userStore.value?.userMinimal?.id);

        return (
            <section className={'container mt-5'}>
                <h1>Sikker jobbanalyse - Planlegg {id ? `#${id}` : 'ny'}</h1>
                <hr/>
                <Authorized aclStore={aclReport} acl={Acl.create}>
                    <TaskProgress step={1} id={id} type={'analysis'}/>

                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Planlegg</h5>
                        </div>
                        <div className={'card-body'}>
                            <div className={'row'}>
                                <div className={'col-6 d-flex flex-column gap-3'}>
                                    <div>
                                        <p className={'form-label'}>Tittel</p>
                                        <TextBoxComponent value={analysisNew.title} readonly={!canManage}
                                                          change={e => analysisNew.title = e.value}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Dato</p>
                                        <DatePickerComponent firstDayOfWeek={1} strictMode={true} placeholder={'I dag'}
                                                             value={analysisNew.date} format={TemplateHelper.date}
                                                             readonly={!canManage}
                                                             change={e => analysisNew.date = e.value}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Prosjekt/referanse</p>
                                        <TextBoxComponent value={analysisNew.project} readonly={!canManage}
                                                          change={e => analysisNew.project = e.value}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Avdeling</p>
                                        <DepartmentSelect id={analysisNew.departmentId} readOnly={!canManage}
                                                          onChange={this.departmentChange}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Lokasjon</p>
                                        <LocationSelect id={analysisNew.locationId} readOnly={!canManage}
                                                        onChange={this.locationChange}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Sted</p>
                                        <SubLocationSelect text={analysisNew.subLocationText} readOnly={!canManage}
                                                           onChange={this.subLocationChange}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Beskrivelse</p>
                                        <TextBoxComponent multiline={true} value={analysisNew.description}
                                                          readonly={!canManage}
                                                          change={e => analysisNew.description = e.value}/>
                                    </div>
                                    <div>
                                        <p className={'form-label'}>Ansvarlig</p>
                                        <ResponsibleSelect value={analysisNew.responsible} placeholder={'Deg selv'}
                                                           readOnly={!canManage} onChange={this.responsibleChange}/>
                                    </div>
                                </div>
                                <div className={'col-6'}>
                                    {/* TODO: external users table does not update when adding. When used in protection/Edit.tsx it does. */}
                                    <TaskParticipants taskForm={analysisNew} externalUserNew={newAnalysisExternalUser}
                                                      changeExternal={e => analysisNew.externalUsers = e}
                                                      readOnly={!canManage} change={e => analysisNew.participants = e}/>
                                </div>
                            </div>
                        </div>
                        <div className={'card-footer d-flex gap-3 justify-content-end'}>
                            {canManage ? <>
                                <button className={'btn btn-success'} onClick={() => this.save(false)}>
                                    <i className={'fa-solid fa-save me-2'}/>Lagre
                                </button>
                                <button className={'btn btn-info'} onClick={() => this.save(true)}>
                                    <i className={'fa-solid fa-fast-forward me-2'}/>Lagre og neste
                                </button>
                            </> : null}
                            <Link to={`/analysis/execute?id=${id}`} className={'btn btn-primary'}>
                                <i className={'fa-solid fa-forward me-2'}/>Neste
                            </Link>
                        </div>
                    </div>
                </Authorized>
            </section>
        );
    }

    /** */
    private responsibleChange(user: UserMinimal | null): void {
        const {analysisNew} = this.state;
        analysisNew.responsible = user?.id ?? null;
        this.setState({analysisNew});
    }

    /** */
    private async save(goNext: boolean): Promise<void> {
        SpinnerHelper.block();
        const {analysisNew} = this.state;
        const {navigate, params} = this.props;

        let id = Number(params.get('id'));
        if (id) {
            const success = await ReportService.patchAnalysis(id, analysisNew);
            if (success && goNext) {
                const query = new URLSearchParams({id: id.toString()});
                navigate(`/analysis/execute?${query}`);
            }
        } else {
            id = await ReportService.postAnalysis(analysisNew);
            if (id) {
                const query = new URLSearchParams({id: id.toString()});
                navigate(`/analysis/${goNext ? 'execute' : 'edit'}?${query}`);
            }
        }

        SpinnerHelper.unblock();
    }

    /** */
    private departmentChange(value: Department | null): void {
        const {analysisNew} = this.state;
        analysisNew.departmentId = value?.id ?? null;
        this.setState({analysisNew});

    }

    /** */
    private locationChange(value: Location | null): void {
        const {analysisNew} = this.state;
        analysisNew.locationId = value?.id ?? null;
        this.setState({analysisNew});
    }

    /** */
    private subLocationChange(value: SubLocation): void {
        const {analysisNew} = this.state;
        analysisNew.subLocationText = value.text;
        this.setState({analysisNew});
    }
}

/** */
export function Edit() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    return (<Internal params={params} navigate={navigate}/>);
}
