import {Acl, Authorized, SpinnerHelper, TemplateHelper} from '@hapro/template-react';
import type {MultiSelectModel} from '@syncfusion/ej2-dropdowns';
import {DateRangePickerComponent, RangeEventArgs} from '@syncfusion/ej2-react-calendars';
import {
    AccumulationChartComponent,
    AccumulationDataLabel,
    AccumulationDataLabelSettingsModel,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    AccumulationTooltip,
    AxisModel,
    BarSeries,
    Category as SfCategory,
    ChartComponent,
    DataLabel,
    PieSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    Tooltip,
    TooltipSettingsModel,
} from '@syncfusion/ej2-react-charts';
import {CheckBoxSelection, DropDownListComponent, MultiSelectComponent} from '@syncfusion/ej2-react-dropdowns';
import {Inject} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {ReportTable} from '../components/ReportTable';
import type {Department} from '../entities/Department';
import type {Location} from '../entities/Location';
import type {Report} from '../entities/Report';
import type {SubLocation} from '../entities/SubLocation';
import {ReportHelper} from '../helpers/ReportHelper';
import type {LogFilters} from '../models/LogFilters';
import {aclLog} from '../services/aclStores';
import {LogService} from '../services/LogService';
import {ReportService} from '../services/ReportService';
import {filterStore} from '../services/stores';
import type {MenuItem} from '../types/MenuItem';

interface Props {
    navigate: NavigateFunction;
}

interface State {
    loading: boolean;
    filters: LogFilters;
    view: { bar: boolean; pie: boolean; grid: boolean };
    reports: Report[] | null;
    locations: Location[];
    subLocations: SubLocation[];
    departments: Department[];
}

class Internal extends React.Component<Props, State> {

    /** Pie chart */
    private pieModel: AccumulationDataLabelSettingsModel = {
        visible: true,
        position: 'Outside',
        connectorStyle: {length: '20px', type: 'Curve'},
        name: 'category',
        font: {
            color: 'white',
        },
    };

    /** */
        // eslint-disable-next-line
    private pieTooltip: TooltipSettingsModel = {enable: true, format: '${point.x} : <b>${point.y}%</b>'};

    /** Bar chart */
    private barModel: AxisModel = {
        valueType: 'Category',
        interval: 1,
        majorGridLines: {width: 0},
        labelStyle: {
            color: 'white',
        },
    };

    /** Base model */
    private multiSelectModel: MultiSelectModel = {
        floatLabelType: 'Auto',
        mode: 'CheckBox',
        showDropDownIcon: true,
        fields: {value: 'id', text: 'text'},
        filterType: 'Contains',
        ignoreCase: true,
        showClearButton: true,
        sortOrder: 'Ascending',
    };

    /** Status select */
    private statusOptions: any[] = [
        {id: 0, text: 'Begge'},
        {id: 1, text: 'Aktive'},
        {id: 2, text: 'Lukkede'},
    ];

    public constructor(props: Props) {
        super(props);
        const filters: LogFilters = filterStore.value;
        const view = {grid: true, pie: false, bar: false};
        this.state = {departments: [], locations: [], subLocations: [], filters, loading: true, view, reports: null};
        this.getReports = this.getReports.bind(this);
        this.toChartData = this.toChartData.bind(this);
        this.updateDateRange = this.updateDateRange.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const locations = await ReportService.getLocations();
        const subLocations = await ReportService.getSubLocations();
        const departments = await ReportService.getDepartments();
        this.setState({locations, departments, subLocations, loading: false});
    }

    public override render(): ReactNode {
        const {loading, filters, view, reports, departments, locations, subLocations} = this.state;

        return (
            <section className={'mt-5 mb-3'}>
                <div className={'container mb-3'}>
                    <h1>Rapportlogg</h1>
                </div>
                <Authorized loading={loading} aclStore={aclLog} acl={Acl.read}>
                    <div className={'container'}>
                        <div className={'card'}>
                            <div className={'card-body'}>
                                <h5 className={'card-title'}>Filter</h5>
                            </div>
                            <div className={'card-body'}>

                                <div className={'row'}>

                                    <div className={'col-12 col-lg-2 mt-2'}>
                                        <DateRangePickerComponent value={filters.dateRange} firstDayOfWeek={1}
                                                                  floatLabelType={'Auto'}
                                                                  placeholder={'Tidsperspektiv'}
                                                                  format={TemplateHelper.date}
                                                                  change={this.updateDateRange}/>
                                    </div>

                                    <div className={'col-12 col-lg-2 mt-2'}>
                                        <MultiSelectComponent {...this.multiSelectModel}
                                                              value={filters.locationIds}
                                                              change={e => this.setState({
                                                                  filters: {
                                                                      ...this.state.filters,
                                                                      locationIds: e.value,
                                                                  },
                                                              })}
                                                              dataSource={locations as any}
                                                              placeholder={'Lokasjoner'}
                                                              noRecordsTemplate={'Fant ingen lokasjoner'}
                                                              showSelectAll={true}>
                                            <Inject services={[CheckBoxSelection]}/>
                                        </MultiSelectComponent>
                                    </div>

                                    <div className={'col-12 col-lg-2 mt-2'}>
                                        <MultiSelectComponent {...this.multiSelectModel}
                                                              value={filters.subLocationIds}
                                                              change={e => this.setState({
                                                                  filters: {
                                                                      ...this.state.filters,
                                                                      subLocationIds: e.value,
                                                                  },
                                                              })}
                                                              dataSource={subLocations as any}
                                                              placeholder={'Steder'}
                                                              noRecordsTemplate={'Fant ingen steder'}
                                                              showSelectAll={true}>
                                            <Inject services={[CheckBoxSelection]}/>
                                        </MultiSelectComponent>
                                    </div>

                                    <div className={'col-12 col-lg-2 mt-2'}>
                                        <MultiSelectComponent {...this.multiSelectModel}
                                                              value={filters.departmentIds}
                                                              change={e => this.setState({
                                                                  filters: {
                                                                      ...this.state.filters,
                                                                      departmentIds: e.value,
                                                                  },
                                                              })}
                                                              dataSource={departments as any}
                                                              placeholder={'Avdelinger'}
                                                              noRecordsTemplate={'Fant ingen avdelinger'}
                                                              showSelectAll={true}>
                                            <Inject services={[CheckBoxSelection]}/>
                                        </MultiSelectComponent>
                                    </div>

                                    <div className={'col-12 col-lg-2 mt-2'}>
                                        <MultiSelectComponent {...this.multiSelectModel}
                                                              value={filters.categories}
                                                              change={e => this.setState({
                                                                  filters: {
                                                                      ...this.state.filters,
                                                                      categories: e.value,
                                                                  },
                                                              })}
                                                              dataSource={ReportHelper.reportCategories as any}
                                                              placeholder={'Kategorier'}
                                                              noRecordsTemplate={'Fant ingen kategorier'}
                                                              showSelectAll={true}>
                                            <Inject services={[CheckBoxSelection]}/>
                                        </MultiSelectComponent>
                                    </div>

                                    <div className={'col-12 col-lg-2 mt-2'}>
                                        <DropDownListComponent value={filters.status}
                                                               change={e => this.setState({
                                                                   filters: {
                                                                       ...this.state.filters,
                                                                       status: e.value,
                                                                   },
                                                               })}
                                                               dataSource={this.statusOptions}
                                                               floatLabelType={'Auto'}
                                                               placeholder={'Status'}
                                                               fields={{value: 'id', text: 'text'}}/>
                                    </div>

                                </div>
                                {/* Row End */}
                            </div>
                            {/* Card Body End */}

                            <div className={'card-footer d-flex justify-content-end'}>
                                <button className={'btn btn-secondary'} onClick={this.getReports}>
                                    <i className={'fa-solid fa-play me-3 text-primary'}/>Hent
                                </button>
                            </div>

                        </div>

                        <div className={'input-group mt-3'}>

                            <button className={`form-control ${view.bar ? 'btn-primary' : ''}`}
                                    onClick={() => this.toggleView('bar')}>
                                <i className={'fa-solid fa-chart-simple'}/> Søylediagram
                            </button>

                            <button className={`form-control ${view.pie ? 'btn-primary' : ''}`}
                                    onClick={() => this.toggleView('pie')}>
                                <i className={'fa-solid fa-chart-pie'}/> Sektordiagram
                            </button>

                            <button className={`form-control ${view.grid ? 'btn-primary' : ''}`}
                                    onClick={() => this.toggleView('grid')}>
                                <i className={'fa-solid fa-table'}/> Tabell
                            </button>

                        </div>

                        <hr/>
                    </div>
                    {/* Container End */}

                    {reports ? <>
                        {view.bar ?
                            <div className={'container'}>
                                <ChartComponent background={'transparent'} primaryXAxis={this.barModel}
                                                tooltip={{enable: true}}>
                                    <SeriesCollectionDirective>
                                        <SeriesDirective dataSource={this.toChartData()} animation={{enable: false}}
                                                         xName={'category'} yName={'count'} type={'Bar'}
                                                         pointColorMapping={'color'}/>
                                    </SeriesCollectionDirective>
                                    <Inject services={[BarSeries, DataLabel, SfCategory, Tooltip]}/>
                                </ChartComponent>
                                <hr/>
                            </div> : null}
                        {view.pie ?
                            <div className={'container'}>
                                <AccumulationChartComponent tooltip={this.pieTooltip} background={'transparent'}>
                                    <AccumulationSeriesCollectionDirective>
                                        <AccumulationSeriesDirective dataSource={this.toChartData()}
                                                                     xName={'category'}
                                                                     yName={'percent'} dataLabel={this.pieModel}
                                                                     animation={{enable: false}}
                                                                     pointColorMapping={'color'}/>
                                    </AccumulationSeriesCollectionDirective>
                                    <Inject services={[PieSeries, AccumulationDataLabel, AccumulationTooltip]}/>
                                </AccumulationChartComponent>
                                <hr/>
                            </div> : null}
                        {view.grid ?

                            <div className={'container-fluid'}>
                                <ReportTable reports={reports}/>
                            </div>
                            : null}
                    </> : null}
                </Authorized>
            </section>
        );
    }

    /** */
    private updateDateRange(e: RangeEventArgs) {
        const filters = this.state.filters;
        filters.dateRange.start = e.startDate;
        e.endDate?.setHours(23);
        e.endDate?.setMinutes(59);
        e.endDate?.setSeconds(59);
        filters.dateRange.end = e.endDate;
        this.setState({filters});
    }

    /** */
    private toChartData(): any[] {
        const reports = this.state.reports;
        if (!reports) {
            return [];
        }

        const res: any[] = [];
        const categories = ReportHelper.reportCategories;
        for (const category of categories) {
            const count = reports.filter(report => report.category === category.id).length;
            const percent = 100 / reports.length * count;
            const text = `${category}: ${count}`;
            const color = ReportHelper.menuColors(category.text as MenuItem);
            res.push({category: category.text, count, text, percent, color});
        }

        return res;
    }

    /** */
    private toggleView(type: 'bar' | 'pie' | 'grid') {
        const view = this.state.view;
        switch (type) {
            case 'bar':
                view.bar = !view.bar;
                break;
            case 'pie':
                view.pie = !view.pie;
                break;
            case 'grid':
                view.grid = !view.grid;
                break;
        }
        this.setState({view});
    }

    /** */
    private async getReports() {
        SpinnerHelper.show();
        const filters = this.state.filters;
        filterStore.value = filters;
        const reports = await LogService.getReports(filters);
        this.setState({reports}, () => SpinnerHelper.hide());

    }
}

export function ReportLog() {
    const navigate = useNavigate();
    return (<Internal navigate={navigate}/>);
}
