import {HttpClient, ServiceBase} from '@hapro/template-react';
import type {Report} from '../entities/Report';
import type {LogFilters} from '../models/LogFilters';

/** */
export class LogService extends ServiceBase {

    /** @returns All reports */
    public static async getReports(filters: LogFilters): Promise<Report[]> {
        try {
            const res: Response = await HttpClient.put('/V1/Log/Reports', filters);
            this.expectSuccessStatusCode(res);
            const reports = await this.expectObject<Report[]>(res);
            HttpClient.initializeDates(reports);
            return reports;
        } catch (e) {
            this.handleError(e, 'Kunne ikke hente hendelsesrapporter');
            return [];
        }
    }
}
