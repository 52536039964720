import {Acl, AclGate, SpinnerHelper, StdFormat, TemplateHelper} from '@hapro/template-react';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import {NumericTextBoxComponent, TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import {
    HtmlEditor,
    Inject,
    Link,
    QuickToolbar,
    RichTextEditorComponent,
    Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import type {Department} from '../entities/Department';
import type {Injury} from '../entities/Injury';
import type {Location} from '../entities/Location';
import type {Report} from '../entities/Report';
import type {SubLocation} from '../entities/SubLocation';
import {ReportCategory} from '../enums/ReportCategory';
import {MiscHelper} from '../helpers/MiscHelper';
import {ReportHelper} from '../helpers/ReportHelper';
import {aclAdmin} from '../services/aclStores';
import {AdminService} from '../services/AdminService';
import {ReportService} from '../services/ReportService';
import {ReportManager} from '../services/ReportStore';
import {Categories} from './Categories';
import {DepartmentSelect} from './DepartmentSelect';
import {LocationSelect} from './LocationSelect';
import {ReportField} from './ReportField';
import {ResponsibleSelect} from './ResponsibleSelect';
import {SubLocationSelect} from './SubLocationSelect';
import {TypeSelect} from './TypeSelect';
import {Units} from './Units';

interface Props {
    report: Report;
    closed: boolean;
}

interface State {

}

/** */
export class ReportForm extends React.Component<Props, State> {

    /** */
    private injury: Injury;

    /** */
    private department: Department | null;

    /** */
    private location: Location | null;

    /** */
    private responsible: number | null;

    /** */
    private subLocation: SubLocation | null;

    /** */
    private readonly report: Report;

    constructor(props: Props) {
        super(props);
        this.injury = props.report.injury ? {...props.report.injury} : {daysAbsent: null, reported: null};
        this.department = props.report.department;
        this.location = props.report.location;
        this.subLocation = props.report.subLocation;
        this.responsible = props.report.responsible;
        this.report = {...props.report};
        this.resetTitle = this.resetTitle.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.resetType = this.resetType.bind(this);
        this.updateType = this.updateType.bind(this);
        this.resetText = this.resetText.bind(this);
        this.updateText = this.updateText.bind(this);
        this.resetInitialMeasure = this.resetInitialMeasure.bind(this);
        this.updateInitialMeasure = this.updateInitialMeasure.bind(this);
        this.resetCategory = this.resetCategory.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.resetDate = this.resetDate.bind(this);
        this.updateDate = this.updateDate.bind(this);
        this.resetResponsible = this.resetResponsible.bind(this);
        this.updateResponsible = this.updateResponsible.bind(this);
        this.resetDepartment = this.resetDepartment.bind(this);
        this.updateDepartment = this.updateDepartment.bind(this);
        this.resetLocation = this.resetLocation.bind(this);
        this.updateLocation = this.updateLocation.bind(this);
        this.resetSubLocation = this.resetSubLocation.bind(this);
        this.updateSubLocation = this.updateSubLocation.bind(this);
        this.resetReported = this.resetReported.bind(this);
        this.updateReported = this.updateReported.bind(this);
        this.resetDaysAbsent = this.resetDaysAbsent.bind(this);
        this.updateDaysAbsent = this.updateDaysAbsent.bind(this);
    }

    public override render(): ReactNode {
        const {report, closed} = this.props;

        return (
            <>
                {/* Title */}
                <ReportField closed={closed} title={'Tittel'}
                             update={this.updateTitle} reset={this.resetTitle} displayText={report.title}>
                    <TextBoxComponent value={report.title} autocomplete={'off'}
                                      change={e => this.report.title = e.value ?? ''}/>
                </ReportField>

                {/* Type and Category */}
                <div className={'row'}>

                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField closed={closed} title={'Type'}
                                     update={this.updateType} reset={this.resetType} displayText={report.type}>
                            <TypeSelect value={report.type} category={report.category}
                                        onChange={value => this.report.type = value}/>
                        </ReportField>
                    </div>
                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField title={'Kategori'}
                                     update={this.updateCategory} reset={this.resetCategory} closed={closed}
                                     displayText={ReportHelper.categoryText(report.category)}>
                            <Categories value={report.category} onChange={value => this.report.category = value}/>
                        </ReportField>
                    </div>

                </div>

                {/* Date and User */}
                <div className={'row'}>

                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField title={'Dato'}
                                     update={this.updateDate} reset={this.resetDate} closed={closed}
                                     displayText={TemplateHelper.toStdDate(report.date, StdFormat.date)}>
                            <DatePickerComponent value={report.date}
                                                 firstDayOfWeek={1} format={TemplateHelper.date} showClearButton={false}
                                                 showTodayButton={false} strictMode={true}
                                                 change={e => this.report.date = e.value ?? this.report.date}/>
                        </ReportField>
                    </div>
                    <div className={'col-md-6 col-sm-12'}>
                        <div className={'d-flex justify-content-between'}>
                            <p className={'form-label'}>Registrert av</p>
                        </div>
                        <p className={'bg-dark rounded ps-3 pe-3 pt-1 pb-1'}>{report.createdDisplayName}</p>
                    </div>

                </div>

                {/* Injury specific */}
                {report.category === ReportCategory.injury ?
                    <AclGate aclStore={aclAdmin} level={Acl.erase}>
                        <div className={'row'}>
                            <div className={'col-md-6 col-sm-12 mt-auto mb-3'}>
                                <ReportField title={'Fraværsdager'}
                                             update={this.updateDaysAbsent} reset={this.resetDaysAbsent} closed={closed}
                                             displayText={report.injury?.daysAbsent?.toString() ?? '--'}>
                                    <NumericTextBoxComponent value={report.injury?.daysAbsent ?? undefined}
                                                             strictMode={true} format={'0'} decimals={0}
                                                             showClearButton={true} min={1}
                                                             change={e => this.injury.daysAbsent = e.value}/>
                                </ReportField>
                            </div>
                            <div className={'col-md-6 col-sm-12 mb-3'}>
                                <ReportField title={'Meldt inn som yrkesskade'}
                                             update={this.updateReported} reset={this.resetReported} closed={closed}
                                             displayText={TemplateHelper.toStdDate(report.injury?.reported,
                                                 StdFormat.date,
                                                 '--')}>
                                    <DatePickerComponent value={report.injury?.reported ?? undefined} strictMode={false}
                                                         showClearButton={true} firstDayOfWeek={1}
                                                         format={TemplateHelper.date}
                                                         change={e => this.injury.reported = e.value}/>
                                </ReportField>
                            </div>
                        </div>
                    </AclGate>
                    : null}

                {/* Responsible and Department */}
                <div className={'row'}>
                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField title={'Ansvarlig'}
                                     update={this.updateResponsible} reset={this.resetResponsible} closed={closed}
                                     displayText={report.responsibleDisplayName}>
                            <ResponsibleSelect value={report.responsible}
                                               onChange={value => this.responsible = value?.id ?? null}/>
                        </ReportField>
                    </div>
                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField title={'Avdeling'}
                                     update={this.updateDepartment} reset={this.resetDepartment} closed={closed}
                                     displayText={report.department?.text ?? 'Uspesifisert'}>
                            <DepartmentSelect id={report.department?.id} onChange={value => this.department = value}/>
                        </ReportField>
                    </div>
                </div>

                {/* Location and SubLocation */}
                <div className={'row mt-3'}>
                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField title={'Lokasjon'}
                                     update={this.updateLocation} reset={this.resetLocation} closed={closed}
                                     displayText={report.location?.text ?? 'Uspesifisert'}>
                            <LocationSelect id={report.location?.id} onChange={value => this.location = value}/>
                        </ReportField>
                    </div>
                    <div className={'col-md-6 col-sm-12'}>
                        <ReportField title={'Sted'}
                                     update={this.updateSubLocation} reset={this.resetSubLocation} closed={closed}
                                     displayText={report.subLocation?.text ?? 'Uspesifisert'}>
                            <SubLocationSelect text={report.subLocation?.text ?? ''}
                                               onChange={value => this.subLocation = value}/>
                        </ReportField>
                    </div>
                </div>

                {/* Text */}
                <ReportField title={ReportHelper.descriptionTitle(report.category)}
                             update={this.updateText} reset={this.resetText} closed={closed}
                             displayText={report.text} html={true}>
                    <RichTextEditorComponent value={report.text} height={300} toolbarSettings={MiscHelper.rteSettings}
                                             change={e => this.report.text = e.value}>
                        <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]}/>
                    </RichTextEditorComponent>
                </ReportField>

                {/* InitialMeasure */}
                <ReportField title={ReportHelper.initialMeasure(report.category)}
                             update={this.updateInitialMeasure} reset={this.resetInitialMeasure} closed={closed}
                             displayText={report.initialMeasure} html={true}>
                    <RichTextEditorComponent value={report.initialMeasure} height={300}
                                             toolbarSettings={MiscHelper.rteSettings}
                                             change={e => this.report.initialMeasure = e.value}>
                        <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]}/>
                    </RichTextEditorComponent>
                </ReportField>

                {/* Units */}
                <Units report={report}/>
            </>
        );
    }

    /** */
    private resetTitle(): void {
        const {report} = this.props;
        report.title = this.report.title;
    }

    /** */
    private async updateTitle(): Promise<void> {
        const {report} = this.props;
        if (this.report.title !== report.title) {
            SpinnerHelper.show();
            const success = await ReportService.patchTitle(report.id, this.report.title);
            if (success) {
                report.title = this.report.title;
                this.setState({});
            } else {
                this.report.title = report.title;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetType(): void {
        const {report} = this.props;
        report.type = this.report.type;
    }

    /** */
    private async updateType(): Promise<void> {
        const {report} = this.props;
        if (this.report.type !== report.type) {
            SpinnerHelper.show();
            const success = await ReportService.patchType(report.id, this.report.type);
            if (success) {
                report.type = this.report.type;
                this.setState({});
            } else {
                this.report.type = report.type;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetText(): void {
        const {report} = this.props;
        report.text = this.report.text;
    }

    /** */
    private async updateText(): Promise<void> {
        const {report} = this.props;
        if (this.report.text !== report.text) {
            SpinnerHelper.show();
            const success = await ReportService.patchText(report.id, this.report.text);
            if (success) {
                report.text = this.report.text;
                this.setState({});
            } else {
                this.report.text = report.text;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetInitialMeasure(): void {
        const {report} = this.props;
        report.initialMeasure = this.report.initialMeasure;
    }

    /** */
    private async updateInitialMeasure(): Promise<void> {
        const {report} = this.props;
        if (this.report.initialMeasure !== report.initialMeasure) {
            SpinnerHelper.show();
            const success = await ReportService.patchInitialMeasure(report.id, this.report.initialMeasure);
            if (success) {
                report.initialMeasure = this.report.initialMeasure;
                this.setState({});
            } else {
                this.report.initialMeasure = report.initialMeasure;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetCategory(): void {
        const {report} = this.props;
        report.category = this.report.category;
    }

    /** */
    private async updateCategory(): Promise<void> {
        const {report} = this.props;
        if (this.report.category !== report.category) {
            SpinnerHelper.show();
            const success = await ReportService.patchCategory(report.id, this.report.category);
            if (success) {
                report.category = this.report.category;
                report.type = TypeSelect.getTypes(report.category)[0];
                this.setState({});
            } else {
                this.report.category = report.category;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetDate(): void {
        const {report} = this.props;
        report.date = this.report.date;
    }

    /** */
    private async updateDate(): Promise<void> {
        const {report} = this.props;
        if (this.report.date !== report.date) {
            SpinnerHelper.show();
            const success = await ReportService.patchDate(report.id, this.report.date);
            if (success) {
                report.date = this.report.date;
                this.setState({});
            } else {
                this.report.date = report.date;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetResponsible(): void {
        const {report} = this.props;
        report.responsible = this.report.responsible;
    }

    /** */
    private async updateResponsible(): Promise<void> {
        const {report} = this.props;
        if (this.responsible !== report.responsible) {
            SpinnerHelper.show();
            const success = await ReportService.patchResponsible(report.id, this.responsible);
            if (success) {
                await ReportManager.updateReport(report.id);
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetDepartment(): void {
        const {report} = this.props;
        report.department = this.report.department;
    }

    /** */
    private async updateDepartment(): Promise<void> {
        const {report} = this.props;
        if (this.department?.id !== report.department?.id) {
            SpinnerHelper.show();
            const success = await ReportService.patchDepartment(report.id, this.department?.id);
            if (success) {
                report.department = this.department ? {...this.department} : null;
                this.setState({});
            } else {
                this.department = report.department ? {...report.department} : null;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetLocation(): void {
        const {report} = this.props;
        report.location = this.report.location;
    }

    /** */
    private async updateLocation(): Promise<void> {
        const {report} = this.props;
        if (this.location?.id !== report.location?.id) {
            SpinnerHelper.show();
            const success = await ReportService.patchLocation(report.id, this.location?.id);
            if (success) {
                report.location = this.location ? {...this.location} : null;
                this.setState({});
            } else {
                this.location = report.location ? {...report.location} : null;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetSubLocation(): void {
        const {report} = this.props;
        report.subLocation = this.report.subLocation;
    }

    /** */
    private async updateSubLocation(): Promise<void> {
        const {report} = this.props;
        if (this.subLocation?.id !== report.subLocation?.id) {
            SpinnerHelper.show();
            const success = await ReportService.patchSubLocation(report.id, this.subLocation?.text ?? '');
            if (success) {
                report.subLocation = this.subLocation ? {...this.subLocation} : null;
                this.setState({});
            } else {
                this.subLocation = report.subLocation ? {...report.subLocation} : null;
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetReported(): void {
        const {report} = this.props;
        if (report.injury) {
            report.injury.reported = this.injury.reported;
        }
    }

    /** */
    private async updateReported(): Promise<void> {
        const {report} = this.props;
        if (this.injury && this.injury.reported !== report.injury?.reported) {
            SpinnerHelper.show();
            const success = await AdminService.patchInjury(report.id, this.injury, 'reported');
            if (success) {
                report.injury = this.injury ? {...this.injury} : null;
                this.setState({});
            } else {
                this.injury = report.injury ? {...report.injury} : {daysAbsent: null, reported: null};
            }
            SpinnerHelper.hide();
        }
    }

    /** */
    private resetDaysAbsent(): void {
        const {report} = this.props;
        if (report.injury) {
            report.injury.daysAbsent = this.injury.daysAbsent;
        }
    }

    /** */
    private async updateDaysAbsent(): Promise<void> {
        const {report} = this.props;
        if (this.injury && this.injury.daysAbsent !== report.injury?.daysAbsent) {
            SpinnerHelper.show();
            const success = await AdminService.patchInjury(report.id, this.injury, 'daysAbsent');
            if (success) {
                report.injury = this.injury ? {...this.injury} : null;
                this.setState({});
            } else {
                this.injury = report.injury ? {...report.injury} : {daysAbsent: null, reported: null};
            }
            SpinnerHelper.hide();
        }
    }
}
