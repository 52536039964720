import {Acl, Authorized, Collapse, FileForm, Modal, SpinnerHelper, userStore} from '@hapro/template-react';
import {DropDownListComponent} from '@syncfusion/ej2-react-dropdowns';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ReactNode} from 'react';
import React from 'react';
import {Link, NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {TaskProgress} from '../../components/TaskProgress';
import type {BulletPoint} from '../../entities/BulletPoint';
import type {Protection} from '../../entities/Protection';
import {PointStatus} from '../../enums/PointStatus';
import {ReportCategory} from '../../enums/ReportCategory';
import {TaskHelper} from '../../helpers/TaskHelper';
import type {ReportForm} from '../../models/ReportForm';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    params: URLSearchParams;
    navigate: NavigateFunction;
}

interface State {
    protection: Protection | null;
    loading: boolean;
}

class Internal extends React.Component<Props, State> {

    /** */
    private fileForm: FileForm | null = null;

    /** */
    private dropDown: DropDownListComponent | null = null;

    /** */
    private comment: TextBoxComponent | null = null;

    /** */
    private modal: Modal | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {protection: null, loading: true};
        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.savePoint = this.savePoint.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const {params, navigate} = this.props;
        const id = Number(params.get('id'));
        if (isNaN(id)) {
            navigate('/protection', {replace: true});
            return;
        }

        const protection = await ReportService.getProtection(id);
        this.setState({protection, loading: false}, () => {
            if (this.getSelected(params, protection)) {
                this.modal?.show();
            }
        });
    }

    public override render(): ReactNode {
        const {protection, loading} = this.state;
        const {params} = this.props;

        const id = params.get('id');
        const selected = this.getSelected(params, protection);
        const canManage = TaskHelper.canManageProtection(protection, userStore.value?.userMinimal?.id);

        return (<section className={'container mt-5'}>
            <h1>Vernerunde - Gå vernerunde #{id}</h1>
            <hr/>
            <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                <TaskProgress step={2} id={id} type={'protection'}/>

                <div className={'card mt-3'}>
                    <div className={'card-header'}>
                        <h5 className={'card-title'}>Velg sjekkpunkt for behandling</h5>
                    </div>
                    <div className={'card-body'}>
                        <p className={'form-label'}>Vernerunde:</p>
                        <h5>{protection?.title}</h5>
                        <hr/>
                        <div className={'d-flex flex-column gap-3'}>
                            {protection?.bulletPointGroups.map((group, i) => <div className={'card'} key={i}>
                                <div className={'card-header'}>
                                    <h5 className={`card-title ${selected?.groupId === group.id ?
                                        'text-primary' :
                                        ''}`}>{group.label}</h5>
                                </div>
                                <Collapse auto={true}>
                                    <div className={'card-body d-flex flex-column gap-1'}>
                                        {group.bulletPoints.map(
                                            (point, j) => <div key={j} onClick={() => this.goTo(group.id, point.id)}
                                                               className={`d-flex selectable pt-1 pb-1 pe-3 ps-3 rounded bg-dark ${selected?.id ===
                                                               point.id && selected.groupId === group.id ?
                                                                   'text-primary' :
                                                                   ''}`}>
                                                <i className={`${TaskHelper.statusIcons.get(
                                                    point.status)?.iconCss} mt-auto mb-auto me-2`}/>
                                                {point.label}
                                            </div>)}
                                    </div>
                                </Collapse>
                            </div>)}
                        </div>
                    </div>
                    <div className={'card-footer d-flex justify-content-between'}>
                        <Link to={`/protection/edit?id=${id}`} className={'btn btn-primary'}>
                            <i className={'fa-solid fa-fast-backward me-2'}/>Forrige
                        </Link>
                        <Link to={`/protection/report?id=${id}`} className={'btn btn-primary'}>
                            <i className={'fa-solid fa-fast-forward me-2'}/>Neste
                        </Link>
                    </div>
                </div>

                <Modal ref={m => this.modal = m} centered={true} size={'xl'} animation={true}>
                    <div className={'modal-header'}>
                        <h5 className={'modal-title'}>Behandle sjekkpunkt</h5>
                        <button className={'btn-close'} onClick={() => this.modal?.hide()}/>
                    </div>
                    {selected ? <div className={'modal-body'}>
                        <p className={'form-label'}>Gruppe</p>
                        <h5>{protection?.bulletPointGroups.find(b => b.id === selected.groupId)?.label}</h5>
                        <p className={'form-label mt-3'}>Sjekkpunkt</p>
                        <h5>{selected.label}</h5>
                        <p className={'form-label mt-3'}>Filer</p>
                        <FileForm ref={f => this.fileForm = f} files={selected.files}
                                  noUpload={!canManage} noRemove={!canManage}
                                  download={`/V1/Report/ProtectionFile/${protection?.id}/${selected?.id}`}
                                  upload={`/V1/Report/ProtectionFile/${protection?.id}/${selected?.id}`}
                                  remove={`/V1/Report/ProtectionFile/${protection?.id}/${selected?.id}`}/>
                        <p className={'form-label mt-3'}>Kommentar</p>
                        <TextBoxComponent ref={t => this.comment = t} value={selected.comment ?? ''}
                                          multiline={true} readonly={!canManage}/>
                        <div className={'d-flex mt-3'}>
                            <div className={'col-8'}>
                                <p className={'form-label'}>Status</p>
                                <DropDownListComponent ref={d => this.dropDown = d} value={selected?.status}
                                                       readonly={!canManage}
                                                       fields={{
                                                           value: 'id', text: 'text', iconCss: 'iconCss',
                                                       }} valueTemplate={TaskHelper.statusTemplate}
                                                       dataSource={TaskHelper.statuses as any}/>
                            </div>
                            {canManage ?
                                <div className={'col-4 d-flex'}>
                                    <button className={'ms-auto mt-auto btn btn-success btn-sm'}
                                            onClick={this.savePoint}>
                                        <i className={'fa-solid fa-save me-2'}/>Lagre
                                    </button>
                                </div>
                                : null}
                        </div>
                        {selected.reportId ? <a href={`/report/${selected.reportId}`} className={'btn btn-sm mt-3'}>
                            <i className={'fa-thin fa-edit me-2'}/>Gå til hendelsesrapport
                        </a> : null}
                    </div> : null}
                    <div className={'modal-footer d-flex justify-content-between'}>
                        <button className={'btn btn-secondary'} onClick={this.prev}>
                            <i className={'fa-solid fa-backward me-2'}/>Forrige
                        </button>
                        <button className={'btn btn-primary'} onClick={this.next}>
                            <i className={'fa-solid fa-play me-2'}/>Neste
                        </button>
                    </div>
                </Modal>
            </Authorized>
        </section>);
    }

    /** */
    private getSelected(params: URLSearchParams, protection: Protection | null): BulletPoint | null {
        let selected = null;
        const groupId = Number(params.get('group'));
        if (!isNaN(groupId)) {
            const group = protection?.bulletPointGroups.find(g => g.id === groupId);
            if (group) {
                const pointId = Number(params.get('point'));
                if (!isNaN(pointId)) {
                    const point = group.bulletPoints.find(p => p.id === pointId);
                    if (point) {
                        selected = point;
                    }
                }
            }
        }

        if (!selected) {
            this.modal?.hide();
        } else {
            this.modal?.show();
        }

        return selected;
    }

    /** */
    private prev(): void {
        const {protection} = this.state;
        const {params} = this.props;
        const selected = this.getSelected(params, protection);
        if (!protection || !selected) {
            return;
        }

        const parent = protection.bulletPointGroups.find(b => b.id === selected?.groupId);
        if (parent) {
            const index = parent.bulletPoints.findIndex(b => b.id === selected?.id);
            if (index !== -1 && index > 0) {
                this.update(parent.bulletPoints[index - 1]);
            } else {
                const index = protection.bulletPointGroups.findIndex(b => b.id === selected?.groupId);
                if (index !== -1 && index > 0) {
                    const parent = protection.bulletPointGroups[index - 1];
                    this.update(parent.bulletPoints[parent.bulletPoints.length - 1]);
                } else {
                    this.modal?.hide();
                }
            }
        }
    }

    /** */
    private next(): void {
        const {protection} = this.state;
        const {params} = this.props;
        const selected = this.getSelected(params, protection);
        if (!protection || !selected) {
            return;
        }

        const parent = protection.bulletPointGroups.find(b => b.id === selected?.groupId);
        if (parent) {
            const index = parent.bulletPoints.findIndex(b => b.id === selected?.id);
            if (index !== -1 && index < parent.bulletPoints.length - 1) {
                this.update(parent.bulletPoints[index + 1]);
            } else {
                const index = protection.bulletPointGroups.findIndex(b => b.id === selected?.groupId);
                if (index !== -1 && index < protection.bulletPointGroups.length - 1) {
                    const parent = protection.bulletPointGroups[index + 1];
                    this.update(parent.bulletPoints[0]);
                } else {
                    this.modal?.hide();
                }
            }
        }
    }

    /** */
    private goTo(parentId: number, childId: number): void {
        const group = this.state.protection?.bulletPointGroups.find(b => b.id === parentId);
        const selected = group?.bulletPoints.find(b => b.id === childId);
        if (selected && group) {
            this.update(selected);
            this.modal?.show();
        }
    }

    /** Update components */
    private update(selected: BulletPoint): void {
        const {params, navigate} = this.props;
        if (this.dropDown && this.comment && this.fileForm) {
            this.fileForm.setState({files: selected?.files ?? []});
            this.dropDown.value = selected?.status ?? 0;
            this.comment.value = selected?.comment ?? '';
        }
        if (params.has('group')) {
            params.set('group', selected.groupId.toString());
        } else {
            params.append('group', selected.groupId.toString());
        }
        if (params.has('point')) {
            params.set('point', selected.id.toString());
        } else {
            params.append('point', selected.id.toString());
        }
        navigate(`${window.location.pathname}?${params}`);
    }

    /** */
    private async savePoint(): Promise<void> {
        SpinnerHelper.block();
        const {protection} = this.state;
        const {params} = this.props;
        const selected = this.getSelected(params, protection);
        const status = this.dropDown?.value as PointStatus;
        const comment = this.comment?.value ?? '';
        if (protection && selected && typeof status === 'number') {
            selected.status = status;
            selected.comment = comment;

            // Create report if not exists
            if ((selected.status === PointStatus.deviation || selected.status === PointStatus.improvement)
                && selected.reportId === null) {

                const reportForm: ReportForm = {
                    title: selected.label,
                    subLocationText: protection.subLocation?.text ?? '',
                    category: selected.status === PointStatus.deviation
                        ? ReportCategory.deviation
                        : ReportCategory.improvement,
                    date: new Date(),
                    daysAbsent: null,
                    initialMeasure: '',
                    locationId: protection.location?.id ?? null,
                    pictures: 0,
                    reported: null,
                    responsible: userStore.value?.userMinimal?.id ?? 0,
                    text: selected.comment,
                    type: 'Vernerunde',
                };
                selected.reportId = await ReportService.post(reportForm);
            }

            const success = await ReportService.patchBulletPoint(protection.id, selected);
            if (success) {
                selected.status = status;
                this.setState({});
            }
        }
        SpinnerHelper.unblock();
    }
}

/** */
export function Execute() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    return (<Internal params={params} navigate={navigate}/>);
}
