import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {ReportHelper} from '../helpers/ReportHelper';
import styles from '../style/ReportCell.module.scss';
import type {MenuItem} from '../types/MenuItem';

interface Props {
    icon: string;
    category: MenuItem;
}

interface State {

}

export class ReportCell extends React.Component<Props, State> {

    public override render(): ReactNode {
        const {icon, category} = this.props;

        const url = category === 'Vernerunde' ? '/protection/edit'
            : category === 'SJA'
                ? '/analysis/edit'
                : `/report?${new URLSearchParams({category})}`;

        return (
            <div className={'col-6 col-lg-4 mb-lg-5 mb-0 mt-md-5 mt-3'}>
                <Link to={url} className={`${styles.cell} rounded p-3 m-auto text-center border`}
                      style={{color: ReportHelper.menuColors(category)}}>
                    <i className={icon}/>
                    <h3 className={'mt-3 mb-md-5 mt-0'}>{category}</h3>
                </Link>
            </div>
        );
    }
}
