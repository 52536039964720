import {SpinnerHelper, TemplateHelper, userStore} from '@hapro/template-react';
import React, {ReactNode} from 'react';
import type {Comment} from '../entities/Comment';
import type {Report} from '../entities/Report';
import {ReportService} from '../services/ReportService';
import {ReportManager} from '../services/ReportStore';
import styles from '../style/CommentCard.module.scss';

interface Props {
    userId: number;
    comment: Comment;
    report: Report;
}

interface State {

}

export class CommentCard extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.upVote = this.upVote.bind(this);
        this.setSolution = this.setSolution.bind(this);
    }

    /** */
    private static isSelfClass(comment: Comment, userId: number): string {
        return comment.upVotes.find(upVote => upVote.createdBy === userId) ? 'text-info' : '';
    }

    public override render(): ReactNode {
        const {userId, comment, report} = this.props;

        const tooltipText = comment.upVotes.map(u => u.createdDisplayName).join('\n');
        const isClosed = report.closedCommentId === comment.id;
        const borderColor = isClosed ? 'border-primary' : 'border-dark';
        return (
            <div className={`card border ${borderColor} shadow`}>
                <div className={'card-header'}>
                    <div className={'d-flex justify-content-between gap-2'}>
                        <small className={'card-title font-sm'}>{comment.createdDisplayName} {this.getBadge(
                            comment, userId)}</small>
                        <div className={'d-flex gap-3'}>
                            <small>{TemplateHelper.toStdDate(comment.created)}</small>
                            <span title={tooltipText}>
                            <i className={`fas fa-thumbs-up pointer me-2 ${styles.upVote} ${CommentCard.isSelfClass(
                                comment, userId)}`} onClick={() => this.upVote(comment.id)}/>
                                {comment.upVotes.length}
                        </span>
                        </div>
                    </div>
                </div>
                <div className={'card-body p-2 pb-0 mb-0'} dangerouslySetInnerHTML={{__html: comment.text}}/>
                {isClosed ?
                    // Closed comment
                    <div className={'card-footer d-flex justify-content-end gap-3'}>
                        <small className={'mt-auto mb-auto'}>Markerer rapporten som lukket</small>
                        <i className={'fa-solid fa-ban fa-lg mt-auto mb-auto text-primary'}/>
                    </div>
                    : null}
            </div>
        );
    }

    /** */
    private getBadge(comment: Comment, userId: number): JSX.Element {
        const report = this.props.report;
        let verbosity = 'bg-dark';
        let text = 'ansatt';
        if (comment.createdBy === userId) { // Commented by you
            verbosity = 'bg-info';
            text = 'deg';
        } else if (report.createdBy === comment.createdBy) { // Commented by person who created report
            verbosity = 'bg-success';
            text = 'rapportør';
        } else if (report.responsible === comment.createdBy) { // Commented by person who is responsible for report
            verbosity = 'bg-danger';
            text = 'ansvarlig';
        } else if (userStore.value?.userMinimal?.superior === comment.createdBy) { // Commented by superior
            verbosity = 'bg-warning';
            text = 'din leder';
        } else if (report.correspondents.find(c => c.userId === comment.createdBy)?.id === -1) { // Commented by HMS responsible
            verbosity = 'bg-primary';
            text = 'HMS ansvarlig';
        } else if (report.correspondents.find(c => c.userId === comment.createdBy)) { // Commented by involved user
            verbosity = 'bg-secondary';
            text = 'involvert';
        }
        return (<span className={`ms-2 badge ${verbosity}`}>{text}</span>);
    }

    /** */
    private async setSolution(commentId: number) {
        SpinnerHelper.show();
        const success = await ReportService.postSolution(this.props.report.id, commentId);
        if (success) {
            await ReportManager.updateReport(this.props.report.id);
        }
        SpinnerHelper.hide();
    }

    /** */
    private async upVote(commentId: number): Promise<void> {
        const success = await ReportService.patchUpVote(commentId);
        if (success) {
            await ReportManager.updateReport(this.props.report.id);
        }
    }
}
