import type {ProxyFile} from '@hapro/template-react';
import type {RiskType} from '../enums/RiskType';

/** Activities for Analyses */
export interface Activity {
    id: number;
    analysisId: number;
    label: string;
    order: number;
    riskBefore: RiskType | null;
    description: string;
    responsible: number;
    responsibleDisplayName: string;
    measure: string | null;
    completed: boolean;
    riskAfter: RiskType | null;
    files: ProxyFile[];
}

/** */
export function defaultActivity(): Activity {
    return {
        id: 0,
        order: 0,
        analysisId: 0,
        label: '',
        description: '',
        riskBefore: null,
        completed: false,
        measure: null,
        responsibleDisplayName: '',
        responsible: 0,
        riskAfter: null,
        files: [],
    };
}
