import type {UserMinimal} from '@hapro/template-react';
import {Acl, Authorized, SpinnerHelper, ToastHelper} from '@hapro/template-react';
import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    CommandModel,
    Edit,
    EditSettingsModel,
    GridComponent,
    Page,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import {Inject} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import type {Correspondent} from '../entities/Correspondent';
import type {Report} from '../entities/Report';
import {aclReport} from '../services/aclStores';
import {ReportService} from '../services/ReportService';
import {ReportManager} from '../services/ReportStore';

interface Props {
    report: Report;
    closed: boolean;
}

interface State {
    loading: boolean;
    value: number;
    users: UserMinimal[];
    acl: Acl;
}

export class Correspondents extends React.Component<Props, State> {

    private fields: FieldSettingsModel = {value: 'id', text: 'displayName'};

    private commands: CommandModel[] = [
        {
            type: 'Delete',
            buttonOption: {cssClass: 'e-flat', iconCss: 'fa-thin fa-trash'},
        }];

    private editSettings: EditSettingsModel = {allowEditing: false, allowDeleting: true, showDeleteConfirmDialog: true};

    public constructor(props: Props) {
        super(props);
        this.state = {loading: true, value: 0, users: [], acl: aclReport.value};
        this.handleAction = this.handleAction.bind(this);
        this.addCorrespondent = this.addCorrespondent.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        aclReport.subscribe(value => {
            this.setState({acl: value});
        });

        const users = await ReportService.getUsers();
        this.setState({users, loading: false});
    }

    public override render(): ReactNode {
        const {loading, value, users, acl} = this.state;
        const {report, closed} = this.props;

        const canEdit = acl >= Acl.create && !closed;

        return (
            <Authorized loading={loading}>
                {canEdit ?
                    <>
                        <ComboBoxComponent value={value}
                                           dataSource={users as any}
                                           noRecordsTemplate={'Fant ingen ansatte'}
                                           allowFiltering={true}
                                           fields={this.fields}
                                           filterType={'Contains'}
                                           ignoreCase={true}
                                           allowCustom={false}
                                           onChange={this.addCorrespondent}
                                           placeholder={'Legg til bruker'}/>
                        <div className={'mt-3'}/>
                    </>
                    : null}

                <GridComponent dataSource={report.correspondents} allowPaging={true} allowSorting={true}
                               pageSettings={{pageSize: 10}} toolbar={['Search']}
                               editSettings={canEdit ? this.editSettings : {}}
                               actionBegin={this.handleAction}>
                    <ColumnsDirective>
                        <ColumnDirective field={'displayName'} headerText={'Bruker'} width={'9rem'}/>
                        <ColumnDirective headerText={'Fjern'} width={'3rem'} commands={this.commands}
                                         allowSorting={false}
                                         visible={canEdit}/>
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Toolbar, Edit, CommandColumn]}/>
                </GridComponent>
            </Authorized>
        );
    }

    /** Prevent deletion on certain users */
    private async handleAction(args: any): Promise<void> {
        if (args.requestType !== 'delete') {
            return;
        }

        const user: Correspondent = args.data[0];
        args.cancel = true;
        const report = this.props.report;
        switch (user.id) {
            case -1:
                ToastHelper.warn(`${user.displayName} er HMS Ansvarlig og kan ikke fjernes`, 'bootstrap');
                break;
            case -2:
                ToastHelper.warn(`${user.displayName} er nærmeste leder og kan ikke fjernes`, 'bootstrap');
                break;
            case -3:
                ToastHelper.warn(`${user.displayName} opprettet rapporten og kan ikke fjernes`, 'bootstrap');
                break;
            case -4:
                ToastHelper.warn(`${user.displayName} er satt som ansvarlig for rapporten og kan ikke fjernes`,
                    'bootstrap');
                break;
            default:
                args.cancel = false;
                SpinnerHelper.block();
                await ReportService.deleteCorrespondent(report.id, user.userId);
                SpinnerHelper.unblock();
                break;
        }
    }

    /** Add correspondent to Report */
    private async addCorrespondent(e: any): Promise<void> {
        const value = e.value;
        if (!value) {
            return;
        }

        SpinnerHelper.block();

        // Don't add duplicates
        const report = this.props.report;
        const user = report.correspondents.find(c => c.userId === value);
        if (user) {
            ToastHelper.warn(`${user.displayName} er allerede involvert`, 'bootstrap');
        } else {
            const correspondent = await ReportService.postCorrespondent(report.id, value);

            if (correspondent.id) {
                await ReportManager.updateReport(report.id);
            }
        }

        this.setState({value: 0});
        SpinnerHelper.unblock();
    }
}
