import {Collapse} from '@hapro/template-react';
import type {ReactNode} from 'react';
import React from 'react';
import {RiskType} from '../enums/RiskType';
import {TaskHelper} from '../helpers/TaskHelper';
import styles from '../style/RiskSelect.module.scss';

interface Props {
    value: RiskType | null;
    onChange: (type: RiskType) => void;
    readOnly?: boolean;
}

interface State {

}

/** */
export class RiskSelect extends React.Component<Props, State> {

    /** */
    private collapse: Collapse | null = null;

    public constructor(props: Props) {
        super(props);
    }

    public override render(): ReactNode {
        //const {} = this.state;
        const {onChange, value, readOnly} = this.props;

        const textIcon = typeof value === 'number' ? TaskHelper.riskIcons.get(value) : null;

        return (<>
            <div className={'form-control pointer d-flex'}>
                <i className={`${textIcon} me-2 mt-auto mb-auto`}/>
            </div>
            <Collapse auto={true} ref={c => this.collapse = c} toggle={false}>
                <div className={'table-responsive'}>
                    <table className={`${styles.table} mt-3`}>
                        <thead>
                        <tr>
                            <th/>
                            <th colSpan={3}>Sannsynlighet</th>
                        </tr>
                        <tr>
                            <th>Konsekvens</th>
                            <th>Lav</th>
                            <th>Medium</th>
                            <th>Høy</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Lav</td>
                            {this.td(RiskType.low, onChange, readOnly)}
                            {this.td(RiskType.low, onChange, readOnly)}
                            {this.td(RiskType.mid, onChange, readOnly)}
                        </tr>
                        <tr>
                            <td>Medium</td>
                            {this.td(RiskType.low, onChange, readOnly)}
                            {this.td(RiskType.mid, onChange, readOnly)}
                            {this.td(RiskType.high, onChange, readOnly)}
                        </tr>
                        <tr>
                            <td>Høy</td>
                            {this.td(RiskType.mid, onChange, readOnly)}
                            {this.td(RiskType.high, onChange, readOnly)}
                            {this.td(RiskType.high, onChange, readOnly)}
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Collapse>
        </>);
    }

    /** */
    private td(riskType: RiskType, onChange: (type: RiskType) => void, readOnly?: boolean): JSX.Element {
        let text: string;
        let style: string;
        switch (riskType) {
            case RiskType.low:
                text = 'OK';
                style = styles.success;
                break;
            case RiskType.mid:
                text = 'Tenk...';
                style = styles.warning;
                break;
            case RiskType.high:
                text = 'STOPP';
                style = styles.danger;
                break;
        }

        return (<td className={style} onClick={() => {
            if (!readOnly) {
                onChange(riskType);
            }
            this.collapse?.hide();
        }}>{text}</td>);
    }
}
