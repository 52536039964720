import type {ReactNode} from 'react';
import React from 'react';
import styles from '../style/ReportField.module.scss';

interface Props {
    update: () => Promise<void>;
    reset: () => void;
    title: string;
    displayText: string;
    closed: boolean;
    html?: boolean;
    children: JSX.Element;
}

interface State {
    editing: boolean;
}

/** */
export class ReportField extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        this.state = {editing: false};
        this.save = this.save.bind(this);
        this.reset = this.reset.bind(this);
    }

    public override render(): ReactNode {
        const {editing} = this.state;
        const {displayText, title, html, children, closed} = this.props;

        return (
            <div>
                <div className={'d-flex justify-content-between'}>
                    <p className={'form-label'}>{title}</p>
                    {closed ? null : <>
                        {editing ?
                            <span>
                        <i className={`fa-light fa-save active me-2 ${styles.effect}`} onClick={this.save}/>
                        <i className={`fa-light fa-times ${styles.effect}`} onClick={this.reset}/>
                    </span>
                            :
                            <i className={`fa-light fa-edit ${styles.effect}`}
                               onClick={() => this.setState({editing: true})}/>
                        }
                    </>}
                </div>
                {editing ?
                    children
                    :
                    html ?
                        <p className={'bg-dark rounded ps-3 pe-3 pt-1 pb-1'}
                           dangerouslySetInnerHTML={{__html: displayText}}/>
                        :
                        <p className={'bg-dark rounded ps-3 pe-3 pt-1 pb-1'}>{displayText}</p>
                }
            </div>
        );
    }

    /** */
    private async save(): Promise<void> {
        const {update} = this.props;
        await update();
        this.setState({editing: false});
    }

    /** */
    private reset(): void {
        const {reset} = this.props;
        reset();
        this.setState({editing: false});
    }
}
