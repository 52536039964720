/** Measures for a report */
import type {ProxyFile} from '@hapro/template-react';

export interface Measure {
    id: number;
    reportId: number;
    createdBy: number;
    createdDisplayName: string;
    title: string;
    deadline: Date;
    responsible: number;
    responsibleDisplayName: string;
    description: string;
    performedBy: number | null;
    performedDisplayName: string;
    performed: Date | null;
    comment: string | null;
    status: string;
    delay: Date | null;
    effectiveDeadline: Date;
    files: ProxyFile[];
}

/** */
export function defaultMeasure(): Measure {
    return {
        comment: null,
        createdBy: 0,
        createdDisplayName: '',
        deadline: new Date(),
        description: '',
        id: 0,
        performed: null,
        performedBy: null,
        performedDisplayName: '',
        reportId: 0,
        responsible: 0,
        responsibleDisplayName: '',
        status: 'Ikke startet',
        title: '',
        delay: null,
        effectiveDeadline: new Date(),
        files: [],
    };
}
