import type {ExternalUserBase} from '../bases/ExternalUserBase';

/** */
export interface ProtectionExternalUser extends ExternalUserBase {
    protectionId: number;
}

/** */
export function newProtectionExternalUser(): ProtectionExternalUser {
    return {id: 0, email: null, name: '', organization: null, phone: null, protectionId: 0};
}
