import {Acl, Authorized, SpinnerHelper, Tooltip} from '@hapro/template-react';
import type {ActionEventArgs, GridModel} from '@syncfusion/ej2-grids';
import {Edit, GridComponent, Page, Toolbar} from '@syncfusion/ej2-react-grids';
import {Inject} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import type {FieldBase} from '../bases/FieldBase';
import type {Department} from '../entities/Department';
import type {Location} from '../entities/Location';
import type {SubLocation} from '../entities/SubLocation';
import {aclAdmin} from '../services/aclStores';
import {AdminService} from '../services/AdminService';
import {ReportService} from '../services/ReportService';

interface CUD {
    createUpdate: (text: string, old?: string) => Promise<number>;
    delete: (id: number) => Promise<boolean>;
    refresh: () => Promise<void>;
}

interface Props {

}

interface State {
    loading: boolean;
    locations: Location[];
    subLocations: SubLocation[];
    departments: Department[];
}

export class Admin extends React.Component<Props, State> {

    /** Base for department and category models */
    private model: GridModel = {
        columns: [
            {field: 'text', headerText: 'Navn'},
        ],
        toolbar: ['Add', 'Edit', 'Delete', 'Search'],
        allowPaging: true,
        allowSorting: true,
        pageSettings: {
            pageSize: 10,
        },
        editSettings: {
            allowEditing: true,
            allowDeleting: true,
            allowAdding: true,
            showDeleteConfirmDialog: true,
            mode: 'Dialog',
        },
    };

    public constructor(props: Props) {
        super(props);
        this.state = {loading: true, locations: [], departments: [], subLocations: []};
        this.handleAction = this.handleAction.bind(this);
        this.handleActionLocation = this.handleActionLocation.bind(this);
        this.handleActionDepartment = this.handleActionDepartment.bind(this);
        this.handleActionSubLocation = this.handleActionSubLocation.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const locations = await ReportService.getLocations();
        const subLocations = await ReportService.getSubLocations();
        const departments = await ReportService.getDepartments();
        this.setState({locations, subLocations, departments, loading: false});
    }

    public override render(): ReactNode {
        const {loading, locations, subLocations, departments} = this.state;

        return (
            <section className={'container mt-5'}>
                <h1>Admin</h1>
                <hr/>

                <Authorized loading={loading} aclStore={aclAdmin} acl={Acl.erase}>
                    <div className={'card'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Avdelinger og Lokasjoner</h5>
                        </div>
                        <Tooltip tag={'div'} className={'card-body'}
                                 title={'Når en lokasjon eller avdeling slettes, vil alle hendelsesrapporter med denne verdien falle tilbake til standardverdi (\'Uspesifisert\')'}>
                            <p className={'form-label'}>Lokasjoner</p>
                            <GridComponent {...this.model} dataSource={locations}
                                           actionBegin={this.handleActionLocation}>
                                <Inject services={[Edit, Toolbar, Page]}/>
                            </GridComponent>

                            <p className={'form-label mt-3'}>Steder</p>
                            <GridComponent {...this.model} dataSource={subLocations}
                                           actionBegin={this.handleActionSubLocation}>
                                <Inject services={[Edit, Toolbar, Page]}/>
                            </GridComponent>

                            <p className={'form-label mt-3'}>Avdelinger</p>
                            <GridComponent {...this.model} dataSource={departments}
                                           actionBegin={this.handleActionDepartment}>
                                <Inject services={[Edit, Toolbar, Page]}/>
                            </GridComponent>
                        </Tooltip>
                    </div>
                </Authorized>
            </section>
        );
    }

    /** */
    public async handleAction(e: ActionEventArgs, cud: CUD): Promise<void> {
        switch (e.requestType) {
            case 'save':
                const field = e.data as FieldBase;
                const old = e.previousData as FieldBase | undefined;
                switch (e.action) {
                    case 'add':
                    case 'edit':
                        SpinnerHelper.block();
                        const createdId = await cud.createUpdate(field.text, old?.text);
                        if (createdId) {
                            await cud.refresh();
                        } else {
                            e.cancel = true;
                        }
                        SpinnerHelper.unblock();
                        break;
                }
                break;
            case 'delete':
                SpinnerHelper.block();
                const toDelete = (e.data as Location[])[0];
                const success = await cud.delete(toDelete.id);
                e.cancel = !success;
                SpinnerHelper.unblock();
                break;
        }
    }

    /** */
    public async handleActionSubLocation(e: ActionEventArgs): Promise<void> {
        const cud: CUD = {
            createUpdate: async (text, old) => await AdminService.putSubLocation(text, old),
            delete: async (id) => await AdminService.deleteSubLocation(id),
            refresh: async () => {
                const subLocations = await ReportService.getSubLocations();
                this.setState({subLocations});
            },
        };
        await this.handleAction(e, cud);
    }

    /** */
    public async handleActionLocation(e: ActionEventArgs): Promise<void> {
        const cud: CUD = {
            createUpdate: async (text, old) => await AdminService.putLocation(text, old),
            delete: async (id) => await AdminService.deleteLocation(id),
            refresh: async () => {
                const locations = await ReportService.getLocations();
                this.setState({locations});
            },
        };
        await this.handleAction(e, cud);
    }

    /** */
    public async handleActionDepartment(e: ActionEventArgs): Promise<void> {
        const cud: CUD = {
            createUpdate: async (text, old) => await AdminService.putDepartment(text, old),
            delete: async (id) => await AdminService.deleteDepartment(id),
            refresh: async () => {
                const departments = await ReportService.getDepartments();
                this.setState({departments});
            },
        };
        await this.handleAction(e, cud);
    }
}
