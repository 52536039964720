import {TemplateHelper} from '@hapro/template-react';

/** */
export interface MeasureNew {
    reportId: number;
    title: string;
    deadline: Date;
    responsible: number | null;
    description: string;
}

/** */
export function defaultMeasureNew(reportId?: number): MeasureNew {
    return {
        reportId: reportId ?? 0,
        deadline: TemplateHelper.addDays(new Date(), 14),
        description: '',
        responsible: null,
        title: '',
    };
}
