import {Acl, SpinnerHelper, TemplateHelper} from '@hapro/template-react';
import type {ActionEventArgs, RecordDoubleClickEventArgs} from '@syncfusion/ej2-grids';
import {
    ActionArgs,
    ColumnChooser,
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    ExcelExport,
    GridComponent,
    Group,
    Inject,
    Page,
    Search,
    Sort,
    SortDescriptorModel,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import React, {createRef, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {fieldAccessor} from '../bases/FieldBase';
import type {Report} from '../entities/Report';
import {CategoryFormatter, closedAccessor, ClosedFormatter, ReportHelper} from '../helpers/ReportHelper';
import {aclAdmin} from '../services/aclStores';
import {AdminService} from '../services/AdminService';
import {ReportManager} from '../services/ReportStore';
import {configStore} from '../services/stores';

interface Props {
    reports: Report[];
}

export function ReportTable(props: Props): JSX.Element {
    const {reports} = props;

    const [acl, setAcl] = useState<Acl>(aclAdmin.value);
    useEffect(() => {
        const unsub = aclAdmin.subscribe(value => setAcl(value));
        return aclAdmin.unsubscribe(unsub);
    }, []);

    const canDelete = acl >= Acl.erase;
    const config = configStore.value;
    const grid = createRef<GridComponent>();
    const navigate = useNavigate();

    /** */
    async function handleDoubleClick(args: RecordDoubleClickEventArgs): Promise<void> {
        const report = args.rowData as Report;
        await ReportManager.updateReport(report.id);
        navigate(`/report/${report.id}`);
    }

    /** */
    function handleToolbarClick(args: any): void {
        switch (args.item.text) {
            case 'Excel Export':
                grid.current?.excelExport();
                break;
            case 'CSV Export':
                grid.current?.csvExport();
                break;
        }
    }

    /** Store table config in local store */
    function actionComplete(e: ActionArgs) {
        switch (e.requestType) {
            case 'columnstate':
                const visible: string[] = grid.current?.getVisibleColumns()?.map(c => c.field) ?? [];
                configStore.value = ({...configStore.value, visible});
                break;
            case 'sorting':
                const sorting: SortDescriptorModel[] = grid.current?.sortSettings.columns
                    ?.map(c => ({field: c.field, direction: c.direction, isFromGroup: c.isFromGroup})) ?? [];
                configStore.value = ({...configStore.value, sorting});
                break;
            case 'grouping':
            case 'ungrouping':
                const grouping: string[] = grid.current?.groupSettings.columns ?? [];
                configStore.value = ({...configStore.value, grouping});
                break;
        }
    }

    /** */
    async function actionBegin(args: ActionEventArgs): Promise<void> {
        if (args.requestType === 'delete' && args.data?.[0]) {
            SpinnerHelper.block();
            const report = args.data[0] as Report;
            const success = await AdminService.deleteReport(report.id);
            args.cancel = !success;
            SpinnerHelper.unblock();
        }
    }

    return (
        <>
            <GridComponent dataSource={reports} ref={grid}
                           editSettings={{allowDeleting: canDelete, showDeleteConfirmDialog: true}}
                           actionBegin={actionBegin}
                           allowGrouping={true} allowSorting={true}
                           allowExcelExport={true} allowPaging={true} pageSettings={{pageSize: 20}}
                           sortSettings={{columns: config.sorting}}
                           groupSettings={{columns: config.grouping}}
                           showColumnChooser={true}
                           toolbar={['ExcelExport', 'CsvExport', 'Search', 'ColumnChooser']}
                           searchSettings={{
                               fields: [
                                   'createdDisplayName',
                                   'responsibleDisplayName',
                                   'department.text',
                                   'location.text',
                                   'subLocation.text',
                                   'title',
                                   'id',
                               ],
                           }} recordDoubleClick={handleDoubleClick} actionComplete={actionComplete}
                           toolbarClick={handleToolbarClick} allowTextWrap={true}>
                <ColumnsDirective>
                    <ColumnDirective field={'id'} headerText={'Id'} width={'5rem'}
                                     allowGrouping={false} visible={config.visible.includes('id')}/>

                    <ColumnDirective field={'category'} headerText={'Kategori'}
                                     valueAccessor={ReportHelper.categoryAccessor} width={'7rem'}
                                     formatter={CategoryFormatter} disableHtmlEncode={false}
                                     visible={config.visible.includes('category')}/>

                    <ColumnDirective field={'type'} headerText={'Type'} width={'8rem'}
                                     visible={config.visible.includes('type')}/>

                    <ColumnDirective field={'title'} headerText={'Tittel'} width={'10rem'}
                                     visible={config.visible.includes('title')}/>

                    <ColumnDirective field={'date'} headerText={'Dato'} width={'7rem'}
                                     format={TemplateHelper.date} type={'Date'}
                                     allowGrouping={false}
                                     visible={config.visible.includes('date')}/>

                    <ColumnDirective field={'createdDisplayName'} headerText={'Registrert av'}
                                     width={'9rem'}
                                     visible={config.visible.includes('createdDisplayName')}/>

                    <ColumnDirective field={'responsibleDisplayName'} headerText={'Ansvarlig'}
                                     width={'9rem'}
                                     visible={config.visible.includes('responsibleDisplayName')}/>

                    <ColumnDirective field={'created'} headerText={'Opprettet'} width={'7rem'}
                                     format={TemplateHelper.date} type={'Date'}
                                     allowGrouping={false}
                                     visible={config.visible.includes('created')}/>

                    <ColumnDirective field={'closedCommentId'} headerText={'Lukket'} width={'6rem'}
                                     valueAccessor={closedAccessor} disableHtmlEncode={false}
                                     formatter={ClosedFormatter} allowGrouping={false}
                                     visible={config.visible.includes('closedCommentId')}/>

                    <ColumnDirective field={'department.text'} headerText={'Avdeling'}
                                     width={'8rem'} valueAccessor={fieldAccessor}
                                     visible={config.visible.includes('department.text')}/>

                    <ColumnDirective field={'location.text'} headerText={'Lokasjon'}
                                     width={'8rem'} valueAccessor={fieldAccessor}
                                     visible={config.visible.includes('location.text')}/>

                    <ColumnDirective field={'subLocation.text'} headerText={'Sted'} width={'8rem'}
                                     valueAccessor={fieldAccessor}
                                     visible={config.visible.includes('subLocation.text')}/>

                    <ColumnDirective field={'units.length'} headerText={'Artikkler'}
                                     width={'6rem'} visible={config.visible.includes('units.length')}/>

                    <ColumnDirective headerText={'Slett'} visible={canDelete} width={'5rem'}
                                     commands={[
                                         {
                                             type: 'Delete',
                                             buttonOption: {
                                                 cssClass: 'e-flat',
                                                 iconCss: 'fa-solid fa-trash text-danger',
                                             },
                                         }]}/>
                </ColumnsDirective>
                <Inject
                    services={[Group, Sort, ExcelExport, Search, Toolbar, ColumnChooser, Edit, CommandColumn, Page]}/>
            </GridComponent>
        </>
    );
}
