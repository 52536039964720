import type {BulletPoint} from './BulletPoint';

/** Groups of BulletPoints for Protections */
export interface BulletPointGroup {
    id: number;
    order: number;
    protectionId: number;
    label: string;
    bulletPoints: BulletPoint[];
}

/** */
export function defaultBulletPointGroup(): BulletPointGroup {
    return {
        bulletPoints: [],
        id: 0,
        label: '',
        order: 0,
        protectionId: 0,
    };
}
