import {
    Acl,
    Authorized,
    FileForm,
    Modal,
    MultiAuthUser,
    SpinnerHelper,
    StdFormat,
    TemplateHelper,
    Tooltip,
    userStore,
} from '@hapro/template-react';
import type {Subscribe} from '@mathias_frost/simplestores';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ReactNode} from 'react';
import React from 'react';
import {Link, NavigateFunction, useNavigate, useSearchParams} from 'react-router-dom';
import {TaskProgress} from '../../components/TaskProgress';
import type {Activity} from '../../entities/Activity';
import type {Analysis} from '../../entities/Analysis';
import {defaultAnalysis} from '../../entities/Analysis';
import {TaskHelper} from '../../helpers/TaskHelper';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';

interface Props {
    params: URLSearchParams;
    navigate: NavigateFunction;
}

interface State {
    loading: boolean;
    selected: Activity | null;
    analysis: Analysis;
    userId: number;
}

class Internal extends React.Component<Props, State> {

    /** */
    private modal: Modal | null = null;

    /** */
    private fileModal: Modal | null = null;

    /** */
    private unsubUser: Subscribe<MultiAuthUser | null> | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {analysis: defaultAnalysis(), loading: true, selected: null, userId: 0};
        this.print = this.print.bind(this);
        this.select = this.select.bind(this);
        this.approve = this.approve.bind(this);
        this.saveEvaluation = this.saveEvaluation.bind(this);
    }

    /** @inheritDoc */
    public async componentDidMount(): Promise<void> {
        const {params, navigate} = this.props;
        const id = Number(params.get('id'));
        if (!params.has('id') || isNaN(id)) {
            navigate('/analysis', {replace: true});
            return;
        }
        this.unsubUser = userStore.subscribe(value => this.setState({userId: value?.userMinimal?.id ?? 0}));

        const analysis = await ReportService.getAnalysis(id);
        this.setState({analysis, loading: false});
    }

    /** @inheritDoc */
    public componentWillUnmount(): void {
        userStore.unsubscribe(this.unsubUser);
    }

    public override render(): ReactNode {
        const {loading, analysis, selected, userId} = this.state;
        const {params} = this.props;

        const id = params.get('id');
        const canManage = TaskHelper.canManageAnalysis(analysis, userStore.value?.userMinimal?.id);

        return (
            <section className={'container mt-5'}>
                <h1>Sikker jobbanalyse - Rapport #{id}</h1>
                <hr/>
                <Authorized aclStore={aclReport} acl={Acl.create} loading={loading}>
                    <TaskProgress step={3} id={id} type={'analysis'}/>

                    {/* Overview */}
                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Oppsummering</h5>
                        </div>
                        <div className={'card-body table-responsive'}>
                            <table className={'table table-dark mt-3'}>
                                <tbody>
                                <tr>
                                    <td colSpan={2}><b>Dato:</b> {TemplateHelper.toStdDate(analysis.date,
                                        StdFormat.date)}</td>
                                    <td colSpan={2}><b>Firma:</b> Hapro Electronics AS</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}/>
                                    <td><b>Mobilnr.</b></td>
                                    <td><b>Godkjent?</b></td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><b>Opprettet av: </b>
                                        <a target={'_blank'} rel={'noreferrer'}
                                           href={`${process.env.REACT_APP_ANSATT_URL}/main.php#!/list/*/personal/${analysis.createdAnr}`}>
                                            {analysis.createdDisplayName}</a>
                                    </td>
                                    <td>{analysis.createdCell}</td>
                                    <td>
                                        <i className={`fa-regular fa-square-check text-success${analysis.createdApproved ?
                                            'fa-square-check text-success' :
                                            'fa-square-minus text-muted'}`}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}><b>Ansvarlig: </b>
                                        <a target={'_blank'} rel={'noreferrer'}
                                           href={`${process.env.REACT_APP_ANSATT_URL}/main.php#!/list/*/personal/${analysis.responsibleAnr}`}>
                                            {analysis.responsibleDisplayName}</a>
                                    </td>
                                    <td>{analysis.responsibleCell}</td>
                                    <td>
                                        <i className={`fa-regular fa-square-check text-success${analysis.responsibleApproved ?
                                            'fa-square-check text-success' :
                                            'fa-square-minus text-muted'}`}/>
                                    </td>
                                </tr>
                                {analysis.participants.length ? <>
                                    <tr>
                                        <td colSpan={4}><b>Deltakere i SJA'en:</b></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}><b>Navn</b></td>
                                    </tr>
                                </> : null}
                                {analysis.participants.map((participant, i) =>
                                    <tr key={i}>
                                        <td colSpan={4}>{participant.displayName}</td>
                                    </tr>,
                                )}
                                {analysis.externalUsers.length ? <>
                                    <tr>
                                        <td colSpan={4}><b>Eksterne i SJA'en:</b></td>
                                    </tr>
                                    <tr>
                                        <td><b>Navn</b></td>
                                        <td><b>Email</b></td>
                                        <td><b>Mobilnr.</b></td>
                                        <td><b>Organisasjon</b></td>
                                    </tr>
                                </> : null}
                                {analysis.externalUsers.map((user, i) =>
                                    <tr key={i}>
                                        <td>{user.name}</td>
                                        {user.email
                                            ?
                                            <td>
                                                <a href={`mailto:${user.email}`}>{user.email}</a>
                                            </td>
                                            :
                                            <td/>
                                        }
                                        <td>{user.phone}</td>
                                        <td>{user.organization}</td>
                                    </tr>,
                                )}
                                <tr>
                                    <td colSpan={2}><b>Avdeling:</b> {analysis.department?.text ?? 'Uspesifisert'}</td>
                                    <td colSpan={2}><b>Lokasjon:</b> {analysis.location?.text ?? 'Uspesifisert'}</td>
                                </tr>
                                <tr>
                                    <td colSpan={4}><b>Prosjekt/Referanse:</b> {analysis.project}</td>
                                </tr>
                                <tr>
                                    <td colSpan={4}><b>Beskrivelse:</b> {analysis.description}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* Activities */}
                    {analysis.activities.length ?
                        <div className={'card mt-3'}>
                            <div className={'card-header'}>
                                <h5 className={'card-title'}>Aktiviteter</h5>
                            </div>
                            <div className={'card-body table-responsive'}>
                                <table className={'table table-dark'}>
                                    <thead>
                                    <tr>
                                        <th>Aktivitet</th>
                                        <th>Beskrivelse</th>
                                        <th>Risiko før tiltak</th>
                                        <th>Tiltak</th>
                                        <th>Ansvarlig</th>
                                        <th>Tiltak gjennomført?</th>
                                        <th>Risiko etter tiltak</th>
                                        <th>Filer</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {analysis.activities.map((activity, i) =>
                                        <tr key={i}>
                                            <td>{activity.label}</td>
                                            <td>{activity.description}</td>
                                            {activity.riskBefore === null ?
                                                <td className={'text-muted'}>--</td>
                                                :
                                                <td><i className={`${TaskHelper.riskIcons.get(
                                                    activity.riskBefore)} me-2`}/></td>
                                            }
                                            {activity.measure ?
                                                <td>{activity.measure}</td>
                                                :
                                                <td className={'text-muted'}>--</td>
                                            }
                                            {activity.responsibleDisplayName ?
                                                <td>{activity.responsibleDisplayName}</td>
                                                :
                                                <td className={'text-muted'}>--</td>
                                            }
                                            <td><i className={`fa-regular ${activity.completed
                                                ? 'fa-square-check text-success'
                                                : 'fa-square-minus text-muted'}`}/></td>
                                            {activity.riskAfter === null ?
                                                <td className={'text-muted'}>--</td>
                                                :
                                                <td><i className={`${TaskHelper.riskIcons.get(
                                                    activity.riskAfter)} me-2`}/></td>
                                            }
                                            <td className={'pointer'}
                                                onClick={() => this.select(activity)}>{activity.files.length}</td>
                                        </tr>,
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : null}

                    {/* Files */}
                    <Modal ref={m => this.fileModal = m} animation={true} centered={true} size={'xl'}
                           onHidden={() => this.setState({selected: null})}>
                        <div className={'modal-header'}>
                            <h5 className={'modal-title'}>Filer for aktivitet: <b>{selected?.label}</b></h5>
                        </div>
                        <div className={'modal-body'}>
                            {selected ?
                                <FileForm files={selected.files} noUpload={true} noRemove={true}
                                          download={`/V1/Report/ActivityFile/${analysis.id}/${selected.id}`}
                                          upload={`/V1/Report/ActivityFile/${analysis.id}/${selected.id}`}
                                          remove={`/V1/Report/ActivityFile/${analysis.id}/${selected.id}`}/>
                                : null}
                        </div>
                    </Modal>

                    {/* Discussion */}
                    <div className={'card mt-3'}>
                        <div className={'card-header'}>
                            <h5 className={'card-title'}>Evaluering <em>(etter at arbeidet er utført)</em></h5>
                        </div>
                        <div className={'card-body'}>
                            <TextBoxComponent multiline={true} value={analysis.evaluation} readonly={!canManage}
                                              change={e => analysis.evaluation = e.value}/>
                            {canManage ?
                                <button className={'btn btn-success mt-3 float-end'} onClick={this.saveEvaluation}>
                                    <i className={'fa-solid fa-save me-2'}/>Lagre
                                </button>
                                : null}
                        </div>
                        <div className={'card-footer d-flex justify-content-between'}>
                            <Link to={`/analysis/execute?id=${id}`} className={'btn btn-primary'}>
                                <i className={'fa-solid fa-fast-backward me-2'}/>Forrige
                            </Link>
                            <div className={'d-flex gap-3'}>
                                {(userId === analysis.responsible
                                    && !analysis.responsibleApproved
                                    && analysis.createdApproved)
                                // If user is creator and responsible skip "Meld inn" part
                                || (userId === analysis.createdBy && userId === analysis.responsible) ?
                                    <button className={'btn btn-secondary'}
                                            onClick={() => this.modal?.show()}>
                                        <i className={'fa-regular fa-circle-check text-success me-2'}/>Godkjenn
                                    </button>
                                    : userId === analysis.createdBy && !analysis.createdApproved ?
                                        <Tooltip title={'Melder inn til ansvarlig at SJA er klar for godkjenning'}>
                                            <button className={'btn btn-secondary'} onClick={this.approve}>
                                                <i className={'fa-regular fa-envelope text-success me-2'}/>Meld inn
                                            </button>
                                        </Tooltip>
                                        : null}
                                <button className={'btn btn-info'} onClick={this.print}>
                                    <i className={'fa-solid fa-print me-2'}/>Skriv ut
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Approval */}
                    <Modal ref={m => this.modal = m} animation={true} centered={true}>
                        <div className={'modal-header'}>
                            <h5 className={'modal-title'}>Godkjenn SJA: <b>{analysis.title}</b></h5>
                        </div>
                        <div className={'modal-body'}>
                            <p>Sikker på at du vil godkjenne?</p>
                        </div>
                        <div className={'modal-footer'}>
                            <button className={'btn btn-warning'} onClick={() => this.modal?.hide()}>
                                <i className={'fa-thin fa-times me-2'}/>Avvent
                            </button>
                            <button className={'btn btn-secondary'} onClick={this.approve}>
                                <i className={'fa-thin fa-circle-check text-success me-2'}/>Godkjenn
                            </button>
                        </div>
                    </Modal>

                </Authorized>
            </section>
        );
    }

    /** */
    private async print(): Promise<void> {
        const {analysis} = this.state;
        SpinnerHelper.show();
        await ReportService.getAnalysisPdf(analysis.id);
        SpinnerHelper.hide();
    }

    /** */
    private async approve(): Promise<void> {
        SpinnerHelper.block();
        const {analysis} = this.state;
        const {params} = this.props;
        const id = Number(params.get('id'));
        const success = await ReportService.patchApproveAnalysis(analysis.id);
        if (success && id) {
            const analysis = await ReportService.getAnalysis(id);
            this.setState({analysis}, () => this.modal?.hide());
        }
        SpinnerHelper.unblock();
    }

    /** */
    private select(activity: Activity): void {
        this.setState({selected: activity}, () => this.fileModal?.show());
    }

    /** */
    private async saveEvaluation(): Promise<void> {
        SpinnerHelper.show();
        const {analysis} = this.state;
        const success = await ReportService.patchEvaluation(analysis.id, analysis.evaluation);
        if (success) {
            this.setState({analysis});
        }
        SpinnerHelper.hide();
    }
}

/** */
export function Report() {
    const [params] = useSearchParams();
    const navigate = useNavigate();
    return (<Internal params={params} navigate={navigate}/>);
}

