import {StdFormat, TemplateHelper, Tooltip, Verbosity} from '@hapro/template-react';
import type {DateRange} from '@syncfusion/ej2-calendars';
import type {RenderDayCellEventArgs} from '@syncfusion/ej2-react-calendars';

/** Helpful functions pertaining to dates */
export class DateHelper {

    /** @returns One month back from now */
    public static oneMonth(): DateRange {
        const now = new Date();
        let dateRange: DateRange = {};
        dateRange.end = new Date(now.getFullYear(), now.getMonth(),
            now.getDate());
        let year: number = now.getFullYear();
        let month: number = now.getMonth() - 1;
        // If we are in January, go back one year
        if (month === -1) {
            year -= 1;
            month = 11;
        }
        dateRange.start = new Date(year, month, now.getDate());
        return dateRange;
    }

    /** Invalid dates for Syncfusion date picker */
    public static pastDates(args: RenderDayCellEventArgs) {
        if (args.date instanceof Date && args.date >= new Date()) {
            args.isDisabled = true;
        }
    }

    /** Says from current time to specified date. Negative for dates in the past */
    public static daysFromNow(date: Date | null): number {
        if (!date) {
            return 0;
        }

        const now = new Date();
        const diff = date.getTime() - now.getTime();
        return Math.round(diff / 1_000 / 60 / 60 / 24);
    }

    /** @return {string} Days until or from now in a pretty string */
    public static daysFromOrUntilNow(date: Date | null): string {
        let days = this.daysFromNow(date);

        if (days === 0) {
            return 'I dag';
        }

        const past = days < 0;
        days = Math.abs(days);

        // Calc years
        let years = 0;
        while (days >= 365) {
            days -= 365;
            years++;
        }

        // Build string
        let result = '';
        if (years !== 0) {
            result += `${years} år`;
        }
        if (days !== 0) {
            if (result !== '') result += ' og ';
            result += `${days} dag${(days > 1 ? 'er' : '')}`;
        }

        return `${result} ${past ? 'siden' : 'til'}`;
    }

    /** Verbosity based on how long it has been since specified date
     * @param date Date to check
     * @param completed If true, will set verbosity to success */
    public static deadlineVerbosity(date: Date | null, completed?: boolean): Verbosity {
        if (completed) {
            return 'success';
        }

        const days = this.daysFromNow(date);

        if (days >= 7) {
            return 'info';
        } else if (days >= 0) {
            return 'warning';
        } else {
            return 'error';
        }
    }

    /** */
    public static deadlineTemplate(date: Date | null, completed: boolean): JSX.Element {
        const string = DateHelper.daysFromOrUntilNow(date);
        let verbosity = this.deadlineVerbosity(date, completed);

        let bootstrap: string;
        switch (verbosity) {
            case 'success':
                bootstrap = 'text-success';
                break;
            case 'info':
                bootstrap = 'text-white';
                break;
            case 'warning':
                bootstrap = 'text-warning';
                break;
            case 'error':
                bootstrap = 'text-danger';
                break;
        }

        return (
            <Tooltip title={string} className={'text-wrap'}>
                <span className={bootstrap}>{TemplateHelper.toStdDate(date, StdFormat.date)}</span>
            </Tooltip>
        );
    }
}
