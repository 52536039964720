/** */
export interface Unit {
    id: number;
    reportId: number;
    article: string;
    batch: number;
    serial: string;
}

/** */
export function defaultUnit(): Unit {
    return {article: '', batch: 0, id: 0, reportId: 0, serial: ''};
}
