import {Acl, Authorized} from '@hapro/template-react';
import React, {useEffect, useState} from 'react';
import {ReportTable} from '../components/ReportTable';
import type {Report} from '../entities/Report';
import {aclReport} from '../services/aclStores';
import {ReportService} from '../services/ReportService';

/** */
export function Improvements(): JSX.Element {

    const [reports, setReports] = useState<Report[] | null>(null);

    useEffect(() => {
        ReportService.getImprovements().then(setReports);
    }, []);

    return (
        <section className={'mt-5'}>
            <div className={'container'}>
                <h1>Forbedringer</h1>
                <hr/>
            </div>
            <div className={'container-fluid'}>
                <Authorized aclStore={aclReport} acl={Acl.read} loading={!reports}>
                    <ReportTable reports={reports ?? []}/>
                </Authorized>
            </div>
        </section>
    );
}
