import {FileForm} from '@hapro/template-react';
import React, {ReactNode} from 'react';
import type {Report} from '../entities/Report';
import {aclReport} from '../services/aclStores';

interface Props {
    report: Report;
    closed: boolean;
}

interface State {

}

/** */
export class Files extends React.Component<Props, State> {

    public override render(): ReactNode {
        const {report, closed} = this.props;

        return (
            <FileForm files={report.files} aclStore={aclReport} noUpload={closed} noRemove={closed}
                      download={`/V1/Report/ReportFile/${report.id}`}
                      upload={`/V1/Report/ReportFile/${report.id}`}
                      remove={`/V1/Report/ReportFile/${report.id}`}/>
        );
    }
}
