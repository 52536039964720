import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import React, {ReactNode} from 'react';
import type {ReportCategory} from '../enums/ReportCategory';
import {ReportHelper} from '../helpers/ReportHelper';

interface Props {
    value: number;
    onChange: (value: ReportCategory) => void;
}

interface State {

}

export class Categories extends React.Component<Props, State> {

    private fields: FieldSettingsModel = {value: 'id', text: 'text'};

    public constructor(props: Props) {
        super(props);
        this.state = {categories: []};
        this.change = this.change.bind(this);
    }

    public override render(): ReactNode {
        const {value} = this.props;

        return <ComboBoxComponent value={value}
                                  dataSource={ReportHelper.reportCategories as any}
                                  fields={this.fields}
                                  filterType={'Contains'}
                                  allowFiltering={true}
                                  ignoreCase={true}
                                  allowCustom={false}
                                  onChange={this.change}/>;
    }

    private change(e: any) {
        this.props.onChange(e.value as ReportCategory);
    }
}
