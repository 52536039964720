import type {UserMinimal} from '@hapro/template-react';
import type {ActionEventArgs} from '@syncfusion/ej2-grids';
import {ComboBoxComponent, FieldSettingsModel} from '@syncfusion/ej2-react-dropdowns';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    GridComponent,
    Inject,
    Sort,
    Toolbar,
} from '@syncfusion/ej2-react-grids';
import type {ReactNode} from 'react';
import React from 'react';
import type {ExternalUserBase} from '../bases/ExternalUserBase';
import type {TaskNewBase} from '../bases/TaskNewBase';
import {ReportService} from '../services/ReportService';

interface Props {
    change: (value: number[]) => void;
    changeExternal: (value: ExternalUserBase[]) => void;
    taskForm: TaskNewBase;
    externalUserNew: (user: ExternalUserBase) => ExternalUserBase;
    readOnly?: boolean;
}

interface State {
    users: UserMinimal[];
}

/** */
export class TaskParticipants extends React.Component<Props, State> {

    /** */
    private fields: FieldSettingsModel = {value: 'id', text: 'displayName', groupBy: 'departmentName'};

    /** */
    private comboBox: ComboBoxComponent | null = null;

    /** */
    private gridExternal: GridComponent | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {users: []};
        this.change = this.change.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
        this.actionBeginExternal = this.actionBeginExternal.bind(this);
    }

    /** @inheritDoc */
    public override async componentDidMount(): Promise<void> {
        const users = await ReportService.getUsers();
        this.setState({users});
    }

    public override render(): ReactNode {
        const {users} = this.state;
        const {taskForm, readOnly} = this.props;

        return (
            <div className={'d-flex flex-column gap-3'}>
                <div>
                    <p className={'form-label'}>Deltakere</p>
                    {readOnly ? null :
                        <ComboBoxComponent ref={c => this.comboBox = c}
                                           dataSource={users.filter(u => !taskForm.participants.includes(u.id)) as any}
                                           noRecordsTemplate={'Fant ingen ansatte'}
                                           fields={this.fields}
                                           filterType={'Contains'}
                                           allowFiltering={true}
                                           ignoreCase={true}
                                           allowCustom={false}
                                           placeholder={'Legg til'}
                                           onChange={this.change}/>
                    }
                </div>
                <div>
                    <GridComponent dataSource={users.filter(u => taskForm.participants.includes(u.id))}
                                   editSettings={{allowDeleting: !readOnly}}
                                   actionBegin={this.actionBegin} allowSorting={true}>
                        <ColumnsDirective>
                            <ColumnDirective field={'displayName'} headerText={'Navn'} width={'9rem'}/>
                            <ColumnDirective field={'departmentDisplayName'} headerText={'Avdeling'} width={'9rem'}/>
                            <ColumnDirective headerText={'Fjern'} width={'4rem'} visible={!readOnly} commands={[
                                {
                                    type: 'Delete',
                                    buttonOption: {cssClass: 'e-flat', iconCss: 'fa-solid fa-trash'},
                                }]}/>
                        </ColumnsDirective>
                        <Inject services={[CommandColumn, Sort]}/>
                    </GridComponent>
                </div>
                <div>
                    <p className={'form-label'}>Eksterne</p>
                    <GridComponent ref={g => this.gridExternal = g}
                                   dataSource={taskForm.externalUsers} toolbar={['Add']}
                                   editSettings={{
                                       mode: 'Dialog',
                                       allowDeleting: !readOnly,
                                       allowEditing: !readOnly,
                                       allowAdding: !readOnly,
                                   }} actionBegin={this.actionBeginExternal} allowSorting={true}>
                        <ColumnsDirective>
                            <ColumnDirective field={'id'} visible={false}/>
                            <ColumnDirective field={'name'} headerText={'Navn'} width={'8rem'}/>
                            <ColumnDirective field={'organization'} headerText={'Organisasjon'} width={'8rem'}/>
                            <ColumnDirective field={'email'} headerText={'Email'} width={'8rem'}/>
                            <ColumnDirective field={'phone'} headerText={'Mobilnr.'} width={'8rem'}/>
                            <ColumnDirective headerText={'Fjern'} width={'6rem'} visible={!readOnly} commands={[
                                {type: 'Delete', buttonOption: {cssClass: 'e-flat', iconCss: 'fa-solid fa-trash'}},
                                {type: 'Save', buttonOption: {cssClass: 'e-flat', iconCss: 'fa-solid fa-check'}},
                                {type: 'Cancel', buttonOption: {cssClass: 'e-flat', iconCss: 'fa-solid fa-times'}}]}/>
                        </ColumnsDirective>
                        <Inject services={[CommandColumn, Sort, Edit, Toolbar]}/>
                    </GridComponent>
                </div>
            </div>
        );
    }

    /** */
    private actionBegin(args: ActionEventArgs): void {
        if (args.requestType === 'delete' && args.data?.[0]) {
            let {taskForm} = this.props;
            const user = args.data[0] as UserMinimal;
            taskForm.participants = taskForm.participants.filter(uid => uid !== user.id);
            this.setState({}, this.refreshComboBox);
        }
    }

    /** */
    private refreshComboBox(): void {
        if (this.comboBox) {
            this.comboBox.value = 0;
            this.comboBox.refresh();
        }
    }

    /** */
    private change(e: { value: number }): void {
        const {users} = this.state;
        const {taskForm, change} = this.props;
        const user = users.find(u => u.id === e.value);
        if (!user) {
            return;
        }

        taskForm.participants.push(user.id);
        change(users.filter(u => taskForm.participants.includes(u.id)).map(u => u.id));
        this.setState({}, this.refreshComboBox);
    }

    /** */
    private async actionBeginExternal(args: ActionEventArgs): Promise<void> {
        const {taskForm, externalUserNew} = this.props;
        switch (args.requestType) {
            case 'save':
                switch (args.action) {
                    case 'add':
                        const newUser = externalUserNew(args.data as ExternalUserBase);
                        if (newUser.name) {
                            newUser.organization = newUser.organization ? newUser.organization : 'Uspesifisert';
                            newUser.email = newUser.email ? newUser.email : '';
                            newUser.phone = newUser.phone ? newUser.phone : '';
                            newUser.id = Math.max(...taskForm.externalUsers.map(u => u.id)) + 1;
                            taskForm.externalUsers.push(newUser);
                            this.gridExternal?.refresh();
                        }
                        break;
                    case 'edit':
                        const externalUser = args.data as ExternalUserBase;
                        const user = taskForm.externalUsers.find(u => u.id === externalUser.id);
                        if (user) {
                            user.name = externalUser.name;
                            user.organization = externalUser.organization;
                            user.email = externalUser.email;
                            user.phone = externalUser.phone;
                        }
                        break;
                }
                break;
            case 'delete':
                const user = args.data?.[0] as ExternalUserBase | undefined;
                const index = taskForm.externalUsers.findIndex(u => u.id === user?.id);
                if (index !== -1) {
                    taskForm.externalUsers.splice(index, 1);
                } else {
                    args.cancel = true;
                }
                break;
        }
    }
}
