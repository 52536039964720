import {Acl, Authorized, ProxyFile, SpinnerHelper, TemplateHelper, ToastHelper} from '@hapro/template-react';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import {TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import {
    HtmlEditor,
    Inject,
    Link,
    QuickToolbar,
    RichTextEditorComponent,
    Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import React, {ReactNode} from 'react';
import {useSearchParams} from 'react-router-dom';
import {LocationSelect} from '../../components/LocationSelect';
import {Pictures} from '../../components/Pictures';
import {ResponsibleSelect} from '../../components/ResponsibleSelect';
import {SubLocationSelect} from '../../components/SubLocationSelect';
import {TypeSelect} from '../../components/TypeSelect';
import {ReportLength} from '../../entities/Report';
import {ReportCategory} from '../../enums/ReportCategory';
import {DateHelper} from '../../helpers/DateHelper';
import {MiscHelper} from '../../helpers/MiscHelper';
import {ReportHelper} from '../../helpers/ReportHelper';
import type {ReportForm} from '../../models/ReportForm';
import {defaultReportForm} from '../../models/ReportForm';
import {aclReport} from '../../services/aclStores';
import {ReportService} from '../../services/ReportService';
import type {MenuItem} from '../../types/MenuItem';

interface Props {
    params: URLSearchParams;
}

interface State {
    report: ReportForm;
    pictures: ProxyFile[];
}

export class Internal extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);
        const report: ReportForm = defaultReportForm();
        this.state = {report, pictures: []};
        this.submitReport = this.submitReport.bind(this);
        this.updateProxyFiles = this.updateProxyFiles.bind(this);
    }

    public override render(): ReactNode {
        const {report, pictures} = this.state;
        const param = this.props.params.get('category') as MenuItem;
        if (param) {
            switch (param) {
                case 'Avvik':
                    report.category = ReportCategory.deviation;
                    break;
                case 'Skade':
                    report.category = ReportCategory.injury;
                    break;
                case 'SJA':
                    break;
                case 'Tilløp til skade':
                    report.category = ReportCategory.nearInjury;
                    break;
                case 'Vernerunde':
                    break;
                case 'Forbedring':
                    report.category = ReportCategory.improvement;
                    break;
            }
            report.type = TypeSelect.getTypes(report.category)[0];
        }

        return (
            <section className={'container mt-5 mb-3'}>
                <div className={'col-lg-6 col-md-10 col-sm-12 m-auto'}>
                    <Authorized aclStore={aclReport} acl={Acl.create}>

                        <div className={'card shadow border-1'}
                             style={{borderColor: ReportHelper.menuColors(param)}}>
                            <div className={'card-header'}>
                                <h5 className={'card-title'}>Ny Hendelsesrapport <span
                                    className={'text-muted'}>&mdash; {param}</span></h5>
                            </div>
                            <div className={'card-body'}>

                                {/* Type */}
                                <p className={'form-label mt-3'}>Type</p>
                                <TypeSelect value={report.type} category={report.category}
                                            onChange={value => report.type = value}/>

                                {/* Title */}
                                <p className={'form-label mt-3'}>Tittel</p>
                                <TextBoxComponent value={report.title} change={(e) => report.title = e.value}
                                                  htmlAttributes={{maxLength: ReportLength.title.toString()}}
                                                  autocomplete={'off'} placeholder={'Samme som beskrivelse'}/>

                                {/* Responsible */}
                                <p className={'form-label mt-3'}>Ansvarlig</p>
                                <ResponsibleSelect value={report.responsible}
                                                   onChange={e => report.responsible = e?.id ?? 0}/>

                                {/* Date */}
                                <p className={'form-label mt-3'}>Dato</p>
                                <DatePickerComponent value={report.date} strictMode={true}
                                                     change={(e) => report.date = e.value}
                                                     showClearButton={false}
                                                     renderDayCell={DateHelper.pastDates}
                                                     firstDayOfWeek={1} format={TemplateHelper.date}/>

                                {/* Location and SubLocation */}
                                <div className={'row'}>

                                    <div className={'col-md-6 col-sm-12 mt-3'}>
                                        <p className={'form-label'}>Lokasjon</p>
                                        <LocationSelect id={report.locationId}
                                                        onChange={value => report.locationId = value?.id ?? null}/>
                                    </div>
                                    <div className={'col-md-6 col-sm-12 mt-3'}>
                                        <p className={'form-label'}>Sted</p>
                                        <SubLocationSelect text={report.subLocationText}
                                                           onChange={value => report.subLocationText = value.text}/>
                                    </div>
                                </div>

                                {/* Text */}
                                <p className={'form-label mt-3'}>{ReportHelper.descriptionTitle(report.category)}</p>
                                <RichTextEditorComponent value={report.text} height={200}
                                                         change={(e) => report.text = e.value}
                                                         toolbarSettings={MiscHelper.rteSettings}>
                                    <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]}/>
                                </RichTextEditorComponent>

                                {/* InitialMeasure */}
                                <p className={'form-label mt-3'}>{ReportHelper.initialMeasure(report.category)}</p>
                                <RichTextEditorComponent value={report.initialMeasure} height={200}
                                                         change={(e) => report.initialMeasure = e.value}
                                                         toolbarSettings={MiscHelper.rteSettings}>
                                    <Inject services={[HtmlEditor, Toolbar, Link, QuickToolbar]}/>
                                </RichTextEditorComponent>


                                {/* Pictures */}
                                <p className={'form-label mt-3'}>Bilder</p>
                                <Pictures proxyFiles={pictures} updateFiles={this.updateProxyFiles}/>

                            </div>
                            <div className={'card-footer'}>
                                <div className={'d-flex justify-content-end'}>
                                    <button className={'btn btn-secondary'} onClick={this.submitReport}>
                                        <i className={'fas fa-share text-info me-3'}/>Opprett hendelsesrapport
                                    </button>
                                </div>
                            </div>

                        </div>
                        {/* card end */}

                    </Authorized>

                </div>
                {/* column end */
                }

            </section>
        );
    }

    /** */
    private updateProxyFiles(proxyFiles: ProxyFile[]) {
        this.setState({pictures: proxyFiles});
    }

    /** */
    private async submitReport(): Promise<void> {
        const {report, pictures} = this.state;
        if (!MiscHelper.stripHtml(report.text)) {
            ToastHelper.warn('Beskrivelse må fylles ut', 'bootstrap');
            return;
        }

        SpinnerHelper.block();
        report.pictures = pictures.length;
        report.title = report.title ? report.title : MiscHelper.summary(report.text);
        const reportId: number = await ReportService.post(report);
        for (const picture of pictures) {
            if (picture.file) {
                await ReportService.postFile(reportId, picture.file, true);
            }
        }

        if (reportId) {
            window.location.replace(`/report/${reportId}`);
        }

        SpinnerHelper.unblock();
    }
}

export function New() {
    const [params] = useSearchParams();
    return <Internal params={params}/>;
}
