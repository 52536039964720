import {Acl, Modal, SpinnerHelper, TemplateHelper, ToastHelper, UserMinimal, userStore} from '@hapro/template-react';
import type {Subscribe} from '@mathias_frost/simplestores';
import type {ActionEventArgs, RecordDoubleClickEventArgs} from '@syncfusion/ej2-grids';
import type {ChangedEventArgs as DateChangeEventArgs} from '@syncfusion/ej2-react-calendars';
import {DatePickerComponent} from '@syncfusion/ej2-react-calendars';
import {
    ColumnDirective,
    ColumnsDirective,
    CommandColumn,
    Edit,
    GridComponent,
    Inject,
    Selection,
    Sort,
} from '@syncfusion/ej2-react-grids';
import {ChangedEventArgs, TextBoxComponent} from '@syncfusion/ej2-react-inputs';
import type {ReactNode} from 'react';
import React from 'react';
import type {Measure} from '../entities/Measure';
import type {Report} from '../entities/Report';
import {DateHelper} from '../helpers/DateHelper';
import type {MeasureNew} from '../models/MeasureNew';
import {defaultMeasureNew} from '../models/MeasureNew';
import {aclAdmin} from '../services/aclStores';
import {ReportService} from '../services/ReportService';
import {ReportManager} from '../services/ReportStore';
import {MeasureForm, statusTemplate} from './MeasureForm';
import {ResponsibleSelect} from './ResponsibleSelect';

interface Props {
    report: Report | null;
    onChange: (report: Report) => void;
    closed: boolean;
}

interface State {
    measureNew: MeasureNew;
    aclAdmin: Acl;
    selected: number | null;
}

/** */
export class Measures extends React.Component<Props, State> {

    /** */
    private modal: Modal | null = null;

    /** */
    private unsubAcl: Subscribe<Acl> | null = null;

    public constructor(props: Props) {
        super(props);
        this.state = {measureNew: defaultMeasureNew(props.report?.id), aclAdmin: Acl.none, selected: null};
        this.add = this.add.bind(this);
        this.save = this.save.bind(this);
        this.reset = this.reset.bind(this);
        this.actionBegin = this.actionBegin.bind(this);
        this.updateTitle = this.updateTitle.bind(this);
        this.updateDeadline = this.updateDeadline.bind(this);
        this.updateResponsible = this.updateResponsible.bind(this);
        this.updateDescription = this.updateDescription.bind(this);
        this.recordDoubleClick = this.recordDoubleClick.bind(this);
    }

    /** */
    private static dateTemplate(measure: Measure): JSX.Element {
        return DateHelper.deadlineTemplate(measure.effectiveDeadline, !!measure.performed);
    }

    /** @inheritDoc */
    public componentDidMount(): void {
        this.unsubAcl = aclAdmin.subscribe(value => this.setState({aclAdmin: value}));
    }

    /** @inheritDoc */
    public componentWillUnmount(): void {
        aclAdmin.unsubscribe(this.unsubAcl);
    }

    public override render(): ReactNode {
        const {measureNew, aclAdmin, selected} = this.state;
        const {report, onChange, closed} = this.props;

        // Only high access level users and creator can manage measures
        const canManage = (aclAdmin >= Acl.erase || userStore.value?.userMinimal?.id === report?.createdBy)
            && !closed;

        return (<>
            <div className={'card'}>
                <div className={'card-header'}>
                    <h5 className={'card-title'}>Tiltak</h5>
                </div>
                <div className={'card-body'}>
                    {canManage ?
                        <button className={'btn btn-secondary mb-3'} onClick={this.add}>
                            <i className={'fa-solid fa-plus text-success me-2'}/>Legg til
                        </button>
                        : null}
                    <GridComponent dataSource={report?.measures ?? []} allowSorting={true}
                                   sortSettings={{columns: [{field: 'effectiveDeadline', direction: 'Ascending'}]}}
                                   actionBegin={this.actionBegin} recordDoubleClick={this.recordDoubleClick}
                                   editSettings={{allowDeleting: canManage, showDeleteConfirmDialog: true}}>
                        <ColumnsDirective>
                            <ColumnDirective field={'id'} headerTemplate={'Id'} width={'5rem'} isPrimaryKey/>
                            <ColumnDirective field={'title'} headerTemplate={'Tittel'} width={'10rem'}/>
                            <ColumnDirective field={'status'} headerTemplate={'Status'} width={'6rem'}
                                             template={statusTemplate}/>
                            <ColumnDirective field={'effectiveDeadline'} headerTemplate={'Frist'} width={'6rem'}
                                             type={'Date'} template={Measures.dateTemplate}/>
                            <ColumnDirective visible={canManage} width={'5rem'} headerText={"Slett"} commands={[
                                {
                                    type: 'Delete',
                                    buttonOption: {iconCss: 'fa-solid fa-trash', cssClass: 'e-flat'},
                                }]}
                            />
                        </ColumnsDirective>
                        <Inject services={[Sort, Edit, CommandColumn, Selection]}/>
                    </GridComponent>
                </div>
            </div>

            <Modal ref={m => this.modal = m} animation={true} centered={true} onHidden={this.reset}>
                <div className={'modal-header'}>
                    <h5 className={'modal-title'}>Nytt tiltak</h5>
                    <button className={'btn-close'} onClick={() => this.modal?.hide()}/>
                </div>
                <div className={'modal-body'}>
                    <p className={'form-label'}>Tittel</p>
                    <TextBoxComponent value={measureNew.title} change={this.updateTitle}/>
                    <p className={'form-label mt-3'}>Frist</p>
                    <DatePickerComponent value={measureNew.deadline} change={this.updateDeadline}
                                         firstDayOfWeek={1} format={TemplateHelper.date}
                                         strictMode={true}/>
                    <p className={'form-label mt-3'}>Ansvarlig for utførelse</p>
                    <ResponsibleSelect value={measureNew.responsible} onChange={this.updateResponsible}
                                       placeholder={'Deg selv'}/>
                    <p className={'form-label mt-3'}>Beskrivelse av tiltak</p>
                    <TextBoxComponent value={measureNew.description} change={this.updateDescription} multiline={true}/>
                </div>
                <div className={'modal-footer'}>
                    <button className={'btn btn-secondary'} onClick={() => this.modal?.hide()}>
                        <i className={'fa-solid fa-times me-2'}/>Forkast
                    </button>
                    <button className={'btn btn-success'} onClick={this.save}>
                        <i className={'fa-solid fa-save me-2'}/>Lagre
                    </button>
                </div>
            </Modal>

            <MeasureForm report={report} measureId={selected} acl={aclAdmin} onChange={onChange}/>
        </>);
    }

    /** */
    private async actionBegin(args: ActionEventArgs): Promise<void> {
        const {report, onChange} = this.props;
        const {selected} = this.state;
        if (args.requestType === 'delete') {
            const toDelete = (args.data as MeasureNew)[0] as Measure;
            const success = await ReportService.deleteMeasure(toDelete.id);
            args.cancel = !success;
            if (success && selected === toDelete.id) {
                this.setState({selected: null}, () => {
                    if (report) {
                        report.measures = report.measures.filter(m => m.id !== toDelete.id);
                        onChange(report);
                    }
                });
            }
        }
    }

    /** */
    private async save(): Promise<void> {
        const {measureNew} = this.state;
        const {report} = this.props;
        if (!report) {
            return;
        }

        SpinnerHelper.block();
        if (!measureNew.title) {
            ToastHelper.warn('Tittel er påkrevet', 'bootstrap');
            return;
        }

        measureNew.reportId = report.id;
        const id = await ReportService.postMeasure(measureNew);
        if (id && report) {
            await ReportManager.updateReport(report.id);
            this.modal?.hide();
        }
        SpinnerHelper.unblock();
    }

    /** */
    private updateTitle(e: ChangedEventArgs): void {
        const {measureNew} = this.state;
        measureNew.title = e.value ?? '';
        this.setState({measureNew});
    }

    /** */
    private updateDeadline(e: DateChangeEventArgs): void {
        const {measureNew} = this.state;
        measureNew.deadline = e.value ?? TemplateHelper.addDays(new Date(), 14);
        this.setState({measureNew});
    }

    /** */
    private updateResponsible(user: UserMinimal | null): void {
        const {measureNew} = this.state;
        measureNew.responsible = user?.id ?? null;
        this.setState({measureNew});
    }

    /** */
    private updateDescription(e: ChangedEventArgs): void {
        const {measureNew} = this.state;
        measureNew.description = e.value ?? '';
        this.setState({measureNew});
    }

    /** */
    private reset(): void {
        this.setState({measureNew: defaultMeasureNew(this.props.report?.id)});
    }

    /** */
    private add(): void {
        this.modal?.show();
    }

    /** */
    private recordDoubleClick(args: RecordDoubleClickEventArgs): void {
        const measure = args.rowData as Measure;
        this.setState({selected: measure.id}, () => {
            window.setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight);
            }, 100);
        });
    }
}
